import { McButton, McHint, McIcon, McTab, McTabBar } from '@maersk-global/mds-react-wrapper';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ShowErrorNotification, ShowSuccessNotification } from '../../common/notification';
import Loading from '../pages/Loading';
import LoadRegion, { LoadRegionData } from '../testconfig/LoadRegion';
import TestConfig, { TestConfigData, TestConfigDataValidator } from '../testconfig/TestConfig';
import ConfirmDeletionModal from '../util/ConfirmDeletionModal';
import ScheduleInfo, { ScheduleInfoData } from './ScheduleInfo';
import ScheduleTestService from './ScheduleTestService';

const ScheduleTest = () => {
  const scheduleTestService = new ScheduleTestService();

  const navigate = useNavigate();
  const testExecutionData = useLocation().state?.testExecutionData;
  const { id } = useParams();

  const defaultApiStatus = {
    status: Status.Pending,
    message: ''
  };
  const [apiStatus, setApiStatus] = useState(defaultApiStatus);

  const defaultScheduleData = {
    scheduleInfoData: ScheduleInfoData,
    testConfigData: TestConfigData,
    loadRegionData: LoadRegionData
  };
  const [scheduleData, setScheduleData] = useState(defaultScheduleData);

  const isEdit = id || scheduleData.scheduleInfoData.id ? true : false;

  const [confirmDelete, setConfirmDelete] = useState(false);

  const setScheduleInfoData = (scheduleInfoData) => {
    setScheduleData({ ...scheduleData, scheduleInfoData: scheduleInfoData });
  };

  const setTestConfigData = (testConfigData) => {
    setScheduleData({
      ...scheduleData,
      testConfigData: {
        ...scheduleData.testConfigData,
        optionsJson: testConfigData.optionsJson,
        varsJson: testConfigData.varsJson
      }
    });
  };

  const setLoadRegionData = (loadRegionData) => {
    setScheduleData({ ...scheduleData, loadRegionData: loadRegionData });
  };

  useEffect(() => {
    if (id) {
      // edit flow
      scheduleTestService
        .getScheduledTestById(id)
        .then((response) => {
          let responseData = response.data;
          let scheduleData = ScheduleTestUtil.getScheduleDataFromResponse(responseData);
          setScheduleData(scheduleData);

          setApiStatus({
            status: Status.Success,
            message: ''
          });
        })
        .catch((error) => {
          console.error('Error while retrieving scheduled test: ', error);
          setApiStatus({
            status: Status.Error,
            message: 'Something unexpected happened. Please try again by refreshing the page.'
          });
        });
    } else if (testExecutionData) {
      // create flow from existing test execution
      let scheduleData = ScheduleTestUtil.getScheduleDataFromTestExecution(testExecutionData);
      setScheduleData(scheduleData);

      setApiStatus({
        status: Status.Success,
        message: ''
      });
    } else {
      // create flow from scratch
      setApiStatus({
        status: Status.NotSupported,
        message: ''
      });
    }
  }, []);

  const handleCreateOrUpdate = () => {
    setApiStatus({ status: Status.InProgress, message: '' });

    let requestData = {};
    try {
      requestData = ScheduleTestUtil.validateAndGetRequestData(scheduleData);
    } catch (error) {
      setApiStatus({ status: Status.Error, message: error.message });
      return;
    }
    scheduleTestService
      .createOrUpdateScheduledTest(requestData, isEdit)
      .then((response) => {
        setScheduleData(ScheduleTestUtil.getScheduleDataFromResponse(response.data));

        let message = isEdit
          ? 'Test schedule updated successfully.'
          : 'Test schedule created successfully.';
        setApiStatus({ status: Status.Success, message: message });
      })
      .catch((error) => {
        console.error('Error while creating or updating scheduled test: ', error);
        let errorMessage = 'Something unexpected happened. Please try again.';
        if (error.response && error.response.status === 400) {
          errorMessage = `${error.response.data.message}. Please provide valid information.`;
        }
        setApiStatus({
          status: Status.Error,
          message: errorMessage
        });
      });
  };

  const handleDelete = (event, action) => {
    setConfirmDelete(false);
    if (action === 'cancel') {
      return;
    }
    setApiStatus({ status: Status.InProgress, message: '' });

    scheduleTestService
      .deleteScheduledTest(id)
      .then((response) => {
        setApiStatus({
          status: Status.Success,
          message: `Test schedule ${id} deleted successfully.`
        });
        setTimeout(() => {
          navigate('/perf-testing/schedules');
        }, 2000);
      })
      .catch((error) => {
        console.error('Error while deleting scheduled test: ', error);
        setApiStatus({
          status: Status.Error,
          message: 'Something unexpected happened. Please try again.'
        });
      });
  };

  const getInitContent = () => {
    if (apiStatus.status === Status.Pending) {
      return <Loading />;
    } else if (apiStatus.status === Status.NotSupported) {
      return <CreateNewScheduleHint />;
    }
  };

  return (
    <div>
      <h5 className="app__page-title">
        Performance Engineering &gt; Test Schedules &gt; {isEdit ? 'Edit Schedule' : 'New Schedule'}
      </h5>
      {apiStatus.status === Status.Pending || apiStatus.status === Status.NotSupported ? (
        getInitContent()
      ) : (
        <>
          {apiStatus.status === Status.Success && apiStatus.message ? (
            <ShowSuccessNotification message={apiStatus.message} />
          ) : null}
          {apiStatus.status === Status.Error ? (
            <ShowErrorNotification message={apiStatus.message} />
          ) : null}
          {confirmDelete ? (
            <ConfirmDeletionModal
              confirmationMessage="Are you sure you want to delete this schedule?"
              onAction={handleDelete}
            />
          ) : null}
          <McTabBar>
            <McTab slot="tab" label="Schedule Info" />
            <div slot="panel">
              <ScheduleInfo
                scheduleInfoData={scheduleData.scheduleInfoData}
                setScheduleInfoData={setScheduleInfoData}
              />
            </div>
            <McTab slot="tab" label="Test Config" />
            <div slot="panel">
              <TestConfig
                testConfigData={scheduleData.testConfigData}
                setTestConfigData={setTestConfigData}
              />
            </div>
            <McTab slot="tab" label="Load Region" />
            <div slot="panel">
              <LoadRegion
                loadRegionData={scheduleData.loadRegionData}
                setLoadRegionData={setLoadRegionData}
              />
            </div>
          </McTabBar>
          <div className="app__page-footer">
            <McButton
              loading={apiStatus.status === Status.InProgress}
              click={(event) => handleCreateOrUpdate(event)}>
              {isEdit ? 'Update' : 'Create'}
            </McButton>
            {isEdit ? (
              <McButton
                appearance="error"
                loading={apiStatus.status === Status.InProgress}
                click={(event) => setConfirmDelete(true)}>
                Delete
              </McButton>
            ) : (
              ''
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ScheduleTest;

export const CreateNewScheduleHint = () => {
  return (
    <McHint>
      <McIcon icon="info-circle" />
      <span>
        To create new schedule please go to Test Executions &gt; View Test &gt; Schedule Test. Only
        completed test executions can be scheduled.
      </span>
    </McHint>
  );
};

class ScheduleTestUtil {
  static getScheduleDataFromTestExecution(testExecution) {
    return {
      scheduleInfoData: {
        scheduleName: testExecution.testName,
        cronExpression: '',
        active: 'true',
        testTemplateId: testExecution.testTemplate.templateId,
        testTemplateName: testExecution.testTemplate.templateName,
        testType: testExecution.testType
      },
      testConfigData: {
        optionsJson: testExecution.optionsJson,
        varsJson: testExecution.varsJson
      },
      loadRegionData: testExecution.selLoadGeneratorList
    };
  }

  static validateAndGetRequestData(scheduleData) {
    let validationError = TestConfigDataValidator(scheduleData.testConfigData);
    if (validationError) {
      throw new Error(validationError);
    }

    return {
      id: scheduleData.scheduleInfoData.id,
      scheduleName: scheduleData.scheduleInfoData.scheduleName,
      cronExpression: scheduleData.scheduleInfoData.cronExpression,
      active: scheduleData.scheduleInfoData.active === 'true',
      testTemplateId: scheduleData.scheduleInfoData.testTemplateId,
      testType: scheduleData.scheduleInfoData.testType,
      optionsJson: scheduleData.testConfigData.optionsJson,
      varsJson: scheduleData.testConfigData.varsJson,
      selLoadGeneratorList: scheduleData.loadRegionData
    };
  }

  static getScheduleDataFromResponse(responseData) {
    return {
      scheduleInfoData: {
        id: responseData.id,
        scheduleName: responseData.scheduleName,
        cronExpression: responseData.cronExpression,
        active: responseData.active.toString(),
        testTemplateId: responseData.testTemplateId,
        testTemplateName: responseData.testTemplateName,
        testType: responseData.testType,
        updatedBy: responseData.updatedBy,
        updatedDateTime: responseData.updatedDateTime
      },
      testConfigData: {
        optionsJson: responseData.optionsJson,
        varsJson: responseData.varsJson
      },
      loadRegionData: responseData.selLoadGeneratorList
    };
  }
}

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error',
  NotSupported: 'NotSupported'
};
