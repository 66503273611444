export const DummyTableData = [
  {
    applicationID: '1',
    application: 'Application 1',
    platform: 'Platform 1',
    portfolio: 'Portfolio 1',
    onboardedOn: '2021-08-01',
    switchApplication: false
  },
  {
    applicationID: '2',
    application: 'Application 2',
    platform: 'Platform 2',
    portfolio: 'Portfolio 2',
    onboardedOn: '2021-08-02',
    switchApplication: false
  }
];

export const DummyColumnData = [
  {
    id: 'applicationID',
    label: 'Application ID'
  },
  {
    id: 'application',
    label: 'Application'
  },
  {
    id: 'platform',
    label: 'Platform'
  },
  {
    id: 'portfolio',
    label: 'Portfoli'
  },
  {
    id: 'onboardedOn',
    label: 'Onboarded On'
  },
  {
    id: 'switchApplication',
    label: 'Switch Application'
  }
];

export const columns = [
  {
    id: 'userID',
    label: 'User Id'
  },
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'verificationStatus',
    label: 'Verification Status'
  }
];

export const data = [
  {
    userID: '1',
    name: 'User 1',
    verificationStatus: true
  },
  {
    userID: '2',
    name: 'User 2',
    verificationStatus: false
  }
];

export const ColumnData = [
  {
    id: 'userId',
    label: 'User ID'
  },
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'onboardedOn',
    label: 'Onboarded On'
  },
  {
    id: 'types',
    label: 'Types'
  }
];

export const DummyData = [
  {
    userId: '1',
    name: 'User 1',
    onboardedOn: '2021-09-01',
    types: 'User'
  },
  {
    userId: '2',
    name: 'User 2',
    onboardedOn: '2021-08-01',
    types: 'Service Principal'
  },
  {
    userId: '3',
    name: 'User 3',
    onboardedOn: '2022-09-01',
    types: 'User'
  }
];

// export const onboardedData = [
//   {
//     id: 22,
//     macId: 'A3536',
//     portfolioShortName: 'tse',
//     platformName: 'Integration Engineering',
//     applicationName: 'SkyDeck',
//     createDateTime: [2023, 11, 22, 14, 31, 39, 9000000],
//     productName: 'SkyDeck',
//     devConfig: {
//       hedwigScopeWebhookUrl:
//         'https://api.hedwig.maersk-digital.net/v1/alerts/webhook/4eeb193d-710c-4954-b0a6-08f2f8b9393b'
//     },
//     preProdConfig: {
//       hedwigScopeWebhookUrl: 'preProdWebhookUrl'
//     },
//     prodConfig: {
//       hedwigScopeWebhookUrl: 'prodWebhookUrl'
//     }
//   },
//   {
//     id: 1,
//     macId: 'A3569',
//     portfolioShortName: 'tbm',
//     platformName: 'Technology Operations Platform',
//     applicationName: 'iPACE',
//     createDateTime: [2023, 4, 17, 16, 58, 11, 836000000],
//     productName: 'iPACE',
//     devConfig: {
//       secrets: {
//         devKey4: 'zA8n5mCOuK/dxShjnH4Knw==',
//         devKey3: 'xDKrq4DsgGX3KoP3p+ZeDw=='
//       }
//     },
//     preProdConfig: {
//       secrets: {
//         preProdSecrets: 'SgdXYp0xJfphSBl+1h3fEg=='
//       }
//     },
//     prodConfig: {
//       secrets: {
//         prodSecrets: 'SgdXYp0xJfphSBl+1h3fEg=='
//       }
//     }
//   },
//   {
//     id: 2,
//     macId: 'tbm-statuspage',
//     portfolioShortName: 'tbm',
//     platformName: 'Tech Ops Platform',
//     applicationName: 'StatusPage',
//     createDateTime: [2023, 5, 3, 14, 26, 30, 819000000]
//   },
//   {
//     id: 3,
//     macId: 'bep-delta-nest-limit-api',
//     portfolioShortName: 'bep',
//     platformName: 'tsunami',
//     applicationName: 'Delta-Nest-Limit-API',
//     createDateTime: [2023, 5, 5, 16, 32, 53, 791000000]
//   },
//   {
//     id: 4,
//     macId: 'A12618',
//     portfolioShortName: 'mbm',
//     platformName: 'Customs Integration Platform',
//     applicationName: 'AppLite',
//     createDateTime: [2023, 5, 22, 15, 21, 44, 790000000],
//     productName: 'AppLite'
//   }
// ];
