import { McToast } from '@maersk-global/mds-react-wrapper';
import { McNotification } from '@maersk-global/mds-react-wrapper/components-core/mc-notification';
import React from 'react';

const fit = 'large';
const position = 'top-center';

const ShowSuccessNotification = ({ message }) => {
  const [open, setOpen] = React.useState(true);
  return (
    <McToast
      open={open}
      fit={fit}
      appearance="success"
      position={position}
      close={(event) => setOpen(false)}>
      <McNotification icon="check-circle">{message}</McNotification>
    </McToast>
  );
};

const ShowErrorNotification = ({ message, attention = 'medium', severity = 'error' }) => {
  const [open, setOpen] = React.useState(true);

  const renderContent = () => {
    if (attention === 'low') {
      return (
        <McToast
          open={open}
          fit={fit}
          appearance={severity}
          position={position}
          close={(event) => setOpen(false)}>
          <McNotification icon="times-circle">{message}</McNotification>
        </McToast>
      );
    } else if (attention === 'medium') {
      return (
        <div className="notification__error">
          <McNotification appearance={severity} fit={fit} icon="times-circle">
            {message}
          </McNotification>
        </div>
      );
    }
  };

  return renderContent();
};

const ShowInfoNotification = ({ message }) => {
  return (
    <McToast open fit={fit} appearance="info" position={position}>
      <McNotification icon="info-circle">{message}</McNotification>
    </McToast>
  );
};

export { ShowErrorNotification, ShowInfoNotification, ShowSuccessNotification };
