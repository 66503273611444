import { McCAccordion, McCAccordionItem } from '@maersk-global/community-ui-react-wrapper';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import UserInfo from './hooks/UserAppAuthorizationProvider';

const Navigation = () => {
  const { isChaosEnabled, onboardingConfig } = UserInfo();
  const [currPath, setcurrPath] = useState(null);

  useEffect(() => {
    if (location.pathname.startsWith('/perf-testing/templates')) {
      setcurrPath('template');
    } else if (location.pathname.startsWith('/perf-testing/executions')) {
      setcurrPath('executions');
    } else if (location.pathname.startsWith('/perf-testing/schedules')) {
      setcurrPath('schedules');
    } else if (location.pathname.startsWith('/chaos-testing/executions')) {
      setcurrPath('listchaostestexec');
    } else if (location.pathname === '/perf-testing/integrations') {
      setcurrPath('integrations');
    } else if (location.pathname === '/config/secrets') {
      setcurrPath('secrets');
    } else if (location.pathname === '/config/azure-key-vault') {
      setcurrPath('azure-key-vault');
    } else if (location.pathname === '/config/notifications') {
      setcurrPath('notifications');
    } else if (location.pathname === '/config/hashicorp-vault') {
      setcurrPath('hashicorpVault');
    } else if (location.pathname === '/insights/perf-tests') {
      setcurrPath('insight');
    } else if (location.pathname === '/insights/applications') {
      setcurrPath('applications');
    } else {
      setcurrPath('/');
    }
  }, [location.pathname]);

  return (
    <div
      className={`app__navigation-layout ${
        onboardingConfig ? '' : 'app__navigation-layout-disabled'
      }`}>
      <McCAccordion fit="medium" variant="default">
        <McCAccordionItem
          title="Performance Engineering"
          id="1"
          expanded
          style={{ marginTop: '2px', paddingTop: '15px', fontWeight: '500' }}>
          <div style={{ paddingTop: '5px', display: 'block' }}>
            <div style={{ width: '100%' }} className="menu-item">
              <Link
                to="/perf-testing/templates"
                className="link"
                style={{
                  display: 'block',
                  color: '#141414',
                  textDecoration: 'none',
                  backgroundColor: currPath === 'template' ? '#E9F6FC' : 'transparent',
                  paddingTop: '5px',
                  paddingBottom: '5px'
                }}>
                &nbsp;&nbsp;&nbsp;&nbsp; Test Templates
              </Link>
            </div>
            <div className="menu-item">
              <Link
                to="/perf-testing/executions"
                style={{
                  display: 'block',
                  color: '#141414',
                  textDecoration: 'none',
                  backgroundColor: currPath === 'executions' ? '#E9F6FC' : 'transparent',
                  paddingTop: '5px',
                  paddingBottom: '5px'
                }}>
                &nbsp;&nbsp;&nbsp;&nbsp; Test Executions
              </Link>
            </div>
            <div className="menu-item">
              <Link
                to="/perf-testing/schedules"
                style={{
                  display: 'block',
                  color: '#141414',
                  textDecoration: 'none',
                  backgroundColor: currPath === 'schedules' ? '#E9F6FC' : 'transparent',
                  paddingTop: '5px',
                  paddingBottom: '5px'
                }}>
                &nbsp;&nbsp;&nbsp;&nbsp; Test Schedules
              </Link>
            </div>
            <div className="menu-item">
              <Link
                to="/perf-testing/integrations"
                style={{
                  display: 'block',
                  color: '#141414',
                  textDecoration: 'none',
                  backgroundColor: currPath === 'integrations' ? '#E9F6FC' : 'transparent',
                  paddingTop: '5px',
                  paddingBottom: '5px'
                }}>
                &nbsp;&nbsp;&nbsp;&nbsp; CICD Integrations
              </Link>
            </div>
          </div>
        </McCAccordionItem>
        {isChaosEnabled !== undefined && isChaosEnabled === 'Y' && (
          <McCAccordionItem title="Chaos Engineering" id="2" expanded style={{ fontWeight: '500' }}>
            <div className="menu-item">
              <div className="linkItem">
                <Link
                  to="/chaos-testing/executions"
                  style={{
                    display: 'block',
                    color: '#141414',
                    textDecoration: 'none',
                    backgroundColor: currPath === 'listchaostestexec' ? '#E9F6FC' : 'transparent',
                    paddingTop: '5px',
                    paddingBottom: '5px'
                  }}>
                  &nbsp;&nbsp;&nbsp;&nbsp; Test Executions
                </Link>
              </div>
            </div>
          </McCAccordionItem>
        )}
        <McCAccordionItem title="Configuration" id="3" expanded style={{ fontWeight: '500' }}>
          <div>
            <div className="menu-item">
              <Link
                to="/config/secrets"
                style={{
                  display: 'block',
                  color: '#141414',
                  textDecoration: 'none',
                  backgroundColor: currPath === 'secrets' ? '#E9F6FC' : 'transparent',
                  paddingTop: '5px',
                  paddingBottom: '5px'
                }}>
                &nbsp;&nbsp;&nbsp;&nbsp; Secrets
              </Link>
            </div>
            <div className="menu-item">
              <Link
                to="/config/azure-key-vault"
                style={{
                  display: 'block',
                  color: '#141414',
                  textDecoration: 'none',
                  backgroundColor: currPath === 'azure-key-vault' ? '#E9F6FC' : 'transparent',
                  paddingTop: '5px',
                  paddingBottom: '5px'
                }}>
                &nbsp;&nbsp;&nbsp;&nbsp; Azure Key Vault
              </Link>
            </div>
            <div className="menu-item">
              <Link
                to="/config/hashicorp-vault"
                style={{
                  display: 'block',
                  color: '#141414',
                  textDecoration: 'none',
                  backgroundColor: currPath === 'hashicorpVault' ? '#E9F6FC' : 'transparent',
                  paddingTop: '5px',
                  paddingBottom: '5px'
                }}>
                &nbsp;&nbsp;&nbsp;&nbsp; HashiCorp Vault
              </Link>
            </div>
            <div className="menu-item">
              <Link
                to="/config/notifications"
                style={{
                  display: 'block',
                  color: '#141414',
                  textDecoration: 'none',
                  backgroundColor: currPath === 'notifications' ? '#E9F6FC' : 'transparent',
                  paddingTop: '5px',
                  paddingBottom: '5px'
                }}>
                &nbsp;&nbsp;&nbsp;&nbsp; Notifications
              </Link>
            </div>
          </div>
        </McCAccordionItem>
        <McCAccordionItem title="Insights" id="4" expanded style={{ fontWeight: '500' }}>
          <div className="menu-item">
            <Link
              to="/insights/perf-tests"
              style={{
                display: 'block',
                color: '#141414',
                textDecoration: 'none',
                backgroundColor: currPath === 'insight' ? '#E9F6FC' : 'transparent',
                paddingTop: '5px',
                paddingBottom: '5px'
              }}>
              &nbsp;&nbsp;&nbsp;&nbsp; Performance Tests
            </Link>
          </div>
          <div className="menu-item">
            <Link
              to="/insights/applications"
              style={{
                display: 'block',
                color: '#141414',
                textDecoration: 'none',
                backgroundColor: currPath === 'applications' ? '#E9F6FC' : 'transparent',
                paddingTop: '5px',
                paddingBottom: '5px'
              }}>
              &nbsp;&nbsp;&nbsp;&nbsp; Applications
            </Link>
          </div>
        </McCAccordionItem>
      </McCAccordion>
    </div>
  );
};

export default Navigation;
