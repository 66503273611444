import React from 'react';
import { useNavigate } from 'react-router-dom';

/**
 * Link to navigate to different component without refreshing the page.
 *
 * @param {string} route - The route to navigate to.
 */
const ComponentLink = ({ route, children }) => {
  const navigate = useNavigate();

  const navigateTo = (event) => {
    event.preventDefault();
    navigate(route);
  };

  return (
    <a href={route} onClick={(event) => navigateTo(event)}>
      {children}
    </a>
  );
};

export default ComponentLink;
