import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { loginRequest } from '../../auth/authProviderMsalReact';
import { ShowErrorNotification } from '../../common/notification';

const RequestInterceptor = ({ children }) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0]);

  const [interceptorRegistered, setInterceptorRegistered] = useState(false);
  const [resInterceptorRegistered, setResInterceptorRegistered] = useState(false);
  const [serviceRestart, setServiceRestart] = useState(false);

  // request interceptor
  useEffect(() => {
    const interceptor = axios.interceptors.request.use(async (config) => {
      // Do we need this even when sso_user is default to true
      // faro.api.setUser({
      //   id: account.localAccountId,
      //   email: account.username,
      //   username: account.name
      // });

      let response;
      try {
        // Send Authorization header all requests. As per docs token should be accessToken but here idToken seems to be accessToken and vice versa.
        response = await instance.acquireTokenSilent({
          ...loginRequest,
          account
        });
        config.headers.Authorization = `Bearer ${response.idToken}`;

        return config;
      } catch (error) {
        console.error('Error while getting the token: ', error);
        if (error instanceof InteractionRequiredAuthError) {
          console.info('Re-authenticating user for getting the token');
          response = await instance.acquireTokenRedirect({
            ...loginRequest,
            account
          });
          config.headers.Authorization = `Bearer ${response.idToken}`;

          return config;
        }
        throw error;
      }
    });

    setInterceptorRegistered(true);

    // Cleanup function to remove the interceptor when the component unmounts
    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, []);

  // response interceptor
  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      // success handler
      (response) => {
        return response;
      },
      // error handler
      async (error) => {
        if (error.response && error.response.status === 401) {
          // token might be expired, try to renew it by re-authenticating the user
          // ideally acquireTokenSilent should renew the token on expiry, but it has been noticed that sometimes idToken
          // expires earlier than accessToken, since accessToken is not expired token renewal doesn't happen
          const response = await instance.acquireTokenRedirect({
            ...loginRequest,
            account
          });
          // retry the request with the new token
          const config = error.config;
          config.headers.Authorization = `Bearer ${response.idToken}`;
          return new Promise((resolve, reject) => {
            axios
              .request(config)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          });
        } else if (
          error.response &&
          (error.response.status === 502 || error.response.status === 503)
        ) {
          setServiceRestart(true);
        }
        // for any other error simple propagate the error
        return Promise.reject(error);
      }
    );

    setResInterceptorRegistered(true);

    // Cleanup function to remove the interceptor when the component unmounts
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  return interceptorRegistered && resInterceptorRegistered ? (
    !serviceRestart ? (
      <>{children}</>
    ) : (
      <ShowErrorNotification message="iPACE service is unavailable at the moment and should be up in few minutes. If you experience unavailability longer than 10 minutes please reach out to iPACE support." />
    )
  ) : null;
};

export default RequestInterceptor;
