import { McLoadingIndicator } from '@maersk-global/mds-react-wrapper';
import React from 'react';

const Loading = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '256px',
        height: '128px',
        zIndex: 1,
        backgroundColor: 'rgb(255,255,255)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      className="mds-table">
      <McLoadingIndicator label="Loading..." fit="medium" />
    </div>
  );
};

export default Loading;
