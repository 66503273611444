import { LogLevel } from '@azure/msal-browser';

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_APPLICATION_REGISTRATION_ID,
    authority: 'https://login.microsoftonline.com/' + process.env.REACT_APP_AZURE_AD,
    redirectUri: process.env.REACT_APP_REDIRECT_URL
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            return;
          case LogLevel.Verbose:
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      logLevel: LogLevel.Info,
      piiLoggingEnabled: false
    }
  }
};

// scopes are needed for retrieving tokens from cache else API requests are made to Azure AD everytime
// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/src/request/ScopeSet.ts#L77
// https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/6969
export const loginRequest = {
  scopes: ['openid', 'profile', 'offline_access']
};
