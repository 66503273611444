import { McInput, McTab, McTabBar } from '@maersk-global/mds-react-wrapper';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McCheckbox } from '@maersk-global/mds-react-wrapper/components-core/mc-checkbox';
import React, { useState } from 'react';
import { getCoreServiceUrl } from '../components/Util.js';
import UserInfo from '../hooks/UserAppAuthorizationProvider.jsx';
import NotificationsService from '../service/NotificationsService.js';

export default function Notifications() {
  const [devConfigUrl, setDevConfigUrl] = useState('');
  const [preProdConfigUrl, setPreProdConfigUrl] = useState('');
  const [prodConfigUrl, setProdConfigUrl] = useState('');
  const [sendFailureNotification] = useState(true);
  const [sendSuccessNotification] = useState(true);
  const { onboardingConfig } = UserInfo();
  const [inProgress, setInProgress] = useState(false);

  const environments = ['Development', 'Pre-Prod', 'Prod'];

  const notificationsService = new NotificationsService();

  React.useEffect(() => {
    notificationsService
      .getApplicationOnboardedData(
        getCoreServiceUrl() + `/core-srv/onboarded-application/${onboardingConfig.id}`
      )
      .then((response) => {
        setDevConfigUrl(response.data.devConfig.hedwigScopeWebhookUrl || '');
        setPreProdConfigUrl(response.data.preProdConfig.hedwigScopeWebhookUrl || '');
        setProdConfigUrl(response.data.prodConfig.hedwigScopeWebhookUrl || '');
      })
      .catch((error) => {
        console.error('error  = ', error);
      });
  }, []);

  const updateOnboardedApplication = () => {
    setInProgress(true);

    let data = {
      devConfig: {
        hedwigScopeWebhookUrl: devConfigUrl
      },
      preProdConfig: {
        hedwigScopeWebhookUrl: preProdConfigUrl
      },
      prodConfig: {
        hedwigScopeWebhookUrl: prodConfigUrl
      }
    };

    notificationsService
      .updateOnboardedApplication(
        getCoreServiceUrl() + `/core-srv/onboarded-application/config/notifications`,
        data
      )
      .then((response) => {
        setInProgress(false);
        setDevConfigUrl(response.data.devConfig.hedwigScopeWebhookUrl || '');
        setPreProdConfigUrl(response.data.preProdConfig.hedwigScopeWebhookUrl || '');
        setProdConfigUrl(response.data.prodConfig.hedwigScopeWebhookUrl || '');
      })
      .catch((error) => {
        console.error('error = ', error);
      });
  };

  const getWebhookURL = (environment) => {
    if (environment === 'Development') {
      return devConfigUrl;
    } else if (environment === 'Pre-Prod') {
      return preProdConfigUrl;
    } else {
      return prodConfigUrl;
    }
  };

  const setWebhookURL = (environment, url) => {
    if (environment === 'Development') {
      setDevConfigUrl(url);
    } else if (environment === 'Pre-Prod') {
      setPreProdConfigUrl(url);
    } else {
      setProdConfigUrl(url);
    }
  };

  return (
    <div>
      <h5 className="app__page-title">Configuration &gt; Notifications</h5>
      <form onSubmit={(e) => updateOnboardedApplication(e)}>
        <div>
          <McTabBar>
            {environments.map((environment, tabindex) => (
              <React.Fragment key={tabindex}>
                <McTab slot="tab" label={environment} />
                <div slot="panel">
                  <div className="app__page-input">
                    <McInput
                      className="app__page-input"
                      label={environment + ' Hedwig webhook URL'}
                      value={getWebhookURL(environment)}
                      input={(e) => setWebhookURL(environment, e.target.value)}
                    />
                  </div>
                  <div className="app__page-input">
                    <McCheckbox
                      checked={sendFailureNotification}
                      disabled
                      value="sendFailureNotification"
                      label="Get failure Notifications"
                    />
                  </div>
                  <div className="app__page-input">
                    <McCheckbox
                      checked={sendSuccessNotification}
                      disabled
                      value="sendSuccessNotification"
                      label="Get success Notifications"
                    />
                  </div>
                </div>
              </React.Fragment>
            ))}
          </McTabBar>
        </div>
        <McButton
          type="submit"
          className="app__page-footer"
          label="Save All"
          loading={inProgress}
        />
      </form>
    </div>
  );
}
