import moment from 'moment';

export function getEnv() {
  let env = '';
  if (process.env.NODE_ENV !== undefined || process.env.NODE_ENV !== '') {
    env = process.env.NODE_ENV.substring(0, 3);
  }
  return env;
}

export function getCoreServiceUrl() {
  // let env = getEnv();
  // if (env === 'dev') {
  //   // console.log('configData.${ENV}.core-service-url = ' + configData.dev.coreserviceurl);
  //   return configData.dev.coreserviceurl;
  // }
  return process.env.REACT_APP_CORESERVICE_URL;
}
export function getOrchestratorUrl() {
  // let env = getEnv();
  // if (env === 'dev') {
  //   // console.log('configData.${ENV}.core-service-url = ' + configData.dev.coreserviceurl);
  //   return configData.dev.orchestratorUrl;
  // }
  return process.env.REACT_APP_ORCHESTRATOR_URL;
}
export function getMACUrl() {
  // let env = getEnv();
  // if (env === 'dev') {
  //   // console.log('configData.${ENV}.core-service-url = ' + configData.dev.coreserviceurl);
  //   return configData.dev.grafanaDashboardUrl;
  // }
  return process.env.REACT_APP_MAC_URL;
}

export const formatDateTime = (timestamp) => {
  const dateTime = new Date(
    timestamp[0],
    timestamp[1] - 1,
    timestamp[2],
    timestamp[3],
    timestamp[4],
    timestamp[5]
  );
  return moment(dateTime).format('D-MMM-yyyy h:mm:ss a');
};

export const formatUnixTimestamp = (dateTime) => {
  let ts = [...dateTime];
  return Date.UTC(ts[0], ts[1] - 1, ts[2], ts[3], ts[4], ts[5]);
};

export const getCurrentTimestamp = () => {
  const currD = new Date();
  return Date.UTC(
    currD.getFullYear(),
    currD.getMonth(),
    currD.getDate(),
    currD.getHours(),
    currD.getMinutes(),
    currD.getSeconds()
  );
};

export const getGitHubLink = (testTemplate) => {
  let branchName = testTemplate.branchName ? testTemplate.branchName : 'main';
  return `https://github.com/Maersk-Global/${testTemplate.repoName}/blob/${branchName}/${testTemplate.testScript}`;
};
