import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McInput } from '@maersk-global/mds-react-wrapper/components-core/mc-input';
import { McOption } from '@maersk-global/mds-react-wrapper/components-core/mc-option';
import { McSelect } from '@maersk-global/mds-react-wrapper/components-core/mc-select';
import React, { useEffect, useState } from 'react';
import { getCoreServiceUrl } from '../components/Util.js';
import configData from '../config/config.json';
import useChaosTestExecution from '../hooks/ChaosExecutionTabs';
import ChaosTestExecService from '../service/ChaosTestExecService';

import { McNotification } from '@maersk-global/mds-react-wrapper/components-core/mc-notification';
import { McToast } from '@maersk-global/mds-react-wrapper/components-core/mc-toast';
import '../stylesheets/Vault.css';

const mdsChaosTest = (props) => {
  const { chaosType } = props;
  const { testId, testData, updateTestData } = useChaosTestExecution();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  let chaosTestExecService = new ChaosTestExecService();
  const [namespaceList] = useState(configData.namespaceList);
  const [chaosActionList] = useState(configData.chaosActions);
  const [chaosModeList] = useState(configData.chaosModes);
  let disableSubmitButton = false;
  let chaosMap;

  useEffect(() => {
    console.log('chaosMap from TestProvider db = ' + JSON.stringify(testData));
    console.log('chaosMap from TestProvider db = ' + testData.chaosTestMap);
    chaosMap =
      testData.chaosTestMap !== undefined
        ? new Map(Object.entries(testData.chaosTestMap))
        : new Map();
    console.log('chaosType from TestProvider db = ' + userForm.chaosType);
    console.log('chaosMap from TestProvider db = ' + chaosMap);
    console.log('chaosMap.has(chaosType) = ' + chaosMap.has(userForm.chaosType));
    //testId will not exists when it is first time load
    if (chaosMap.has(userForm.chaosType) !== false) {
      let chaosTest = chaosMap.get(userForm.chaosType);
      setUserForm({
        ...userForm,
        chaosType: chaosTest.testType,
        testStatus: chaosTest.status,
        namespace: chaosTest.metadata.namespace,
        labelSelector: flattenLabelSelector(chaosTest),
        chaosAction: chaosTest.chaosSpec.action,
        chaosMode: chaosTest.chaosSpec.mode,
        duration: chaosTest.chaosSpec.duration
      });
      console.log('chaosTest from TestProvider db = ' + chaosTest);
      console.log('testData from TestProvider db = ' + testData);
      console.log('data from TestProvider db = ' + JSON.stringify(testData));
    } else {
      console.log('Inside the ELSE case');
    }
  }, []);

  if (testData !== undefined && testData.status !== 'DRAFT') {
    disableSubmitButton = true;
  }
  console.log('disableSubmitButton2 =' + disableSubmitButton);

  function flattenLabelSelector(chaosTest) {
    let labSelTxt = '';
    console.log(
      'chaosTest.chaosSpec.selector.labelSelector =' +
        chaosTest.chaosSpec.selector.userForm.labelSelectors
    );
    let labelMap =
      chaosTest.chaosSpec.selector.labelSelectors !== undefined
        ? new Map(Object.entries(chaosTest.chaosSpec.selector.userForm.labelSelector))
        : new Map();
    console.log('labelMap =' + labelMap.size);
    for (const mapKey of labelMap.keys()) {
      labSelTxt += mapKey + ':' + labelMap.get(mapKey) + ',';
    }
    //remove the last commma
    labSelTxt = labSelTxt !== undefined ? labSelTxt.substring(0, labSelTxt.length - 1) : '';
    return labSelTxt;
  }

  function formatMapForLabels() {
    let labels =
      userForm.labelSelector.indexOf(',') > -1
        ? userForm.labelSelector.split(',')
        : userForm.labelSelector;
    let labMap = new Map();
    console.log('labels.length =' + (typeof labels === 'string'));
    if (typeof labels === 'string') {
      let labelObj = labels.split(':');
      console.log('labelObj' + labelObj.length);
      labMap.set(labelObj[0], labelObj[1]);
      console.log('labelObj[0]' + labelObj[0]);
      console.log('labMap.get(labelObj[0]' + labMap.get(labelObj[0]));
    } else {
      for (let i = 0; i < labels.length; i++) {
        let labelObj = labels[i].split(':');
        labMap.set(labelObj[0], labelObj[1]);
      }
    }
    console.log('labelMap =' + labMap.size);
    return labMap;
  }
  const handleSubmit = async (e) => {
    console.log('Called Chaos Save');
    disableSubmitButton = true;
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    //validate data
    let hasError = false;
    if (userForm.namespace === undefined || userForm.namespace.trim() === '') {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Namespace cannot be empty');
      hasError = true;
    }
    console.log('hasError = ' + hasError);
    if (!hasError) {
      let labelSelectors1 = formatMapForLabels();
      let labelSelectors = Object.fromEntries(labelSelectors1);
      console.log('json label = ' + labelSelectors);

      let nameOfChaosTest = testId + '-' + chaosType.toLowerCase();
      let postExperiment = {
        testType: userForm.chaosType,
        status: userForm.testStatus,
        metadata: {
          name: nameOfChaosTest,
          namespace: userForm.namespace
        },
        chaosSpec: {
          action: userForm.chaosAction,
          mode: userForm.chaosMode,
          duration: userForm.duration,
          selector: {
            labelSelectors,
            namespaces: [userForm.namespace]
          }
        }
      };
      console.log('LabelSelectors = ' + labelSelectors);
      console.log(nameOfChaosTest);
      console.log('postExperiment =' + JSON.stringify(postExperiment));
      console.log(
        'URL in TestSCope =' +
          getCoreServiceUrl() +
          '/core-srv/chaos-test-exec/experiment/' +
          testId +
          '/chaostype/' +
          chaosType
      );
      chaosTestExecService
        .addExperimentToTest(
          getCoreServiceUrl() +
            '/core-srv/chaos-test-exec/experiment/' +
            testId +
            '/chaostype/' +
            chaosType,
          postExperiment
        )
        .then((response) => {
          console.log(response.status);
          if (response.status == 200) {
            console.log(
              'successfully saved the ' + chaosType + ' tab -response.status : ' + response.status
            );
            setShowAlert(true);
            setAlertType('success');
            setAlertMessage('Successfully saved the chaos test ' + chaosType);
            setTimeout(() => {
              updateTestData(response.data);
            }, 100);
          } else {
            throw response;
          }
        })
        .catch((error) => {
          console.log(JSON.stringify(error));
          if (error instanceof Error) {
            console.log('error1 = ' + error);
            setShowAlert(true);
            setAlertType('error');
            setAlertMessage(error.response.data.message);
          }
        });
    }
  };

  const [userForm, setUserForm] = React.useState({
    chaosType: '',
    testStatus: '',
    namespace: '',
    labelSelector: '',
    chaosAction: '',
    chaosMode: '',
    duration: ''
  });
  const [fit] = React.useState('medium');
  function errorDialogOnClose() {
    setShowAlert(false);
  }
  function showAlertDialog() {
    let icon;

    if (alertType === 'info') {
      icon = 'info-circle';
    } else if (alertType === 'error') {
      icon = 'exclamation-octagon';
    } else if (alertType === 'success') {
      icon = 'check-circle';
    }

    return (
      <McToast
        open
        position="top-center"
        appearance={alertType}
        close={errorDialogOnClose}
        duration="5000">
        <McNotification icon={icon} body={alertMessage}></McNotification>
      </McToast>
    );
  }
  let alertDia = showAlertDialog();

  return (
    <div>
      <>
        {showAlert ? alertDia : <div></div>}
        <div className="layout layout--left">
          <div className="layout--s layout--margin-32">
            <form id="info" onSubmit={(e) => handleSubmit(e)}>
              <div id="Personal">
                <div className="field">
                  <McInput
                    name="chaosType"
                    label=" Experiment Type "
                    fit={fit}
                    value={userForm.chaosType}
                    disabled={true}
                    required></McInput>
                  <div className="field"></div>{' '}
                  <McInput
                    name="testStatus"
                    label="Test Status"
                    fit={fit}
                    value={userForm.testStatus}
                    disabled={true}
                    required></McInput>
                  <div className="field"></div>{' '}
                  <div>
                    <McSelect
                      name="namespace"
                      label="Namespace Selector"
                      fit={fit}
                      required
                      optionselected={(event) => {
                        setUserForm({
                          ...userForm,
                          namespace: event.detail.value
                        });
                      }}>
                      {namespaceList !== undefined &&
                        namespaceList.map((namSpace, index) => (
                          <McOption key={index} value={namSpace}>
                            {namSpace}
                          </McOption>
                        ))}
                    </McSelect>
                  </div>
                  <div className="field"></div>{' '}
                  <McInput
                    name="labelSelector"
                    label="Label Selector"
                    placeholder="Each attribute and value in double quotes seperated by :"
                    fit={fit}
                    value={userForm.labelSelector}
                    required
                    input={(event) => {
                      if (event) {
                        const value = event.target.value;
                        console.log(value);
                        setUserForm({ ...userForm, labelSelector: value });
                      }
                    }}></McInput>
                  <div className="field"></div>{' '}
                  <div>
                    <McSelect
                      name="chaosAction"
                      label="Action"
                      fit={fit}
                      value={userForm.chaosAction}
                      required
                      optionselected={(event) => {
                        setUserForm({
                          ...userForm,
                          chaosAction: event.detail.value
                        });
                      }}>
                      {chaosActionList &&
                        chaosActionList.map((act, index) => (
                          <McOption key={index} value={act}>
                            {act}
                          </McOption>
                        ))}
                    </McSelect>
                  </div>
                  <div className="field"></div>{' '}
                  <div>
                    <McSelect
                      name="chaosMode"
                      label="Mode"
                      fit={fit}
                      value={userForm.chaosMode}
                      required
                      optionselected={(event) => {
                        setUserForm({
                          ...userForm,
                          chaosMode: event.detail.value
                        });
                      }}>
                      {chaosModeList &&
                        chaosModeList.map((mode, index) => (
                          <McOption key={index} value={mode}>
                            {mode}
                          </McOption>
                        ))}
                    </McSelect>
                  </div>
                  <div className="field"></div>{' '}
                  <McInput
                    name="duration"
                    label="Duration"
                    fit={fit}
                    placeholder="Duration (in seconds or minutes) Ex: 1s or 1m"
                    value={userForm.duration}
                    required
                    input={(event) => {
                      if (event) {
                        const value = event.target.value;
                        console.log(value);
                        setUserForm({ ...userForm, duration: value });
                      }
                    }}></McInput>
                  <div className="field"></div>{' '}
                  <McButton type="submit" variant="filled" trailingicon="chevron" fit={fit}>
                    Save
                  </McButton>
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    </div>
  );
};

export default mdsChaosTest;
