import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McInput } from '@maersk-global/mds-react-wrapper/components-core/mc-input';
import { McNotification } from '@maersk-global/mds-react-wrapper/components-core/mc-notification';
import { McOption } from '@maersk-global/mds-react-wrapper/components-core/mc-option';
import { McSelect } from '@maersk-global/mds-react-wrapper/components-core/mc-select';
import { McToast } from '@maersk-global/mds-react-wrapper/components-core/mc-toast';
import React, { useEffect, useState } from 'react';
import { getCoreServiceUrl } from '../components/Util.js';
import Loading from '../components/pages/Loading.jsx';
import configData from '../config/config.json';
import useChaosTestExecution from '../hooks/ChaosExecutionTabs.jsx';
import ChaosTestExecService from '../service/ChaosTestExecService.js';
export default function HttpChaosTest(props) {
  const { chaosType } = props;
  const { testId, testData, updateTestData, chaosConfigData } = useChaosTestExecution();

  let chaosTestExecService = new ChaosTestExecService();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [labelSelector, setLabelSelector] = useState('');
  const [namespace, setNamespace] = useState('');
  const [testStatus, setTestStatus] = useState('DRAFT');
  const [port, setPort] = useState('');
  const [path, setPath] = useState('');
  const [delay, setDelay] = useState('');
  const [duration, setDuration] = useState('');
  const [method, setMethod] = useState('GET');

  const [loading, setLoading] = useState(true);
  const filterNamespaces = () => {
    let filteredNewData = [];
    testData.clusterNames.forEach((item) => {
      chaosConfigData.forEach((prevItem) => {
        if (prevItem.clusterName === item) {
          filteredNewData.push(...prevItem.namespaces);
        }
      });
    });
    return filteredNewData;
  };
  const [namespaceList] = useState(filterNamespaces());

  const [methodList] = useState(configData.httpMethods);
  let disableSubmitButton = false;
  let chaosMap;
  const handleLabelSelectorChange = (e) => {
    setLabelSelector(e.target.value);
  };
  const handleNamespaceChange = (e) => {
    setNamespace(e.target.value);
  };
  const handlePortChange = (e) => {
    setPort(e.target.value);
  };
  const handlePathChange = (e) => {
    setPath(e.target.value);
  };
  const handleDelayChange = (e) => {
    setDelay(e.target.value);
  };
  const handleDurationChange = (e) => {
    setDuration(e.target.value);
  };
  const handleMethodChange = (e) => {
    setMethod(e.target.value);
  };

  useEffect(() => {
    chaosMap =
      testData.chaosTestMap !== undefined
        ? new Map(Object.entries(testData.chaosTestMap))
        : new Map();

    //testId will not exists when it is first time load
    if (chaosMap.has(chaosType) !== false) {
      let chaosTest = chaosMap.get(chaosType);
      setMethod(chaosTest.chaosSpec.method);
      setPort(chaosTest.chaosSpec.port);
      setPath(chaosTest.chaosSpec.path);
      setDelay(chaosTest.chaosSpec.delay);
      setDuration(chaosTest.chaosSpec.duration);
      setNamespace(chaosTest.metadata.namespace);
      setLabelSelector(flattenLabelSelector(chaosTest));
      setTestStatus(chaosTest.status);
      disableSubmitButton = true;
    }
    setLoading(false);
  }, []);

  if (testData !== undefined && testData.status !== 'DRAFT') {
    disableSubmitButton = true;
  }

  function flattenLabelSelector(chaosTest) {
    let labSelTxt = new String();

    let labelMap =
      chaosTest.chaosSpec.selector.labelSelectors !== undefined
        ? new Map(Object.entries(chaosTest.chaosSpec.selector.labelSelectors))
        : new Map();

    for (const mapKey of labelMap.keys()) {
      labSelTxt += mapKey + ':' + labelMap.get(mapKey) + ',';
    }
    labSelTxt = labSelTxt !== undefined ? labSelTxt.substring(0, labSelTxt.length - 1).trim() : '';
    return labSelTxt;
  }

  function formatMapForLabels() {
    let labels = labelSelector.indexOf(',') > -1 ? labelSelector.split(',') : labelSelector;
    let labMap = new Map();

    if (typeof labels === 'string') {
      let labelObj = labels.split(':');

      labMap.set(labelObj[0].trim(), labelObj[1].trim());
    } else {
      for (let i = 0; i < labels.length; i++) {
        let labelObj = labels[i].split(':');
        labMap.set(labelObj[0].trim(), labelObj[1].trim());
      }
    }

    return labMap;
  }
  const handleChaosSave = async (e) => {
    disableSubmitButton = true;
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    //validate data
    let hasError = false;
    if (namespace === undefined || namespace.trim() === '') {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Namespace cannot be empty.');
      hasError = true;
    }
    if (labelSelector === undefined || labelSelector === '') {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Label Selector cannot be empty.');
      hasError = true;
    }
    if (port === undefined || port === '') {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Port cannot be empty.');
      hasError = true;
    }
    if (method === undefined || method === '') {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Method cannot be empty.');
      hasError = true;
    }

    if (path === undefined || path.trim() === '') {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Path cannot be empty.');
      hasError = true;
    }

    if (duration === undefined || duration === '') {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Duration cannot be empty.');
      hasError = true;
    }

    if (delay === undefined || delay === '') {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Delay cannot be empty.');
      hasError = true;
    }

    if (!hasError) {
      setLoading(true);
      let labelSelectors1 = formatMapForLabels();
      let labelSelectors = Object.fromEntries(labelSelectors1);

      let nameOfChaosTest = testId + '-' + chaosType.toLowerCase();
      let postExperiment = {
        testType: chaosType,
        status: testStatus,
        metadata: {
          namespace: namespace,
          name: nameOfChaosTest
        },
        chaosSpec: {
          mode: 'all',
          selector: {
            namespaces: [namespace],
            labelSelectors
          },
          target: 'Response',
          port: port,
          method: method,
          path: path,
          delay: delay,
          duration: duration
        }
      };

      chaosTestExecService
        .addExperimentToTest(
          getCoreServiceUrl() +
            '/core-srv/chaos-test-exec/experiment/' +
            testId +
            '/chaostype/' +
            chaosType,
          postExperiment
        )
        .then((response) => {
          if (response.status == 200) {
            setShowAlert(true);
            setAlertType('success');
            setAlertMessage('Successfully saved the chaos test ' + chaosType);
            setTimeout(() => {
              updateTestData(response.data);
            }, 100);
          } else {
            throw response;
          }
        })
        .catch((error) => {
          if (error instanceof Error) {
            setShowAlert(true);
            setAlertType('success');
            setAlertMessage(error.response.data.message);
          }
        });
    }
    setLoading(false);
  };
  function errorDialogOnClose() {
    setShowAlert(false);
  }
  function showAlertDialog() {
    let icon;

    if (alertType === 'info') {
      icon = 'info-circle';
    } else if (alertType === 'error') {
      icon = 'exclamation-octagon';
    } else if (alertType === 'success') {
      icon = 'check-circle';
    }

    return (
      <McToast
        open
        position="top-center"
        appearance={alertType}
        close={errorDialogOnClose}
        duration="5000">
        <McNotification icon={icon} body={alertMessage}></McNotification>
      </McToast>
    );
  }
  let alertDia = showAlertDialog();

  return (
    <>
      {showAlert ? alertDia : <div></div>}
      <div>
        <>
          {loading ? (
            <Loading />
          ) : (
            <div>
              <div style={{ width: '60%' }}>
                <form onSubmit={handleChaosSave}>
                  <div>
                    <div className="field">
                      <McSelect
                        name="namespace"
                        label="Namespace Selector"
                        value={namespace}
                        required
                        disabled={disableSubmitButton}
                        optionselected={handleNamespaceChange}>
                        {namespaceList !== undefined &&
                          namespaceList.map((namSpace) => (
                            <McOption key={namSpace} value={namSpace}>
                              {namSpace}
                            </McOption>
                          ))}
                      </McSelect>
                      <div className="field"></div>{' '}
                      <McInput
                        name="labelSelector"
                        type="text"
                        placeholder="Each attribute and value in double quotes separated by :"
                        value={labelSelector}
                        disabled={disableSubmitButton}
                        required
                        onInput={handleLabelSelectorChange}
                      />
                      <div className="field"></div>{' '}
                      <McSelect
                        name="method"
                        label="Method"
                        value={method}
                        required
                        optionselected={handleMethodChange}>
                        {methodList.map((d) => (
                          <McOption key={d} value={d}>
                            {d}
                          </McOption>
                        ))}
                      </McSelect>
                      <div className="field"></div>{' '}
                      <McInput
                        name="path"
                        label="Path"
                        required
                        disabled={disableSubmitButton}
                        type="text"
                        value={path}
                        onInput={handlePathChange}
                      />
                      <div className="field"></div>{' '}
                      <McInput
                        name="port"
                        label="Port"
                        placeholder="Ex: 8081"
                        required
                        disabled={disableSubmitButton}
                        type="number"
                        value={port}
                        onInput={handlePortChange}
                      />
                      <div className="field"></div>{' '}
                      <McInput
                        name="delay"
                        label="Delay"
                        placeholder="Ex: 5s"
                        required
                        disabled={disableSubmitButton}
                        type="text"
                        value={delay}
                        onInput={handleDelayChange}
                      />
                      <div className="field"></div>{' '}
                      <McInput
                        name="duration"
                        label="Duration"
                        placeholder="Ex: 5m"
                        required
                        disabled={disableSubmitButton}
                        type="text"
                        value={duration}
                        onInput={handleDurationChange}
                      />
                      <div className="field"></div>{' '}
                      <McButton type="submit" variant="filled" disabled={disableSubmitButton}>
                        Save
                      </McButton>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
}
