import axios from 'axios';
import { getCoreServiceUrl } from '../components/Util';
import { ONBOARDING_CONFIG_HEADER } from '../components/auth/AppHeaderRequestInterceptor';

class OnboardingConfigService {
  url = `${getCoreServiceUrl()}/core-srv`;

  createOnboardingConfig(onboardingConfigRequest) {
    let url = `${this.url}/onboard-application`;

    return axios
      .post(url, onboardingConfigRequest, {
        validateStatus: function (status) {
          return status === 201;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }

  getAllOnboardingConfigs() {
    let url = `${this.url}/onboarded-applications`;
    return axios
      .get(url, {
        validateStatus: function (status) {
          return status === 200;
        },
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }

  getOnboardingConfigById(id) {
    let url = `${this.url}/onboarded-application/${id}`;
    return axios
      .get(url, {
        validateStatus: function (status) {
          return status === 200;
        },
        method: 'GET',
        headers: {
          Accept: 'application/json',
          [ONBOARDING_CONFIG_HEADER]: id
        }
      })
      .then((response) => {
        return response;
      });
  }
}

export default OnboardingConfigService;
