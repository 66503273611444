// export default Routes;

import React from 'react';
// import parse from 'html-react-parser';
const Health = () => {
  //   const yourHtmlString = '{"status":"ok"}';
  return (
    <>
      <div className="px-3 py-1">SUCCESS-1</div>
    </>
  );
};
export default Health;
