import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McInput } from '@maersk-global/mds-react-wrapper/components-core/mc-input';
import React, { useState } from 'react';
import { ShowErrorNotification, ShowSuccessNotification } from '../common/notification.jsx';
import UserInfo from '../hooks/UserAppAuthorizationProvider.jsx';
import UserProfileService from '../service/UserProfileService.js';

const UserOnboarding = ({ onUserOnboard }) => {
  const { graphData } = UserInfo();
  const [userProfileData, setUserProfileData] = useState({
    status: Status.Pending,
    error: ''
  });

  const userProfileService = new UserProfileService();

  const handleSubmit = (event) => {
    setUserProfileData({ status: Status.InProgress });

    let userProfileRequest = {
      userName: graphData.name,
      emailId: graphData.username
    };
    userProfileService
      .createUserProfile(userProfileRequest)
      .then((response) => {
        onUserOnboard(response.data);
        setUserProfileData({ status: Status.Success });
      })
      .catch((error) => {
        console.error('Error while saving user profile: ', error);
        setUserProfileData({
          status: Status.Error,
          error: 'Unexpected error. Please try onboarding again.'
        });
      });
  };

  return (
    <div>
      <h5 className="app__page-title">My Profile &gt; Onboard Me</h5>
      {userProfileData.status === Status.Success ? (
        <ShowSuccessNotification message="Onboarded successfully." />
      ) : null}
      {userProfileData.status === Status.Error ? (
        <ShowErrorNotification message={userProfileData.error} />
      ) : null}
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="field">
          <McInput name="name" label="Name" value={graphData.name} disabled={true}></McInput>
          <div className="field"></div>{' '}
          <McInput
            name="username"
            label="Email"
            value={graphData.username}
            disabled={true}></McInput>
          <div className="field"></div>{' '}
          <div className="layout layout__row button-group" style={{ paddingTop: '3%' }}>
            <McButton type="submit" loading={userProfileData.status === Status.InProgress}>
              Confirm Onboarding
            </McButton>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UserOnboarding;

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error'
};
