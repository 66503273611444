import axios from 'axios';
import { getCoreServiceUrl } from '../components/Util';

class PlatformService {
  url = `${getCoreServiceUrl()}/core-srv/error-msg/codes`;

  async getErrorCodes() {
    return await axios
      .get(this.url, {
        validateStatus: function (status) {
          return status === 200;
        },
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }
}

export default PlatformService;
