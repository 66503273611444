import { McInput } from '@maersk-global/mds-react-wrapper/components-core/mc-input';
import { McOption } from '@maersk-global/mds-react-wrapper/components-core/mc-option';
import { McSelect } from '@maersk-global/mds-react-wrapper/components-core/mc-select';
import React from 'react';
import configData from '../config/config.json';
import '../stylesheets/TestType.css';

const TestInfo = ({ isEdit, testData, setTestData }) => {
  const dataTestTypes = configData.testType;

  return (
    <div className="app__page-form">
      {testData.testId ? (
        <McInput name="testId" label="Test ID" value={testData.testId} disabled={true} />
      ) : (
        ''
      )}
      <McInput
        name="testName"
        label="Test Name"
        placeholder="Enter test name"
        required
        value={testData.testName}
        disabled={!isEdit}
        blur={(event) => {
          setTestData({ ...testData, testName: event.target.value });
        }}
      />
      <McSelect
        name="testType"
        label="Test Type"
        value={testData.testType}
        disabled={!isEdit}
        optionselected={(event) => {
          setTestData({
            ...testData,
            testType: event.detail.value
          });
        }}>
        {dataTestTypes.map((testType, index) => (
          <McOption key={index} value={testType.toUpperCase()}>
            {testType}
          </McOption>
        ))}
      </McSelect>
    </div>
  );
};

export default TestInfo;

export const TestTypeInfo = {
  testName: '',
  status: 'NEW',
  testType: 'LOAD TEST',
  testTemplate: null,
  createdBy: '',
  createDateTime: ''
};
