import { McCCodePreview } from '@maersk-global/community-ui-react-wrapper';
import { McOption, McSelect, McTextarea } from '@maersk-global/mds-react-wrapper';
import React from 'react';

const TestConfig = ({ isEdit = true, testConfigData, setTestConfigData }) => {
  const configTypeList = ['Options', 'Variables'];

  const [configType, setConfigType] = React.useState(
    testConfigData.configType ? testConfigData.configType : 'Options'
  );

  const getConfigJson = (configType) => {
    let configJson = '';
    if (configType === 'Options') {
      configJson = testConfigData.optionsJson;
    } else if (configType === 'Variables') {
      configJson = testConfigData.varsJson;
    }
    configJson = configJson ? configJson : 'Configuration not available';
    return configJson;
  };

  return (
    <div className="app__page-form">
      <McSelect
        name="configType"
        label="Configuration Type"
        value={configType}
        optionselected={(event) => {
          setConfigType(event.detail.value);
        }}>
        {configTypeList.map((configType, index) => (
          <McOption key={index} value={configType}>
            {configType}
          </McOption>
        ))}
      </McSelect>
      {isEdit ? (
        <McTextarea
          name="configJson"
          hiddenlabel
          placeholder={
            configType === 'Options'
              ? 'Enter your options JSON here'
              : 'Enter your variables JSON here'
          }
          rows={20}
          value={configType === 'Options' ? testConfigData.optionsJson : testConfigData.varsJson}
          blur={(event) => {
            const val = event.target.value;
            if (configType === 'Options') {
              setTestConfigData({ ...testConfigData, optionsJson: val });
            } else if (configType === 'Variables') {
              setTestConfigData({ ...testConfigData, varsJson: val });
            }
          }}></McTextarea>
      ) : (
        <div className="mds-text--x-small-normal">
          <McCCodePreview
            fit="small"
            variant="none"
            appearance="inverse"
            hidetoggle={true}
            code={[
              {
                language: 'json',
                template: getConfigJson(configType)
              }
            ]}></McCCodePreview>
        </div>
      )}
    </div>
  );
};

export default TestConfig;

export const TestConfigData = {
  configType: 'Options',
  optionsJson: '',
  varsJson: ''
};

export const TestConfigDataValidator = (testConfigData) => {
  try {
    testConfigData.optionsJson ? JSON.parse(testConfigData.optionsJson) : '';
  } catch (error) {
    return 'Options configuration is invalid: ' + error;
  }
  try {
    testConfigData.varsJson ? JSON.parse(testConfigData.varsJson) : '';
  } catch (error) {
    return 'Variables configuration is invalid: ' + error;
  }
};
