import { McCCodePreview } from '@maersk-global/community-ui-react-wrapper';
import { McButton, McSelect } from '@maersk-global/mds-react-wrapper';
import { McIcon } from '@maersk-global/mds-react-wrapper/components-core/mc-icon';
import { McOption } from '@maersk-global/mds-react-wrapper/components-core/mc-option';
import { McTable } from '@maersk-global/mds-react-wrapper/components-core/mc-table';
import React, { useEffect, useState } from 'react';
import { getCoreServiceUrl } from '../components/Util.js';
import Loading from '../components/pages/Loading.jsx';
import LoadRegionSelect from '../components/util/LoadRegionSelect.jsx';
import TestExecService from '../service/TestExecService.js';
import TestInsightService from '../service/TestInsightService.js';
import '../stylesheets/CustomTextareaStyle.css';

const TestReportTab = ({ testData, onError, clearError }) => {
  const testExecService = new TestExecService();
  const testInsightService = new TestInsightService();

  const defaultApiStatus = {
    status: Status.Pending,
    message: ''
  };
  const [apiStatus, setApiStatus] = useState(defaultApiStatus);
  const [comparisonApiStatus, setComparisonApiStatus] = useState(Status.Pending);

  const [reportFormat, setReportFormat] = useState('nfrReport');
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState('');
  const [reportData, setReportData] = useState(ReportData);
  const [testIds, setTestIds] = useState([]);
  const [compareTestId, setCompareTestId] = useState('');

  useEffect(() => {
    testExecService
      .getCompletedTestIdsByApplication()
      .then((response) => {
        const testIds = response.data.map((test) => test.testId);
        // filter out the current testId, can't compare with itself
        const filteredTestIds = testIds.filter((id) => id !== testData.testId);
        if (filteredTestIds.length > 0) {
          // compare if there are more than one completed tests
          setTestIds(filteredTestIds);
          setCompareTestId(filteredTestIds[0]);
        }
      })
      .catch((error) => {
        console.error('Error while getting testIds for comparison: ', error);
        onError('Something unexpected happened. Please try again by refreshing the page.');
      });
  }, []);

  useEffect(() => {
    setApiStatus({ status: Status.InProgress, message: '' });
    testExecService
      .getTestResultData(`${getCoreServiceUrl()}/core-srv/test-results/${testData.testId}`)
      .then((response) => {
        if (response.status === 404) {
          // Test results not available
          setApiStatus({ status: Status.NotFound, message: '' });
          return;
        }
        let regions = response.data.map((item) => item.region);
        setRegions(regions);
        setRegion(regions[0]);

        let reportData = getReportData(response.data);
        setReportData(reportData);

        setApiStatus({ status: Status.Success, message: '' });
      })
      .catch((error) => {
        console.error('Error while retrieving test results: ', error);
        setApiStatus({
          status: Status.Error,
          message: ''
        });
        onError('Something unexpected happened. Please try again by refreshing the page.');
      });
  }, []);

  const getComparisonReport = () => {
    clearError();
    setComparisonApiStatus(Status.InProgress);

    testInsightService
      .getTestComparisonLink([compareTestId.toString(), testData.testId.toString()])
      .then((response) => {
        setComparisonApiStatus(Status.Success);
        window.open(response.data.grafana_url, '_blank');
      })
      .catch((error) => {
        console.error('Error while retrieving getting comparison report: ', error);
        setComparisonApiStatus(Status.Error);
        if (error.response.status === 400) {
          onError(
            "Test don't have enough metrics for comparison. This can happen if test has run only for few minutes. Please compare tests which has run at least for 5 mins"
          );
        } else {
          onError('Something unexpected happened. Please try again.');
        }
      });
  };

  return apiStatus.status === Status.Pending || apiStatus.status === Status.InProgress ? (
    <Loading />
  ) : (
    <div className="app__page-form">
      <div style={{ display: 'flex', gap: '16px', alignItems: 'flex-end' }}>
        <McSelect
          style={{ width: '220px' }}
          name="reportFormat"
          label="Report Format"
          value={reportFormat}
          optionselected={(event) => setReportFormat(event.detail.value)}>
          <McOption value="nfrReport">NFR Report</McOption>
          <McOption value="summaryReport">Summary Report</McOption>
          <McOption value="comparisonReport">Comparison Report</McOption>
        </McSelect>
        {reportFormat === 'comparisonReport' ? (
          <>
            <McSelect
              name="compareWithTestId"
              label="Compare with Test ID"
              value={compareTestId}
              optionselected={(event) => {
                setCompareTestId(event.detail.value);
              }}>
              {testIds.map((id) => (
                <McOption key={id} value={id}>
                  {id}
                </McOption>
              ))}
            </McSelect>
            <McButton
              variant="outlined"
              trailingicon="square-arrow-up-right"
              loading={comparisonApiStatus === Status.InProgress}
              click={(event) => {
                getComparisonReport();
              }}>
              Compare
            </McButton>
          </>
        ) : (
          <LoadRegionSelect
            filterLoadRegions={regions}
            selectedLoadRegion={region}
            onSelect={(region) => setRegion(region)}
          />
        )}
      </div>

      <div>
        {apiStatus.status === Status.Error || apiStatus.status === Status.NotFound ? (
          <ReportNotAvailable />
        ) : (
          <>
            {reportFormat === 'nfrReport' ? (
              <NFRReport thresholdResults={reportData[region].nfrReport} />
            ) : reportFormat === 'comparisonReport' ? null : (
              <div className="mds-text--x-small-normal">
                {reportData[region].summaryReport ? (
                  <McCCodePreview
                    fit="small"
                    variant="none"
                    appearance="inverse"
                    hidetoggle={true}
                    code={[
                      {
                        language: 'json',
                        template: reportData[region].summaryReport
                      }
                    ]}></McCCodePreview>
                ) : (
                  <ReportNotAvailable />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TestReportTab;

const ReportData = {
  westeurope: {
    nfrReport: [],
    summaryReport: ''
  }
};

const getReportData = (testResultsData) => {
  let reportData = testResultsData.reduce(
    (reportData, { region, resultHtml, thresholdResults }) => {
      if (!reportData[region]) {
        // add id for table datakey
        if (thresholdResults && thresholdResults.length > 0) {
          thresholdResults.forEach((item, index) => {
            item.id = index;
          });
        }
        reportData[region] = { nfrReport: thresholdResults, summaryReport: resultHtml };
      }
      return reportData;
    },
    {}
  );
  return reportData;
};

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error',
  NotFound: 'NotFound'
};

const columns = [
  { id: 'metricName', label: 'Metric Name' },
  { id: 'thresholdCriteria', label: 'Threshold', sortDisabled: true },
  {
    id: 'actualValue',
    label: 'Actual Value',
    sortDisabled: true,
    tabularFigures: true,
    dataType: { type: 'number', options: { maximumFractionDigits: 3 } }
  },
  { id: 'success', label: 'Result', sortDisabled: true }
];

const NFRReport = ({ thresholdResults }) => {
  return (
    <div>
      {thresholdResults && thresholdResults.length > 0 ? (
        <McTable data={thresholdResults} columns={columns} datakey="id">
          {thresholdResults.map((row) => (
            <div
              style={{ display: 'flex', alignItems: 'center' }}
              key={`${row.id}_success`}
              slot={`${row.id}_success`}>
              <McIcon
                icon={row.success ? 'check-circle' : 'times-circle'}
                color={row.success ? 'green' : 'red'}></McIcon>
              <span style={{ marginLeft: '4px' }}>{row.success ? 'Passed' : 'Failed'}</span>
            </div>
          ))}
        </McTable>
      ) : (
        <ReportNotAvailable />
      )}
    </div>
  );
};

const ReportNotAvailable = () => {
  return (
    <span>
      Test report not available. To see the test report, please refer{' '}
      <a
        href="https://ipace-docs.maersk-digital.net/perf-testing/defining-nfr.html"
        target="_blank"
        rel="noopener noreferrer">
        this
      </a>
      .
    </span>
  );
};
