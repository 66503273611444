import axios from 'axios';

class HashicorpVaultService {
  async postHashicorpVault(url, data) {
    return await axios.post(url, data).then((response) => {
      return response;
    });
  }

  async getHashicorpVault(url) {
    return await axios
      .get(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }
}

export default HashicorpVaultService;
