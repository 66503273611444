import axios from 'axios';
import { getCoreServiceUrl } from '../components/Util';

class UserProfileService {
  url = `${getCoreServiceUrl()}/core-srv/userprofile`;

  async createUserProfile(userProfileRequest) {
    return await axios
      .post(`${getCoreServiceUrl()}/core-srv/userprofile/new`, userProfileRequest, {
        validateStatus: function (status) {
          return status === 200;
        }
      })
      .then((response) => {
        return response;
      });
  }

  async getUserProfileByEmail() {
    let url = `${this.url}/me`;
    return await axios
      .get(url, {
        validateStatus: function (status) {
          return status === 200 || status === 403 || status === 404;
        },
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }

  async addOnboardingConfigId(email, newOnboardingConfigId) {
    let url = `${this.url}/onboardingConfigs/${newOnboardingConfigId}`;
    return await axios
      .put(url, null, {
        validateStatus: function (status) {
          return status === 200;
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }

  getOnboardedAppsForUser() {
    let url = `${this.url}/onboardingConfigs`;
    return axios
      .get(url, {
        validateStatus: function (status) {
          return status === 200;
        },
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }

  removeOnboardingConfigId(onboardingConfigId) {
    let url = `${this.url}/onboardingConfigs/${onboardingConfigId}`;
    return axios
      .delete(url, null, {
        validateStatus: function (status) {
          return status === 200;
        },
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }
}

export default UserProfileService;
