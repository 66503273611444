import axios from 'axios';

class TestTmplService {
  async getAllTestTemplate(url, pageNum, pageSize) {
    return await axios
      .get(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {},
        params: {
          pageSize: pageSize,
          pageNum: pageNum
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
  async postTestTemplate(url, data) {
    return await axios
      .post(url, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
  async updateTestTemplate(url, data) {
    return await axios
      .put(url, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
  async getTestTemplateById(url) {
    return await axios
      .get(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {},
        params: {
          pageSize: 25
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  async deleteTestTemplate(url) {
    return axios
      .delete(url, {
        validateStatus: function (status) {
          return status === 200 || status === 409;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
}
export default TestTmplService;
