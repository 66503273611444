import React, { createContext, useContext, useEffect, useState } from 'react';
import Loading from '../components/pages/Loading.jsx';
import OnboardingConfigService from '../service/OnboardingConfigService.js';
import PlatformService from '../service/PlatformService.js';
import UserProfileService from '../service/UserProfileService.js';
import ErrorCodeResolver from './ErrorCodeResolver.js';

const PageLayoutContext = createContext('UserContext');
const PlatformInfoContext = createContext();

export const UserAppAuthorizationProvider = ({ graphData, children }) => {
  // context state shared with child components
  const onUserProfileChange = (userProfile) => {
    setUserProfileData({ userProfile: userProfile, status: Status.Success });
  };

  const onOnboardingConfigChange = (onboardingConfig) => {
    setOnboardingConfigData({ onboardingConfig: onboardingConfig, status: Status.Success });
    // TODO: remove platformName, teamName, applicationName and get this from onboardingConfig
    setPlatformName(onboardingConfig.portfolioShortName);
    setTeamName(onboardingConfig.platformName);
    setApplicationName(onboardingConfig.applicationName);
  };

  // internal state to be used by this component
  // user profile of currently logged in user
  const [userProfileData, setUserProfileData] = useState({
    userProfile: null,
    status: Status.Pending,
    error: null
  });
  // application onboarding config which user is currently using in this browser tab
  const [onboardingConfigData, setOnboardingConfigData] = useState({
    onboardingConfig: null,
    status: Status.Pending,
    error: null
  });
  const [platformName, setPlatformName] = useState('');
  const [teamName, setTeamName] = useState('');
  const [applicationName, setApplicationName] = useState('');
  const [searchApplicationName, setSearchApplicationName] = useState('');

  const [errorCodeResolver, setErrorCodeResolver] = useState();

  const userProfileService = new UserProfileService();
  const onboardingConfigService = new OnboardingConfigService();
  const platformService = new PlatformService();

  const handleSearchApplicationName = (val) => {
    console.log('handleSearchApplicationName in PageContext=' + val);
    setSearchApplicationName(val);
  };

  useEffect(() => {
    getUserProfileByEmail();
    getErrorCodes();
  }, []);

  const getErrorCodes = () => {
    platformService
      .getErrorCodes()
      .then((response) => {
        let errorCodes = response.data;
        setErrorCodeResolver(new ErrorCodeResolver(errorCodes));
      })
      .catch((error) => {
        console.error('Error while getting error codes: ', error);
        setErrorCodeResolver(new ErrorCodeResolver());
      });
  };

  const getUserProfileByEmail = async () => {
    setUserProfileData({ status: Status.InProgress });
    try {
      const response = await userProfileService.getUserProfileByEmail();

      if (response.status == 403 || response.status == 404) {
        console.info('User not found');
        setUserProfileData({ status: Status.NotFound });
      } else if (response.status == 200) {
        let userProfile = response.data;
        if (!userProfile.onboardingConfigIds) {
          console.info('User does not have any application onboarding config: ', userProfile);
          setUserProfileData({
            userProfile: userProfile,
            status: Status.NotComplete
          });
          return;
        }
        console.info('User found: ', userProfile);
        let onboardingConfigResponse = await onboardingConfigService.getOnboardingConfigById(
          userProfile.onboardingConfigIds[0]
        );
        let onboardingConfigId = '';
        let onboardingConfig = onboardingConfigResponse.data;
        onboardingConfigId = onboardingConfigResponse.data.id;

        onUserProfileChange(userProfile);
        onOnboardingConfigChange(onboardingConfig);

        setSearchApplicationName(onboardingConfig.applicationName);

        return onboardingConfigId;
      }
    } catch (error) {
      console.error('Error while getting user or application onboarding config: ', error);
      setUserProfileData({
        status: Status.Error,
        error: error
      });
    }
  };

  const renderContent = () => {
    if (userProfileData.status === Status.Pending || userProfileData.status === Status.InProgress) {
      return <Loading />;
    } else if (userProfileData.status === Status.Success) {
      return (
        <PageLayoutContext.Provider
          value={{
            userProfile: userProfileData.userProfile,
            onUserProfileChange,
            graphData,
            isAdminRole: userProfileData.userProfile.role === 'ADMIN' ? true : false,
            isChaosEnabled: userProfileData.userProfile.isChaosEnabled
              ? userProfileData.userProfile.isChaosEnabled
              : 'N',
            onboardingConfig: onboardingConfigData.onboardingConfig,
            onOnboardingConfigChange,
            platformName,
            teamName,
            applicationName,
            searchApplicationName,
            handleSearchApplicationName
          }}>
          <PlatformInfoContext.Provider
            value={{
              errorCodeResolver
            }}>
            {children}
          </PlatformInfoContext.Provider>
        </PageLayoutContext.Provider>
      );
    } else if (
      userProfileData.status === Status.NotFound ||
      userProfileData.status === Status.NotComplete
    ) {
      return (
        <PageLayoutContext.Provider
          value={{
            userProfile: userProfileData.userProfile,
            graphData,
            onUserProfileChange,
            onOnboardingConfigChange
          }}>
          {children}
        </PageLayoutContext.Provider>
      );
    } else if (userProfileData.status === Status.Error) {
      return (
        <PageLayoutContext.Provider
          value={{
            graphData,
            authError: {
              message: 'Unexpected error. Please try refreshing the page.',
              cause: userProfileData.error
            }
          }}>
          {children}
        </PageLayoutContext.Provider>
      );
    }
  };

  return <>{renderContent()}</>;
};

export default function UserInfo() {
  return useContext(PageLayoutContext);
}

export function usePlatformInfo() {
  return useContext(PlatformInfoContext);
}

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  NotFound: 'NotFound',
  NotComplete: 'NotComplete',
  Error: 'Error'
};
