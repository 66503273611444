import React from 'react';
import { Navigate } from 'react-router-dom';
import UserInfo from '../hooks/UserAppAuthorizationProvider';

// @deprecated can be removed as UserAppAuthorizedTemplate handles auth error
const PrivateRoute = ({ children }) => {
  const { authError } = UserInfo();

  return authError ? <Navigate to="/" /> : children;
};

export default PrivateRoute;
