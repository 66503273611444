import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShowErrorNotification } from '../common/notification.jsx';
import Loading from '../components/pages/Loading.jsx';
import { formatDateTime, getCoreServiceUrl } from '../components/Util.js';
import IcTable from '../components/util/IcTable.jsx';
import UserInfo from '../hooks/UserAppAuthorizationProvider.jsx';
import TestTmplService from '../service/TestTmplService.js';
export default function ListTestExcnts() {
  const { applicationName, isAdminRole, searchApplicationName } = UserInfo();
  const navigate = useNavigate();
  const defaultTestExecutionListData = {
    data: [],
    totalCount: -1,
    totalPages: 0,
    currentPage: 0,
    pageSize: 50
  };
  const [TestExecutionListData, setTestExecutionListData] = useState(defaultTestExecutionListData);

  const defaultApiStatus = {
    status: Status.Pending,
    message: ''
  };
  const [apiStatus, setApiStatus] = useState(defaultApiStatus);
  const navigateToExecuteTest = (e, testId) => {
    navigate(`/perf-testing/executions/${testId}`);
  };

  React.useEffect(() => {
    setApiStatus({ status: Status.InProgress, message: '' });
    let testTmplService = new TestTmplService();
    let appNameToSearch = applicationName;
    if (isAdminRole) {
      appNameToSearch = searchApplicationName !== '' ? searchApplicationName : applicationName;
    }
    testTmplService
      .getAllTestTemplate(
        getCoreServiceUrl() + `/core-srv/test-exec/list`,
        TestExecutionListData.currentPage,
        TestExecutionListData.pageSize
      )
      .then((response) => {
        const responseData = response.data;
        setTestExecutionListData({
          ...TestExecutionListData,
          data: responseData.data,
          totalCount: responseData.totalCount,
          totalPages: responseData.totalPages
        });
        setApiStatus({ status: Status.Success, message: '' });
      })
      .catch((error) => {
        console.error('Error while retrieving TestExecution list: ', error);
        setApiStatus({
          status: Status.Error,
          message: 'Something unexpected happened. Please try again by refreshing the page.'
        });
      });
  }, [TestExecutionListData.currentPage, searchApplicationName]);

  const getButtonLabel = (status) => {
    let label = 'View Test';
    if (status === 'DRAFT') {
      label = 'Edit Test';
    } else if (status === 'FAILED_TO_START' || status === 'FAILED_TO_COMPLETE') {
      label = 'View Failure';
    } else if (status === 'COMPLETED') {
      label = 'View Report';
    }
    return label;
  };

  return (
    <div style={{ width: 'fit-content' }}>
      <h5 className="app__page-title">Performance Engineering &gt; Test Executions</h5>
      {apiStatus.status === Status.InProgress ? <Loading /> : ''}
      {apiStatus.status === Status.Error ? (
        <ShowErrorNotification message={apiStatus.message} />
      ) : null}
      <IcTable
        name="Test Execution"
        data={TestExecutionListData}
        columns={columns}
        datakey="testId"
        footer
        className="app__page-table-pagination"
        disableplaceholderfooters
        onPageChange={(nextPage) => {
          setTestExecutionListData({
            ...TestExecutionListData,
            currentPage: nextPage
          });
        }}>
        {TestExecutionListData.data?.map((row) => (
          <React.Fragment key={row.testId}>
            <div slot={`${row.testId}_templateName`}>{row.testTemplate.templateName}</div>
            <div slot={`${row.testId}_createDateTime`}>{formatDateTime(row.createDateTime)}</div>
            <div slot={`${row.testId}_action`}>
              <McButton variant="outlined" onClick={(e) => navigateToExecuteTest(e, row.testId)}>
                {getButtonLabel(row.status)}
              </McButton>
            </div>
          </React.Fragment>
        ))}
      </IcTable>
    </div>
  );
}
const columns = [
  {
    id: 'testId',
    sortDisabled: true,
    label: 'Test ID'
  },
  {
    id: 'testName',
    sortDisabled: true,
    label: 'Test Name'
  },
  {
    id: 'templateName',
    sortDisabled: true,
    label: 'Template Name'
  },
  {
    id: 'createdBy',
    sortDisabled: true,
    label: 'Created By'
  },
  {
    id: 'status',
    sortDisabled: true,
    label: 'Status'
  },
  {
    id: 'createDateTime',
    sortDisabled: true,
    label: 'Created  Date Time'
  },
  {
    id: 'action',
    sortDisabled: true,
    label: 'Action'
  }
];
const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error'
};
