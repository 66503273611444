import { McCCodePreview } from '@maersk-global/community-ui-react-wrapper';
import DOMPurify from 'dompurify';
import React, { useState } from 'react';
import Loading from '../components/pages/Loading.jsx';
import { usePlatformInfo } from '../hooks/UserAppAuthorizationProvider.jsx';
import TestExecService from '../service/TestExecService.js';

const FailureInfo = ({ testData, onError }) => {
  const { errorCodeResolver } = usePlatformInfo();
  const testExecService = new TestExecService();

  const defaultApiStatus = {
    status: Status.Pending,
    message: ''
  };
  const [apiStatus, setApiStatus] = useState(defaultApiStatus);
  const [failureInfo, setFailureInfo] = useState(FailureInfoData);

  React.useEffect(() => {
    setApiStatus({ status: Status.InProgress, message: '' });
    testExecService
      .getRecentFailure(testData.testId)
      .then((response) => {
        if (response.status === 200) {
          setFailureInfo(response.data);
        } else if (response.status === 404) {
          // use default
        }
        setApiStatus({ status: Status.Success, message: '' });
      })
      .catch((error) => {
        console.error('Error while retrieving failure info: ', error);
        setApiStatus({
          status: Status.Error,
          message: ''
        });
        onError('Something unexpected happened. Please try again by refreshing the page.');
      });
  }, []);

  function formatErrorMessage(errorMsg) {
    return errorMsg.replace(/(\\t)/g, '\t').replace(/\\n/g, '\n');
  }

  const renderFailureInfo = () => {
    return (
      <div className="app__page-form">
        <div
          style={{ marginBottom: '16px' }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              errorCodeResolver.resolve(failureInfo.errorCode).description,
              { ALLOWED_TAGS: ['a'], ALLOWED_ATTR: ['target', 'href', 'rel'] }
            )
          }}></div>
        {errorCodeResolver.resolve(failureInfo.errorCode).isUserError() && failureInfo.errorMsg ? (
          <div className="mds-text--x-small-normal">
            <McCCodePreview
              heading="Error Message"
              fit="small"
              variant="none"
              appearance="inverse"
              hidetoggle={true}
              code={[
                {
                  language: 'plaintext',
                  template: formatErrorMessage(failureInfo.errorMsg)
                }
              ]}></McCCodePreview>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <>
      {apiStatus.status === Status.InProgress ? (
        <Loading />
      ) : apiStatus.status === Status.Success ? (
        renderFailureInfo()
      ) : null}
    </>
  );
};

export default FailureInfo;

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error',
  NotSupported: 'NotSupported'
};

const FailureInfoData = {
  errorCode: '',
  errorMsg: ''
};
