import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import UserInfo from '../../hooks/UserAppAuthorizationProvider';

export const ONBOARDING_CONFIG_HEADER = 'X-Ipace-Onboarded-App-Id';

const AppHeaderRequestInterceptor = ({ children }) => {
  const { onboardingConfig } = UserInfo();

  const onboardingConfigRef = useRef(onboardingConfig);
  useEffect(() => {
    onboardingConfigRef.current = onboardingConfig;
  }, [onboardingConfig]);

  const [interceptorRegistered, setInterceptorRegistered] = useState(false);

  // request interceptor
  useEffect(() => {
    const interceptor = axios.interceptors.request.use(async (config) => {
      // Send custom header with onboarded app id for core service APIs only
      let url = new URL(config.url);
      if (!url.pathname.startsWith('/git')) {
        config.headers[ONBOARDING_CONFIG_HEADER] = onboardingConfigRef.current.id;
      }
      return config;
    });

    setInterceptorRegistered(true);

    // Cleanup function to remove the interceptor when the component unmounts
    return () => {
      axios.interceptors.request.eject(interceptor);
    };
  }, []);

  return interceptorRegistered ? <>{children}</> : null;
};

export default AppHeaderRequestInterceptor;
