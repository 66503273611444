import { McCheckbox, McCheckboxGroup } from '@maersk-global/mds-react-wrapper';
import React, { useRef } from 'react';
import allLoadRegions from '../../EngineRegions.json';

const LoadRegion = ({ isEdit = true, loadRegionData, setLoadRegionData }) => {
  // Add selectedZoneIndexes to each region without modifying the original data
  const loadRegionsRef = useRef(
    allLoadRegions[process.env.REACT_APP_ENV].map((loadRegion) => {
      let newLoadRegion = { ...loadRegion };
      // find the selected region
      const selLoadRegion = loadRegionData.find(
        (selLoadRegion) => selLoadRegion.region === loadRegion.regionName
      );
      if (selLoadRegion) {
        // set true for selected zones, false for others
        newLoadRegion.selectedZoneIndexes = loadRegion.zoneListName.map((zone) =>
          selLoadRegion.zoneList.includes(zone)
        );
      } else {
        // set false for all zones
        newLoadRegion.selectedZoneIndexes = new Array(loadRegion.zoneList.length).fill(false);
      }
      return newLoadRegion;
    })
  );

  const handleCheckBox = (event, regionIndex) => {
    let selectedZoneIndexes = event.detail;

    // update the selectedZoneIndexes for the region
    loadRegionsRef.current[regionIndex].selectedZoneIndexes.fill(false);
    selectedZoneIndexes.forEach((selectedZoneIndex) => {
      loadRegionsRef.current[regionIndex].selectedZoneIndexes[selectedZoneIndex] = true;
    });

    // update the parent state
    let selectedLoadRegions = [];
    loadRegionsRef.current.forEach((loadRegion) => {
      let selectedZones = [];
      loadRegion.selectedZoneIndexes.forEach((selected, index) => {
        if (selected) {
          selectedZones.push(loadRegion.zoneListName[index]);
        }
      });
      if (selectedZones.length > 0) {
        selectedLoadRegions.push({
          region: loadRegion.regionName,
          zoneList: selectedZones
        });
      }
    });
    setLoadRegionData(selectedLoadRegions);
  };

  return (
    <div className="app__page-form">
      {loadRegionsRef.current.map((loadRegion, regionIndex) => {
        return (
          <div style={{ marginBottom: '16px' }} key={loadRegion.region}>
            <McCheckboxGroup
              legend={loadRegion.region}
              orientation="horizontal"
              change={(event) => handleCheckBox(event, regionIndex)}>
              {loadRegion.zoneList.map((eachZone, zoneIndex) => {
                return (
                  <React.Fragment key={eachZone}>
                    <McCheckbox
                      label={eachZone}
                      checked={loadRegionsRef.current[regionIndex].selectedZoneIndexes[zoneIndex]}
                      disabled={!isEdit}
                      value={zoneIndex}
                    />
                  </React.Fragment>
                );
              })}
            </McCheckboxGroup>
          </div>
        );
      })}
    </div>
  );
};

export default LoadRegion;

export const LoadRegionData = [
  {
    region: 'europe',
    zoneList: ['west']
  }
];
