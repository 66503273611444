import { McButton } from '@maersk-global/mds-react-wrapper';
import { McDateRange } from '@maersk-global/mds-react-wrapper/components-core/mc-date-range';
import React, { useEffect, useState } from 'react';
import ApplicationOnboardingService from '../Onboarding/ApplicationOnboardingService';
import Loading from '../components/pages/Loading';
import InsightsService from './InsightsService';
import InsightsTableReport from './InsightsTableReport';
const config = require('../config/config.json');

const allLoadRegions = require('../EngineRegions.json');
const loadRegions = allLoadRegions[process.env.REACT_APP_ENV].map((loadRegion) => ({
  ...loadRegion,
  zoneIDs: loadRegion.zoneListName.map((zone) => `${zone}${loadRegion.regionName}`)
}));
const Insights = () => {
  const [cache, setCache] = useState({
    applications: [],
    platforms: [],
    portfolioList: [],
    portfolioMap: {},
    status: Status.Pending,
    error: ''
  });

  const [portfolioList] = useState(config.platformNames);
  const [resultsTableData, setResultsTableData] = useState({});

  const getStartOfWeek = () => {
    let date = new Date();
    let dayOfWeek = date.getDay();
    // Monday to Sunday week
    let difference = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    date.setDate(date.getDate() - difference);
    return date.toISOString().slice(0, 10);
  };

  const getToday = () => {
    let date = new Date();
    return date.toISOString().slice(0, 10);
  };

  const defaultInsightsData = {
    from: getStartOfWeek(),
    to: getToday(),
    fromToChanged: true,
    defaultData: [],
    data: [],
    status: Status.InProgress,
    error: ''
  };

  const [insightsData, setInsightsData] = useState(defaultInsightsData);

  const getPortfolios = () => {
    const portfolioList = config.platformNames;
    const portfolioMap = { [config.undefinedPortfolio.macPortfolioId]: config.undefinedPortfolio };
    // add MAC portfolio id to portfolio mapping
    portfolioList.reduce((map, portfolio) => {
      map[portfolio.macPortfolioId] = portfolio;
      return map;
    }, portfolioMap);
    // add portfolio short name to portfolio mapping
    portfolioMap[config.undefinedPortfolio.value] = config.undefinedPortfolio;
    portfolioList.reduce((map, portfolio) => {
      map[portfolio.value] = portfolio;
      return map;
    }, portfolioMap);

    return { portfolioList: portfolioList, portfolioMap: portfolioMap };
  };

  const setDateFilter = (event) => {
    if (
      insightsData.from !== event.target.value.from ||
      insightsData.to !== event.target.value.to
    ) {
      setInsightsData((prevState) => ({
        ...prevState,
        from: event.target.value.from,
        to: event.target.value.to,
        fromToChanged: true
      }));
    }
  };

  const isDateFilterDisabled = () => {
    return (
      insightsData.status === Status.InProgress ||
      !(insightsData.fromToChanged && insightsData.from && insightsData.to)
    );
  };

  const dateFilterHandler = (event) => {
    getInsights();
  };

  const mapData = (data) => {
    return data.map((item) => {
      const matchedPortfolio = portfolioList.find(
        (portfolio) => portfolio.value === item.portfolioShortName
      );
      if (matchedPortfolio) {
        item.portfolioShortName = matchedPortfolio.name;
      }
      item.totalTestRunDurationMins = item.totalTestRunDurationMins.toString() + 'mins';

      for (const regionData of loadRegions) {
        for (const [index, zoneID] of regionData.zoneIDs.entries()) {
          const zoneIndex = item.region.indexOf(zoneID);
          if (zoneIndex !== -1) {
            item.region[zoneIndex] = regionData.zoneList[index] + ' ' + regionData.region;
          }
        }
      }

      return item;
    });
  };

  const insightsService = new InsightsService();

  const getInsights = () => {
    setInsightsData((prevState) => ({
      ...prevState,
      status: Status.InProgress
    }));

    let requestData = {
      startTime: insightsData.from,
      endTime: insightsData.to,
      granularity: 'ALL'
    };
    insightsService
      .getTestResults(requestData)
      .then((response) => {
        let defaultData = insightsData.defaultData;
        let availableData = response.data ? response.data : [];

        // use available data for insights, for applications with no insights for the time period use default data
        // both default and available data are expected to be sorted by portfolioShortName, platformName, applicationName
        let i = 0,
          j = 0;
        let data = [];

        while (i < defaultData.length && j < availableData.length) {
          let defaultDataAppId =
            defaultData[i].portfolioShortName +
            defaultData[i].platformName +
            defaultData[i].applicationName;
          let availableDataAppId =
            availableData[j].portfolioShortName +
            availableData[j].platformName +
            availableData[j].applicationName;

          if (defaultDataAppId < availableDataAppId) {
            data.push(defaultData[i]);
            i++;
          } else {
            data.push(availableData[j]);
            i++;
            j++;
          }
        }

        while (i < defaultData.length) {
          data.push(defaultData[i]);
          i++;
        }

        setInsightsData((prevState) => ({
          ...prevState,
          data: data,
          status: Status.Success,
          fromToChanged: false
        }));
      })
      .catch((error) => {
        console.error('Error while getting test insights ', error);
        setInsightsData((prevState) => ({
          ...prevState,
          status: Status.Error,
          error: 'Unexpected error while getting test insights. Please click on Apply again.'
        }));
      });
  };

  const applicationOnboardingService = new ApplicationOnboardingService();

  // get default insights for all onboarded applications
  useEffect(() => {
    setInsightsData((prevState) => ({ ...prevState, status: Status.InProgress }));

    // get all applications with default insights data
    applicationOnboardingService
      .getAllOnboardingConfigs()
      .then((response) => {
        let applications = response.data;
        let defaultData = applications.map((eachApp) => ({
          portfolioShortName: eachApp.portfolioShortName,
          platformName: eachApp.platformName,
          applicationName: eachApp.applicationName,
          macId: [eachApp.macId],
          region: eachApp.region || [],
          testType: eachApp.testType || [],
          nfrStatus: eachApp.nfrStatus || [],
          testDurationMins: eachApp.testDurationMins || 0,
          testCount: eachApp.testCount || 0
        }));

        setInsightsData((prevState) => ({ ...prevState, defaultData: defaultData }));
      })
      .catch((error) => {
        console.error('Error while getting onboarded applications', error);
        setInsightsData((prevState) => ({
          ...prevState,
          status: Status.Error,
          error: 'Unexpected error while getting test insights. Please click on Apply again.'
        }));
      });
  }, []);

  // get available insights for the time period
  useEffect(() => {
    if (insightsData.defaultData.length > 0) {
      getInsights();
    }
  }, [insightsData.defaultData]);

  return (
    <div style={{ width: 'fit-content' }}>
      <h5 className="app__page-title">Insights &gt; Performance Tests</h5>
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-end',
          marginBottom: '16px'
        }}>
        <McDateRange
          name="dateFilter"
          format="YYYY-MM-DD"
          placeholder="YYYY-MM-DD"
          style={{ marginRight: '12px' }}
          input={(event) => setDateFilter(event)}
          value={{ from: insightsData.from, to: insightsData.to }}
        />
        <McButton disabled={isDateFilterDisabled()} click={dateFilterHandler}>
          Apply
        </McButton>
      </div>

      <div>
        {insightsData.status === Status.InProgress ? (
          <Loading />
        ) : (
          <InsightsTableReport data={insightsData.data} />
        )}
      </div>
    </div>
  );
};

export default Insights;

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error'
};
