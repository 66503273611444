import axios from 'axios';
import { getCoreServiceUrl } from '../Util';

class TestIntegrationService {
  url = `${getCoreServiceUrl()}/core-srv`;

  getIntegrationClients() {
    let url = `${this.url}/test-integration/clients/list`;
    return axios
      .get(url, {
        validateStatus: function (status) {
          return status === 200;
        },
        headers: {
          Accept: 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }

  createOrUpdateTestIntegration(data, isUpdate) {
    if (isUpdate) {
      return this.updateTestIntegration(data);
    } else {
      return this.createTestIntegration(data);
    }
  }

  createTestIntegration(data) {
    let url = `${this.url}/test-integration/new`;

    return axios
      .post(url, data, {
        validateStatus: function (status) {
          return status === 201;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }

  getTestIntegrations(currentPage, pageSize) {
    let url = `${this.url}/test-integration/list?pageNum=${currentPage}&pageSize=${pageSize}`;
    return axios
      .get(url, {
        validateStatus: function (status) {
          return status === 200;
        },
        headers: {
          Accept: 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }

  getTestIntegrationById(id) {
    let url = `${this.url}/test-integration/${id}`;

    return axios
      .get(url, {
        validateStatus: function (status) {
          return status === 200;
        },
        headers: {
          Accept: 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }

  updateTestIntegration(data) {
    let url = `${this.url}/test-integration/${data.id}`;

    return axios
      .put(url, data, {
        validateStatus: function (status) {
          return status === 200;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }

  deleteTestIntegration(id) {
    let url = `${this.url}/test-integration/${id}`;

    return axios
      .delete(url, {
        validateStatus: function (status) {
          return status === 204;
        },
        headers: {
          Accept: 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }

  getWorkflowPreview(id) {
    let url = `${this.url}/test-integration/${id}/workflow-preview`;

    return axios
      .get(url, {
        validateStatus: function (status) {
          return status === 200;
        },
        headers: {
          Accept: 'text/plain'
        }
      })
      .then((response) => {
        return response;
      });
  }
}

export default TestIntegrationService;
