class ErrorCodeResolver {
  constructor(errorCodes) {
    const errorCodesWrapped = {};
    if (errorCodes) {
      // Iterate over the errorCodes and wrap into ErrorCode instances
      for (const [code, details] of Object.entries(errorCodes)) {
        errorCodesWrapped[code] = new ErrorCode(code, details.description, details.type);
      }
    }

    this.errorCodes = errorCodesWrapped;
  }

  resolve(errorCode) {
    let errorCodeWrapped = this.errorCodes[errorCode];
    if (!errorCodeWrapped) {
      errorCodeWrapped = new ErrorCode(errorCode, `'${errorCode}' error code not found.`, 'SYSTEM');
    }
    return errorCodeWrapped;
  }
}

export default ErrorCodeResolver;

class ErrorCode {
  constructor(code, description, type) {
    this.code = code;
    this.description = description;
    this.type = type;
  }

  isUserError() {
    return this.type === 'USER';
  }
}
