import { McInput, McOption, McSelect } from '@maersk-global/mds-react-wrapper';
import React from 'react';
import configData from '../../config/config.json';
import { formatDateTime } from '../Util';

const ScheduleInfo = ({ scheduleInfoData, setScheduleInfoData }) => {
  const isEdit = scheduleInfoData.id ? true : false;

  return (
    <div className="app__page-form">
      {isEdit ? (
        <McInput
          name="scheduleId"
          label="Schedule ID"
          disabled={true}
          value={scheduleInfoData.id}
        />
      ) : (
        ''
      )}
      <McInput
        name="scheduleName"
        label="Schedule Name"
        value={scheduleInfoData.scheduleName}
        input={(event) =>
          setScheduleInfoData({ ...scheduleInfoData, scheduleName: event.target.value })
        }
      />
      <McInput
        name="cronExpression"
        label="Schedule Interval"
        placeholder="0 6 * * 0 (to run every Sunday at 6:00 AM UTC)"
        value={scheduleInfoData.cronExpression}
        input={(event) =>
          setScheduleInfoData({ ...scheduleInfoData, cronExpression: event.target.value })
        }>
        <span slot="hint">
          Schedule interval is defined as cron expression. You can use{' '}
          <a
            className="mds-neutral--weak__text-color"
            href="https://crontab.guru/"
            target="_blank"
            rel="noopener noreferrer">
            crontab
          </a>{' '}
          to create one.
        </span>
      </McInput>
      <McSelect
        name="active"
        label="Active"
        value={scheduleInfoData.active}
        optionselected={(event) => {
          setScheduleInfoData({ ...scheduleInfoData, active: event.detail.value });
        }}>
        <McOption value="true">Yes</McOption>
        <McOption value="false">No</McOption>
      </McSelect>
      <McInput
        name="templateName"
        label="Test Template Name"
        disabled={true}
        value={scheduleInfoData.testTemplateName}
      />
      <McSelect
        name="testType"
        label="Test Type"
        value={scheduleInfoData.testType}
        optionselected={(event) => {
          setScheduleInfoData({ ...scheduleInfoData, testType: event.detail.value });
        }}>
        {configData.testType.map((testType, index) => (
          <McOption key={index} value={testType.toUpperCase()}>
            {testType}
          </McOption>
        ))}
      </McSelect>
      {isEdit ? (
        <>
          <McInput
            name="createdBy"
            label="Updated By"
            value={scheduleInfoData.updatedBy}
            disabled={true}
          />
          <McInput
            name="createdDateTime"
            label="Updated Date Time"
            value={formatDateTime(scheduleInfoData.updatedDateTime)}
            disabled={true}
          />
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default ScheduleInfo;

export const ScheduleInfoData = {
  id: '',
  scheduleName: '',
  cronExpression: '',
  active: 'true',
  testTemplateId: '',
  testTemplateName: '',
  testType: 'LOAD TEST',
  updatedBy: '',
  updatedDateTime: ''
};
