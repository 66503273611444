import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McInput } from '@maersk-global/mds-react-wrapper/components-core/mc-input';
import { McNotification } from '@maersk-global/mds-react-wrapper/components-core/mc-notification';
import { McOption } from '@maersk-global/mds-react-wrapper/components-core/mc-option';
import { McSelect } from '@maersk-global/mds-react-wrapper/components-core/mc-select';
import { McToast } from '@maersk-global/mds-react-wrapper/components-core/mc-toast';
import React, { useEffect, useState } from 'react';
import { getCoreServiceUrl } from '../components/Util.js';
import Loading from '../components/pages/Loading';
import configData from '../config/config.json';
import useChaosTestExecution from '../hooks/ChaosExecutionTabs';
import ChaosTestExecService from '../service/ChaosTestExecService';
import '../stylesheets/ChaosTest.css';
export default function PodFailureChaosTest(props) {
  const { chaosType } = props;
  const { testId, testData, updateTestData, chaosConfigData } = useChaosTestExecution();
  const [fit] = React.useState('medium');
  let chaosTestExecService = new ChaosTestExecService();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [labelSelector, setLabelSelector] = useState('');
  const [namespace, setNamespace] = useState('');
  const [testStatus, setTestStatus] = useState('DRAFT');
  const [chaosAction, setChaosAction] = useState('pod-failure');
  const [chaosMode, setChaosMode] = useState('');
  const [duration, setDuration] = useState('');
  const [chaosModePercentValue, setChaosModePercentValue] = useState(0);
  const [enableChaosModePerct, setEnableChaosModePerct] = useState(false);
  const [loading, setLoading] = useState(true);

  const filterNamespaces = () => {
    let filteredNewData = [];
    testData.clusterNames.forEach((item) => {
      chaosConfigData.forEach((prevItem) => {
        if (prevItem.clusterName === item) {
          filteredNewData.push(...prevItem.namespaces);
        }
      });
    });
    return filteredNewData;
  };
  const [namespaceList] = useState(filterNamespaces());
  const [chaosActionList] = useState(configData.chaosActions);
  const [chaosModeList] = useState(configData.chaosModes);

  let disableSubmitButton = false;
  let chaosMap;
  const handleLabelSelectorChange = (e) => {
    setLabelSelector(e.target.value);
  };
  const handleNamespaceChange = (e) => {
    setNamespace(e.target.value);
  };
  const handleChaosActionChange = (e) => {
    setChaosAction(e.target.value);
  };
  const handleChaosModeChange = (e) => {
    if (e.target.value === 'fixed-percent') {
      //enable
      setEnableChaosModePerct(true);
    } else {
      setEnableChaosModePerct(false);
      setChaosModePercentValue(0);
    }
    setChaosMode(e.target.value);
  };
  const handleDurationChange = (e) => {
    setDuration(e.target.value);
  };

  const handleChaosModePercentValueChange = (e) => {
    setChaosModePercentValue(e.target.value);
  };

  useEffect(() => {
    chaosMap =
      testData.chaosTestMap !== undefined
        ? new Map(Object.entries(testData.chaosTestMap))
        : new Map();

    //testId will not exists when it is first time load
    if (chaosMap.has(chaosType) !== false) {
      let chaosTest = chaosMap.get(chaosType);
      setChaosAction(chaosTest.chaosSpec.action);
      setChaosMode(chaosTest.chaosSpec.mode);
      setDuration(chaosTest.chaosSpec.duration);
      setNamespace(chaosTest.metadata.namespace);
      setLabelSelector(flattenLabelSelector(chaosTest));
      setTestStatus(chaosTest.status);
      disableSubmitButton = true;

      if (chaosTest.chaosSpec.mode === 'fixed-percent') {
        setChaosModePercentValue(chaosTest.chaosSpec.value);
        setEnableChaosModePerct(true);
      }
    }
    if (chaosMode === 'fixed-percent') {
      setEnableChaosModePerct(true);
    }
    setLoading(false);
  });

  if (testData !== undefined && testData.status !== 'DRAFT') {
    disableSubmitButton = true;
  }

  function flattenLabelSelector(chaosTest) {
    let labSelTxt = new String();
    let labelMap =
      chaosTest.chaosSpec.selector.labelSelectors !== undefined
        ? new Map(Object.entries(chaosTest.chaosSpec.selector.labelSelectors))
        : new Map();

    for (const mapKey of labelMap.keys()) {
      labSelTxt += mapKey + ':' + labelMap.get(mapKey) + ',';
    }
    //remove the last commma
    labSelTxt = labSelTxt !== undefined ? labSelTxt.substring(0, labSelTxt.length - 1).trim() : '';

    return labSelTxt;
  }

  function formatMapForLabels() {
    let labels = labelSelector.indexOf(',') > -1 ? labelSelector.split(',') : labelSelector;
    let labMap = new Map();

    if (typeof labels === 'string') {
      let labelObj = labels.split(':');

      labMap.set(labelObj[0].trim(), labelObj[1].trim());
    } else {
      for (let i = 0; i < labels.length; i++) {
        let labelObj = labels[i].split(':');
        labMap.set(labelObj[0].trim(), labelObj[1].trim());
      }
    }

    return labMap;
  }
  const isDurationInAllowedLimit = () => {
    let uom = duration.substring(duration.length - 1);
    let beyondAllowedLimit = false;
    let absVal = duration.substring(0, duration.length - 1);
    let valInHr = 0;
    switch (uom) {
      case 's':
        valInHr = absVal / 3600;
        break;
      case 'm':
        valInHr = absVal / 60;
        break;
      case 'h':
        valInHr = absVal;
        break;
      default:
        break;
    }
    if (valInHr > 2) {
      beyondAllowedLimit = true;
    }

    return beyondAllowedLimit;
  };

  const handleChaosSave = async (e) => {
    disableSubmitButton = true;
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    //validate data
    let hasError = false;
    if (namespace === undefined || namespace.trim() === '') {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Namespace cannot be empty');
      hasError = true;
    }
    if (isDurationInAllowedLimit()) {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Maximum allowed test duration is 2 hours');
      hasError = true;
    }
    if (
      chaosMode === 'fixed-percent' &&
      (chaosModePercentValue === '' || chaosModePercentValue === 0)
    ) {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Percentage value should be entered.');
      setEnableChaosModePerct(true);
      hasError = true;
    }

    if (!hasError) {
      setLoading(true);
      let labelSelectors1 = formatMapForLabels();
      let labelSelectors = Object.fromEntries(labelSelectors1);

      let aa = new String();
      aa = '"' + chaosModePercentValue + '"';

      let nameOfChaosTest = testId + '-' + chaosType.toLowerCase();
      let isFixedPerct = chaosMode === 'fixed-percent' ? true : false;
      let postExperiment = {
        testType: chaosType,
        status: testStatus,
        metadata: {
          name: nameOfChaosTest,
          namespace: namespace
        },
        chaosSpec: {
          action: chaosAction,
          mode: chaosMode,
          duration: duration,
          selector: {
            labelSelectors,
            namespaces: [namespace]
          }
        }
      };
      if (isFixedPerct) {
        postExperiment.chaosSpec.value = aa;
      }

      chaosTestExecService
        .addExperimentToTest(
          getCoreServiceUrl() +
            '/core-srv/chaos-test-exec/experiment/' +
            testId +
            '/chaostype/' +
            chaosType,
          postExperiment
        )
        .then((response) => {
          if (response.status == 200) {
            setShowAlert(true);
            setAlertType('success');
            setAlertMessage('Successfully saved the chaos test ' + chaosType);
            setTimeout(() => {
              updateTestData(response.data);
            }, 100);
            setLoading(false);
          } else {
            throw response;
          }
        })
        .catch((error) => {
          if (error instanceof Error) {
            setShowAlert(true);
            setAlertType('error');
            setAlertMessage(error.response.data.message);
          }
        });
    }
  };

  function errorDialogOnClose() {
    setShowAlert(false);
  }
  function showAlertDialog() {
    let icon;

    if (alertType === 'info') {
      icon = 'info-circle';
    } else if (alertType === 'error') {
      icon = 'exclamation-octagon';
    } else if (alertType === 'success') {
      icon = 'check-circle';
    }

    return (
      <McToast
        open
        position="top-center"
        appearance={alertType}
        close={errorDialogOnClose}
        duration="5000">
        <McNotification icon={icon} body={alertMessage}></McNotification>
      </McToast>
    );
  }
  let alertDia = showAlertDialog();

  return (
    <>
      {showAlert ? alertDia : <div></div>}

      <div>
        <>
          {loading ? (
            <Loading />
          ) : (
            <div>
              <div style={{ width: '60%' }}>
                <form onSubmit={handleChaosSave}>
                  <div>
                    <div className="field">
                      <McInput
                        name="chaosType"
                        label="Experiment Type"
                        value={chaosType}
                        required
                        disabled></McInput>
                      <div className="field"></div>{' '}
                      <McInput
                        name="testStatus"
                        label="Test Status"
                        fit={fit}
                        value={testStatus}
                        disabled={true}
                        required
                      />
                      <div className="field"></div>{' '}
                      <McSelect
                        name="namespace"
                        label="Namespace Selector"
                        fit={fit}
                        value={namespace}
                        disabled={disableSubmitButton}
                        required
                        optionselected={handleNamespaceChange}>
                        {namespaceList !== undefined &&
                          namespaceList.map((namSpace) => (
                            <McOption key={namSpace} value={namSpace}>
                              {namSpace}
                            </McOption>
                          ))}
                      </McSelect>
                      <div className="field"></div>{' '}
                      <McInput
                        name="labelSelector"
                        label="Label Selector"
                        fit={fit}
                        disabled={disableSubmitButton}
                        type="text"
                        placeholder="Each attribute and value in double quotes separated by :"
                        value={labelSelector}
                        required
                        onInput={handleLabelSelectorChange}
                      />
                      <div className="field"></div>{' '}
                      <McSelect
                        name="chaosAction"
                        label="Action"
                        fit={fit}
                        value={chaosAction}
                        required
                        disabled
                        optionselected={handleChaosActionChange}>
                        {chaosActionList.map((d) => (
                          <McOption key={d} value={d}>
                            {d}
                          </McOption>
                        ))}
                      </McSelect>
                      <div className="field"></div>{' '}
                      <McSelect
                        name="chaosMode"
                        label="Mode"
                        fit={fit}
                        value={chaosMode}
                        disabled={disableSubmitButton}
                        required
                        optionselected={handleChaosModeChange}>
                        {chaosModeList.map((d) => (
                          <McOption key={d} value={d}>
                            {d}
                          </McOption>
                        ))}
                      </McSelect>
                      <div className="field"></div>{' '}
                      <McInput
                        name="chaosModePercentValue"
                        label={`Percentage value ${chaosModePercentValue}`}
                        fit={fit}
                        type="range"
                        placeholder="Enter a percentage value"
                        value={chaosModePercentValue}
                        min="1"
                        max="100"
                        disabled={!enableChaosModePerct}
                        onInput={handleChaosModePercentValueChange}
                      />
                      <div className="field"></div>{' '}
                      <McInput
                        name="duration"
                        label="Duration of test"
                        fit={fit}
                        disabled={disableSubmitButton}
                        type="text"
                        placeholder="Duration (in seconds or minutes) Ex: 1s or 1m"
                        value={duration}
                        required
                        onInput={handleDurationChange}
                      />
                      <div className="field"></div>{' '}
                      <McButton
                        type="submit"
                        disabled={disableSubmitButton}
                        variant="filled"
                        fit={fit}>
                        Save
                      </McButton>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
}
