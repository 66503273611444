import { McButton } from '@maersk-global/mds-react-wrapper';
import useChaosTestExecution from '../hooks/ChaosExecutionTabs';
import '../stylesheets/ChaosDashboard.css';
import { useEffect, useState } from 'react';
function ChaosDashboard() {
  const { testData } = useChaosTestExecution();
  const [envMetricUrl, setEnvMetricUrl] = useState(process.env.REACT_APP_CHAOS_PODFAULT);
  const envHttpMetricUrl = process.env.REACT_APP_CHAOS_HTTPFAULT;

  useEffect(() => {
    if (testData && testData.chaosTestMap && Object.keys(testData.chaosTestMap).length > 0) {
      const testType = testData.chaosTestMap[Object.keys(testData.chaosTestMap)[0]].testType;

      if (testType === 'HTTPDELAY' || testType === 'HTTPREPLACE' || testType === 'HTTPABORT') {
        setEnvMetricUrl(envHttpMetricUrl);
      }
    }
  }, [testData]);

  let testId = testData.testId;
  function getUnixDateTime(startTime) {
    if (startTime !== undefined) {
      let aa = [...startTime];
      const epocNowMillisDD = Date.UTC(aa[0], aa[1] - 1, aa[2], aa[3], aa[4], aa[5]);

      return epocNowMillisDD;
    } else {
      let currD = new Date();
      const epocNowMillis = Date.UTC(
        currD.getFullYear(),
        currD.getMonth(),
        currD.getDate(),
        currD.getHours(),
        currD.getMinutes(),
        currD.getSeconds()
      );

      return epocNowMillis;
    }
  }

  let clusterName = testData.clusterNames[0];

  let chaosMap = new Map(Object.entries(testData.chaosTestMap));

  const getMetricUrlForDashBoard = (chaosTest) => {
    let metricUrl;

    let unixTestStartTime = getUnixDateTime(chaosTest.startDateTime);
    let unixTestEndTime = null;
    if (chaosTest.stopDateTime !== undefined) {
      unixTestEndTime = getUnixDateTime(chaosTest.stopDateTime);
    }
    let namsp =
      chaosTest.chaosSpec.selector.namespaces !== undefined
        ? chaosTest.chaosSpec.selector.namespaces[0]
        : '';
    let searchName = testId + '-' + chaosTest.testType.toLowerCase();
    if (unixTestEndTime === null) {
      metricUrl =
        envMetricUrl +
        '?orgId=1&var-node=All' +
        '&var-cluster=' +
        clusterName +
        '&var-namespace=' +
        namsp +
        '&var-contains=' +
        searchName +
        '&from=' +
        unixTestStartTime +
        '&refresh=5s';
    } else {
      metricUrl =
        envMetricUrl +
        '?orgId=1&var-node=All' +
        '&var-cluster=' +
        clusterName +
        '&var-namespace=' +
        namsp +
        '&var-contains=' +
        searchName +
        '&from=' +
        unixTestStartTime +
        '&to=' +
        unixTestEndTime +
        '&refresh=5s';
    }

    return metricUrl;
  };
  const renderDashBoardLink = (myValue, myKey) => {
    return chaosMap.has(myKey) === true ? (
      <McButton
        key={myKey}
        variant="filled"
        href={getMetricUrlForDashBoard(myValue)}
        target="_blank"
        style={{ marginRight: '32px' }}>
        {myKey} Metrics
      </McButton>
    ) : (
      ''
    );
  };

  const renderPage = () => {
    let buttonRender = [];
    {
      chaosMap.forEach((myValue, myKey) => buttonRender.push(renderDashBoardLink(myValue, myKey)));
    }

    return (
      <div
        className="dashboardBox"
        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {[...buttonRender]}
      </div>
    );
  };
  return renderPage();
}

export default ChaosDashboard;
