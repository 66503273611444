import { McPagination, McTable } from '@maersk-global/mds-react-wrapper';
import React from 'react';

/**
 * iPACE table component which wraps the McTable component and adds additional functionality
 *
 * @param {string} name - Name of the table representing the data
 * @param {Function} onPageChange - Function called when the page changes
 * @param {Object} data - Data to be displayed in the table and of the form {data: [], totalCount: 0, totalPages: 0}
 * @param {...Object} props - Any property that can be passed to McTable component.
 */
const IcTable = ({ name, onPageChange, data, children, ...props }) => {
  const columns = props.columns;

  return (
    <>
      <McTable columns={columns} data={data.data} {...props}>
        {children}
        <div slot={`${columns[0].id}_footer`}>
          {data.totalCount == -1
            ? 'Total:'
            : data.totalCount
            ? `Total: ${data.totalCount}`
            : `No ${name.toLowerCase()}s found`}
        </div>
      </McTable>
      <div className="app__page-table-pagination">
        <McPagination
          totalpages={data.totalPages}
          pagechange={(event) => {
            // subtract 1 from the page number as MDS page number start from 1 while API page number starts from 0
            onPageChange(event.detail - 1);
          }}
        />
      </div>
    </>
  );
};

export default IcTable;
