import axios from 'axios';

function handleError(error) {
  console.log('Error = ' + error);
  return error;
}

class GetTestScriptDataService {
  async getTestScriptData(url, data) {
    return await axios
      .post(url, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return handleError(error);
      });
  }

  async getFileListData(url, data) {
    return await axios
      .post(url, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return handleError(error);
      });
  }
}

export default GetTestScriptDataService;
