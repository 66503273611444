import { createContext, useContext, useState } from 'react';

export const ChaosExecutionTabsProvider = ({
  testId,
  testData,
  showAlertMsg,
  updateTestData,
  addExperiment,
  chaosConfigData,
  clusterObjMap,
  environment,
  getConfigDataForChaos,
  setEnvironment,
  children
}) => {
  const [state, setState] = useState();
  return (
    <ChaosExecutionTabsContext.Provider
      value={{
        testId,
        testData,
        showAlertMsg,
        updateTestData,
        chaosConfigData,
        clusterObjMap,
        environment,
        getConfigDataForChaos,
        setEnvironment,
        addExperiment,
        state,
        setState
      }}>
      {children}
    </ChaosExecutionTabsContext.Provider>
  );
};

const ChaosExecutionTabsContext = createContext('ChaosTestExecutionCtx');
export default function useTestExecution() {
  return useContext(ChaosExecutionTabsContext);
}
