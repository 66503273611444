import { getDataForColumn } from '@maersk-global/mds-components-core/mc-table/utils.js';
import { format } from '@maersk-global/mds-components-utils';
import { McIcon, McTable } from '@maersk-global/mds-react-wrapper';
import React from 'react';

const InsightsTableReport = ({ data }) => {
  const columns = [
    {
      label: 'MAC ID',
      id: 'macId',
      sortDisabled: true,
      width: '100px'
    },
    {
      label: 'Portfolio',
      id: 'portfolioShortName',
      sortDisabled: true,
      width: '200px'
    },
    {
      label: 'Platform',
      id: 'platformName',
      sortDisabled: true,
      width: '200px'
    },
    {
      label: 'Application',
      id: 'applicationName',
      sortDisabled: true,
      width: '200px'
    },
    {
      label: 'Test Type',
      id: 'testType',
      sortDisabled: true
    },
    {
      label: 'Number Of Tests',
      id: 'testCount',
      sortDisabled: true,
      tabularFigures: true,
      dataType: { type: 'number', options: { useGrouping: true } }
    },
    {
      label: 'Total Test Duration (in mins)',
      id: 'testDurationMins',
      sortDisabled: true,
      tabularFigures: true,
      dataType: { type: 'number', options: { useGrouping: true } }
    },
    {
      label: 'Load Regions',
      id: 'region',
      sortDisabled: true
    },
    {
      label: 'NFR Present',
      id: 'nfrStatus',
      sortDisabled: true
    }
  ];

  return (
    <McTable columns={columns} data={data} datakey="macId" footer>
      {data.map((row) => (
        <React.Fragment key={row.macId}>
          {row.nfrStatus.map((status, index) => (
            <div key={index} slot={`${row.macId}_nfrStatus`}>
              <McIcon
                icon={
                  status === 'Passed'
                    ? 'check-circle'
                    : status === 'Failed'
                    ? 'times-circle'
                    : 'exclamation-circle'
                }
                color={
                  status === 'Passed' ? '#40AB35' : status === 'Not Defined' ? '#B80012' : '#FFD029'
                }
              />
            </div>
          ))}
        </React.Fragment>
      ))}
      <div slot="macId_footer">Total:</div>
      <div slot="testCount_footer">
        {format(sum(getDataForColumn(data, 'testCount')), {
          type: 'number',
          options: { useGrouping: true }
        })}
      </div>
      <div slot="testDurationMins_footer">
        {format(sum(getDataForColumn(data, 'testDurationMins')), {
          type: 'number',
          options: { useGrouping: true }
        })}
      </div>
    </McTable>
  );
};

export default InsightsTableReport;

const sum = (numbers) =>
  numbers.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);
