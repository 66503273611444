import axios from 'axios';

function handleError(error) {
  console.log('Error = ', error);
  return error;
}

class ChaosTestExecService {
  async getAllChaosTestTemplate(url, pageNum, pageSize) {
    return await axios
      .get(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        data: {},
        params: {
          pageSize: pageSize,
          pageNum: pageNum
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        handleError(error);
      });
  }
  async getTestData(url) {
    return await axios
      .get(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        handleError(error);
      });
  }

  async getChaosConfigData(url) {
    return await axios
      .get(url, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        handleError(error);
      });
  }

  getClusterObjectsForEnvV2(chaosConfigData) {
    let clusterMap = new Map();
    for (var i = 0; i < chaosConfigData.length; i++) {
      clusterMap.set(chaosConfigData[i].clusterName, chaosConfigData[i].region);
    }

    return clusterMap;
  }
  async postTestData(url, data) {
    return await axios
      .post(url, data, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return handleError(error);
      });
  }

  async addExperimentToTest(url, data) {
    return await axios
      .put(url, data, {
        method: 'PUT',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return handleError(error);
      });
  }
  async startTest(url, data) {
    return await axios
      .post(url, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return handleError(error);
      });
  }
  async updateTestData(url, data) {
    return await axios
      .put(url, data)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return handleError(error);
      });
  }
  async stopTest(url, data) {
    return await axios
      .post(url, data, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        return handleError(error);
      });
  }
}
export default ChaosTestExecService;
