import axios from 'axios';

class SecretService {
  async postSecret(url, data) {
    return await axios
      .post(url, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error('Error', error);
        return error;
      });
  }

  async getSecret(url) {
    return await axios
      .get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error('Error', error);
        return error;
      });
  }

  async deleteSecret(url) {
    return await axios
      .delete(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.error('Error', error);
        return error;
      });
  }
}

export default SecretService;
