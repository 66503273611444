import { React, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { McButtonGroup } from '@maersk-global/mds-react-wrapper/components-core/mc-button-group';
import { McButtonGroupItem } from '@maersk-global/mds-react-wrapper/components-core/mc-button-group-item';
import { McTable } from '@maersk-global/mds-react-wrapper/components-core/mc-table';
import { McInput } from '@maersk-global/mds-react-wrapper/components-core/mc-input';
import { McSelect } from '@maersk-global/mds-react-wrapper/components-core/mc-select';
import { McOption } from '@maersk-global/mds-react-wrapper/components-core/mc-option';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McIcon } from '@maersk-global/mds-react-wrapper/components-core/mc-icon';

import { DummyData, ColumnData } from './DummyTableData';

const ApplicationDetails = () => {
  const [optionSelected, setOptionSelected] = useState('none');
  const [isARowSelected, setIsARowSelected] = useState(false);
  const [applicationDetail, setApplicationData] = useState({});
  const location = useLocation();

  useEffect(() => {
    setApplicationData(location.state.application);
  }, []);

  const rowSelectHandler = (evt) => {
    setIsARowSelected(true);
  };

  return (
    <>
      <>
        <div
          className="layout layout--center"
          style={{
            position: 'absolute',
            top: '15%',
            left: '36%',
            width: '80%'
          }}>
          <div className="layout" style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ border: '1px solid grey', padding: '2vw 2vh', borderRadius: '10px' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}>
                <div
                  style={{
                    width: '30%'
                  }}>
                  <McInput
                    name="ApplicationId"
                    value={applicationDetail.macId}
                    label="Application ID"
                    placeholder="Application ID"
                    disabled
                    // input={yourTextChangeHandler($event)}
                  ></McInput>
                </div>
                <div style={{ width: '65%' }}>
                  <McInput
                    name="ApplicationName"
                    value={applicationDetail.applicationName}
                    label="Application"
                    placeholder="Application"
                    width="100"
                    disabled
                    // input={yourTextChangeHandler($event)}
                  ></McInput>
                </div>
              </div>
              <div style={{ marginTop: '1.5vh' }}>
                <McInput
                  name="PlatformName"
                  disabled
                  value={applicationDetail.platformName}
                  label="Platform"
                  placeholder="Platform"
                  width="100"
                  // input={yourTextChangeHandler($event)}
                ></McInput>
              </div>
              <div style={{ marginTop: '1.5vh' }}>
                <McSelect
                  label="Portfolio"
                  value={applicationDetail.portfolioShortName}
                  placeholder="Select an option"
                  optionswidth="trigger"
                  // input="yourTextChangeHandler"
                  // optionselected="yourOptionselectedChangeHandler"
                  name="portfolioName"
                  disabled>
                  <McOption value="1">One</McOption>
                  <McOption value="2">Two</McOption>
                  <McOption value="3">Three</McOption>
                  <McOption value="4">Four</McOption>
                  <McOption value="5">Five</McOption>
                </McSelect>
              </div>
              <div style={{ marginTop: '1.5vh' }}>
                <McButton
                  label="Update Application"
                  disabled
                  //   click="yourClickHandler()"
                ></McButton>
              </div>
            </div>
            <div style={{ marginTop: '4vh' }}>
              <McButtonGroup>
                <McButtonGroupItem
                  selected={optionSelected === 'onboard'}
                  disabled={!isARowSelected}
                  value="onboard"
                  label="Onboard Users"
                  icon="plus"
                  onClick={(evt) => setOptionSelected(evt.target.value)}></McButtonGroupItem>
                <McButtonGroupItem
                  selected={optionSelected === 'remove'}
                  disabled={!isARowSelected}
                  value="remove"
                  label="Remove Users"
                  icon="minus"
                  onClick={(evt) => setOptionSelected(evt.target.value)}></McButtonGroupItem>
              </McButtonGroup>

              <div style={{ marginTop: '20px' }}>
                <McTable
                  data={DummyData}
                  columns={ColumnData}
                  datakey="userId"
                  // rowclick={(evt) => rowSelectHandler(evt)}
                  select={(evt) => rowSelectHandler(evt)}>
                  {DummyData &&
                    DummyData.map((row) => (
                      <div
                        style={{ display: 'flex', justifyContent: 'flex-start' }}
                        key={`${row.userId}_types`}
                        slot={`${row.userId}_types`}>
                        <McIcon icon={row.types === 'User' ? 'user-circle' : 'wrench'}></McIcon>
                        <span style={{ marginLeft: '0.4vw', marginTop: '-0.2vh' }}>
                          {row.types}
                        </span>
                      </div>
                    ))}
                </McTable>
              </div>
              {console.log('isARowSelected', isARowSelected)}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ApplicationDetails;
