import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McInput } from '@maersk-global/mds-react-wrapper/components-core/mc-input';
import { McNotification } from '@maersk-global/mds-react-wrapper/components-core/mc-notification';
import { McToast } from '@maersk-global/mds-react-wrapper/components-core/mc-toast';
import React, { useState } from 'react';
import { getCoreServiceUrl } from '../components/Util.js';
import UserInfo from '../hooks/UserAppAuthorizationProvider.jsx';
import VaultService from '../service/VaultService.js';
import '../stylesheets/Vault.css';
import './mdsVault.css';
let config = require('../config/config.json');

const MdsVault = () => {
  const [vaultExist, setVaultExist] = useState(false);
  const { graphData, platformName, teamName } = UserInfo();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');

  const [teamMetadataId, setTeamMetadataId] = useState('');

  let vaultService = new VaultService();
  const handleSubmit = (event) => {
    event.preventDefault();
    if (vaultExist === true) {
      let data = {
        vaultConfig: {
          clientId: userForm.clientId,
          clientSecret: userForm.clientSecret,
          scope: userForm.scope,
          vaultUrl: userForm.vaultUrl,
          tenantId: userForm.tenantId
        },
        teamName: teamName,
        platform: platformName,
        createdBy: graphData.name,
        teamMetadataId: teamMetadataId,
        environment: 'prod'
      };
      vaultService
        .updateVault(getCoreServiceUrl() + '/core-srv/teammetadata/' + teamName, data)
        .then((response) => {
          if (response.status == 200) {
            setShowAlert(true);
            setAlertType('success');
            setAlertMessage('Successfully updated Azure Key Vault configuration.');
          } else {
            throw response;
          }
        })
        .catch((error) => {
          console.error('Error while updating configuration', error.response.data.message);
          if (error instanceof Error) {
            setShowAlert(true);
            setAlertType('error');
            setAlertMessage(error.response.data.message);
          }
        });
    } else {
      let data = {
        vaultConfig: {
          clientId: userForm.clientId,
          clientSecret: userForm.clientSecret,
          scope: userForm.scope,
          vaultUrl: userForm.vaultUrl,
          tenantId: userForm.tenantId
        },
        teamName: teamName,
        platform: platformName,
        createdBy: graphData.name,
        environment: 'prod'
      };
      vaultService
        .postVault(getCoreServiceUrl() + '/core-srv/teammetadata', data)
        .then((response) => {
          if (response.status == 200) {
            setShowAlert(true);
            setAlertType('success');
            setAlertMessage('Successfully configured Azure Key Vault.');
          } else {
            throw response;
          }
        })
        .catch((error) => {
          console.error('Error while saving configuration', error.response.data.message);
          if (error instanceof Error) {
            setShowAlert(true);
            setAlertType('error');
            setAlertMessage(error.response.data.message);
          }
        });
    }
  };

  React.useEffect(() => {
    vaultService
      .getVaultByTeamName(getCoreServiceUrl() + '/core-srv/teammetadata/' + teamName)
      .then((response) => {
        if (response.status == 200) {
          setTeamMetadataId(response.data.teamMetadataId);
          setVaultExist(true);
          setUserForm({
            ...userForm,
            teamName: response.data.teamName,
            platform: response.data.platform,
            teamMetadataId: response.data.teamMetadataId,
            environment: 'prod',
            createdBy: response.data.createdBy,
            clientId: response.data.vaultConfig.clientId,
            clientSecret: response.data.vaultConfig.clientSecret,
            scope: response.data.vaultConfig.scope,
            vaultUrl: response.data.vaultConfig.vaultUrl,
            tenantId: response.data.vaultConfig.tenantId
          });
        } else {
          throw response;
        }
      })
      .catch((error) => {
        console.error('Error while getting configuration', error.response.data.message);
        if (error instanceof Error) {
          setShowAlert(true);
          setAlertType('error');
          setAlertMessage(error.response.data.message);
        }
      });
  }, []);

  const [userForm, setUserForm] = React.useState({
    clientId: '',
    clientSecret: '',
    scope: '',
    vaultUrl: '',
    tenantId: '',
    teamName: '',
    platform: '',
    createdBy: graphData.name,
    teamMetadataId: '',
    environment: 'prod'
  });
  function errorDialogOnClose() {
    setShowAlert(false);
  }
  function showAlertDialog() {
    let icon;

    if (alertType === 'info') {
      icon = 'info-circle';
    } else if (alertType === 'error') {
      icon = 'exclamation-octagon';
    } else if (alertType === 'success') {
      icon = 'check-circle';
    }

    return (
      <McToast
        open
        position="top-center"
        appearance={alertType}
        close={errorDialogOnClose}
        duration="5000">
        <McNotification icon={icon} body={alertMessage}></McNotification>
      </McToast>
    );
  }
  let alertDia = showAlertDialog();

  return (
    <>
      {showAlert ? alertDia : <div></div>}
      <div>
        {showAlert ? alertDia : <div></div>}
        <h5 className="app__page-title">Configuration &gt; Azure Key Vault</h5>
        <form id="info" onSubmit={(e) => handleSubmit(e)}>
          <div id="Personal">
            <div className="field">
              <div className="app__page-input">
                <McInput
                  name="clientId"
                  label="Client Id"
                  value={userForm.clientId}
                  required={true}
                  input={(event) => {
                    if (event) {
                      const value = event.target.value;
                      setUserForm({ ...userForm, clientId: value });
                    }
                  }}></McInput>
              </div>
              <div className="app__page-input">
                <McInput
                  name="clientSecret"
                  label="Client Secret"
                  placeholder={vaultExist ? '**********' : 'Enter client Secret'}
                  type="password"
                  required={true}
                  value={userForm.clientSecret}
                  input={(event) => {
                    if (event) {
                      const value = event.target.value;
                      setUserForm({ ...userForm, clientSecret: value });
                    }
                  }}></McInput>
              </div>
              <div className="app__page-input">
                <McInput
                  name="scope"
                  label="Scope"
                  placeholder="Enter scope"
                  value={userForm.scope}
                  required={true}
                  input={(event) => {
                    if (event) {
                      const value = event.target.value;
                      setUserForm({ ...userForm, scope: value });
                    }
                  }}></McInput>
              </div>
              <div className="app__page-input">
                <McInput
                  name="vaultUrl"
                  label="Vault Url"
                  placeholder="Enter vault Url"
                  value={userForm.vaultUrl}
                  required={true}
                  input={(event) => {
                    if (event) {
                      const value = event.target.value;
                      setUserForm({ ...userForm, vaultUrl: value });
                    }
                  }}></McInput>
              </div>
              <div className="app__page-input">
                <McInput
                  name="tenantId"
                  label="Tenant Id"
                  placeholder="Enter tenant Id"
                  value={userForm.tenantId}
                  required={true}
                  input={(event) => {
                    if (event) {
                      const value = event.target.value;
                      setUserForm({ ...userForm, tenantId: value });
                    }
                  }}></McInput>
              </div>

              <McButton type="submit" className="app__page-footer">
                {vaultExist ? 'Update' : 'Save'}
              </McButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default MdsVault;
