import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McInput } from '@maersk-global/mds-react-wrapper/components-core/mc-input';
import { McNotification } from '@maersk-global/mds-react-wrapper/components-core/mc-notification';
import { McOption } from '@maersk-global/mds-react-wrapper/components-core/mc-option';
import { McSelect } from '@maersk-global/mds-react-wrapper/components-core/mc-select';
import { McToast } from '@maersk-global/mds-react-wrapper/components-core/mc-toast';
import React, { useEffect, useState } from 'react';
import { getCoreServiceUrl } from '../components/Util.js';
import Loading from '../components/pages/Loading';
import configData from '../config/config.json';
import useChaosTestExecution from '../hooks/ChaosExecutionTabs';
import UserInfo from '../hooks/UserAppAuthorizationProvider.jsx';
import ChaosTestExecService from '../service/ChaosTestExecService';
import '../stylesheets/ChaosScope.css';
export default function TestScope() {
  const { graphData, platformName, applicationName } = UserInfo();
  const {
    testData,
    updateTestData,
    chaosConfigData,
    setEnvironment,
    getConfigDataForChaos,
    environment,
    clusterObjMap
  } = useChaosTestExecution();
  let chaosTestExecService = new ChaosTestExecService();
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [clusterNames, setClusterNames] = useState('');
  const [createdBy, setCreatedBy] = useState('');
  const [testName, setTestName] = useState('');
  const [environmentLocal, setEnvironmentLocal] = useState(environment);
  const [testStatus, setTestStatus] = useState('DRAFT');
  const [clusterNameList, setClusterNameList] = useState([...clusterObjMap.keys()]);
  const [regionList, setRegionList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [envList] = useState(configData.chaosEnvironment);

  const handleEnvironmentChange = (e) => {
    setLoading(true);
    setEnvironmentLocal(e.target.value);
    setEnvironment(e.target.value);
    //resettting
    setClusterNames('');
    let promises = [];
    promises.push(getConfigDataForChaos(e.target.value));
    Promise.all(promises)
      .then(() => {
        setClusterNameList([...clusterObjMap.values()]);
        setClusterNames([...clusterObjMap.values()][0]);
      })
      .catch((error) => {
        console.error('Error:', error);
        setLoading(false);
      });
  };
  const handleClusterNameChange = (event) => {
    if (event.target.value !== '') {
      setClusterNames(event.target.value);

      const clusterRegion = clusterObjMap.get(event.target.value);
      if (clusterRegion) {
        if (!regionList.includes(clusterRegion)) {
          const newRegionList = [...regionList, clusterRegion];
          setRegionList(newRegionList);
        }
      }
    }
  };

  let disableSubmitButton = false;

  useEffect(() => {
    const noClustersForProd = localStorage.getItem('noClustersForProd');

    if (noClustersForProd) {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('No clusters available for the selected environment');
      // Remove the item from localStorage so the message doesn't keep showing up
      localStorage.removeItem('noClustersForProd');
    }

    //testId will not exists when it is first time load
    if (testData !== undefined) {
      //from context as the test exists in db

      setTestName(testData.testName);
      setEnvironmentLocal(testData.environment);
      setTestStatus(testData.status);
      setClusterNames(testData.clusterNames[0]);
      setCreatedBy(testData.createdBy);
      setClusterNameList([...clusterObjMap.keys()]);
    }

    setLoading(false);
  }, []);

  const handleTestNameChange = (event) => {
    setTestName(event.target.value);
  };

  if (testData !== undefined && testData.testId !== '') {
    disableSubmitButton = true;
  }
  const handleSubmit = async (e) => {
    disableSubmitButton = true;
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    //validate data
    let hasError = false;

    if (testName === undefined || testName.trim() === '') {
      setShowAlert(true);
      setAlertType('error');
      setAlertMessage('Test Name cannot be empty');
      hasError = true;
    }

    if (!hasError) {
      let postData = {
        testName: testName,
        platform: platformName,
        applicationName: applicationName,
        environment: environmentLocal,
        clusterNames: [clusterNames],
        status: testStatus,
        createdBy: graphData.name,
        regions: regionList
      };

      chaosTestExecService
        .postTestData(getCoreServiceUrl() + '/core-srv/chaos-test-exec', postData)
        .then((response) => {
          if (response.status == 201) {
            setShowAlert(true);
            setAlertType('success');
            setAlertMessage('Successfully saved the test scope');
            setTimeout(() => {
              updateTestData(response.data);
            }, 100);
          } else {
            throw response;
          }
        })
        .catch((error) => {
          console.log(error);
          if (error instanceof Error) {
            setShowAlert(true);
            setAlertType('error');
            setAlertMessage(error.response.data.message);
          }
        });
    }
  };
  function errorDialogOnClose() {
    setShowAlert(false);
  }
  function showAlertDialog() {
    let icon;

    if (alertType === 'info') {
      icon = 'info-circle';
    } else if (alertType === 'error') {
      icon = 'exclamation-octagon';
    } else if (alertType === 'success') {
      icon = 'check-circle';
    }

    return (
      <McToast
        open
        position="top-center"
        appearance={alertType}
        close={errorDialogOnClose}
        duration="5000">
        <McNotification icon={icon} body={alertMessage}></McNotification>
      </McToast>
    );
  }
  let alertDia = showAlertDialog();

  return (
    <>
      {showAlert ? alertDia : ''}
      {loading ? (
        <Loading />
      ) : (
        <div style={{ width: '60%' }}>
          <form onSubmit={handleSubmit}>
            <div>
              <div className="field">
                <McInput
                  name="testName"
                  label="Test Name"
                  value={testName}
                  disabled={disableSubmitButton}
                  required
                  onInput={handleTestNameChange}></McInput>
                <div className="field"></div>{' '}
                <McInput
                  name="testStatus"
                  label="Test Status"
                  value={testStatus}
                  disabled={true}
                  required></McInput>
                <div className="field"></div>{' '}
                <div>
                  <McSelect
                    name="environment"
                    label="Environment"
                    value={environmentLocal}
                    required
                    disabled={disableSubmitButton}
                    optionselected={handleEnvironmentChange}>
                    {envList !== undefined &&
                      envList.length > 0 &&
                      envList.map((env) => (
                        <McOption key={env.value} value={env.value}>
                          {env.name}
                        </McOption>
                      ))}
                  </McSelect>
                </div>
                <div className="field"></div>{' '}
                <div>
                  <McSelect
                    name="clusterNames"
                    label="Cluster"
                    value={clusterNames}
                    required
                    disabled={
                      disableSubmitButton || !clusterNameList || clusterNameList.length === 0
                    }
                    optionselected={handleClusterNameChange}>
                    {clusterNameList &&
                      clusterNameList.map((act, index) => (
                        <McOption key={index} value={act}>
                          {act}
                        </McOption>
                      ))}
                  </McSelect>
                </div>
                <div className="field"></div>{' '}
                <McInput
                  name="createdBy"
                  label="Created By"
                  value={testData ? createdBy : graphData.name}
                  disabled={true}
                  required></McInput>
                <div className="field"></div>{' '}
                <McButton type="submit" variant="filled" disabled={disableSubmitButton}>
                  Save
                </McButton>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}
