import axios from 'axios';
import { getOrchestratorUrl } from '../components/Util.js';

class TestConfigService {
  getConfigJson(url, data) {
    return axios
      .post(url, data, {
        validateStatus: function (status) {
          return status === 200;
        }
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }

  async getConfigFromGitHub(testTemplate) {
    let config = {};

    let optionsRequest = {
      repoName: testTemplate.repoName,
      filePath: getConfigFilePath(testTemplate, 'Options'),
      branchName: testTemplate.branchName
    };
    try {
      let optionsResponse = await this.getConfigJson(
        getOrchestratorUrl() + '/git/test-options',
        optionsRequest
      );
      config.optionsJson = JSON.stringify(optionsResponse.data, null, 2);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        config.optionsJsonNotFound = true;
      } else {
        throw error;
      }
    }

    let varsRequest = {
      repoName: testTemplate.repoName,
      filePath: getConfigFilePath(testTemplate, 'Variables'),
      branchName: testTemplate.branchName
    };
    try {
      let varsResponse = await this.getConfigJson(
        getOrchestratorUrl() + '/git/test-vars',
        varsRequest
      );
      config.varsJson = JSON.stringify(varsResponse.data, null, 2);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        config.varsJsonNotFound = true;
      } else {
        throw error;
      }
    }
    return config;
  }
}

export default TestConfigService;

const getConfigFilePath = (testTemplate, type) => {
  let startIndex = testTemplate.testScript.lastIndexOf('/') + 1;
  const folder = testTemplate.testScript.substring(0, startIndex - 1);

  let endIndex = testTemplate.testScript.lastIndexOf('.js');
  let testScriptName = testTemplate.testScript.substring(startIndex, endIndex);

  let environment = testTemplate.environment;

  if (type === 'Options') {
    return `${folder}/config/${testScriptName}_options_${environment}.json`;
  } else {
    return `${folder}/config/${testScriptName}_vars_${environment}.json`;
  }
};
