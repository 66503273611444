import { McOption, McSelect } from '@maersk-global/mds-react-wrapper';
import React from 'react';
import allEnvLoadRegions from '../../EngineRegions.json';

/**
 * Dropdown component to select a load region.
 *
 * @param {Object} props - Component props.
 * @param {Array} props.loadRegions - Array of load regions to be displayed as options.
 * @param {string} props.selectedLoadRegion - The currently selected load region.
 * @param {Function} props.onSelect - Callback function to be called when a new load region is selected.
 * @returns {React.Component} The LoadRegionSelect component.
 */
const LoadRegionSelect = ({ filterLoadRegions, selectedLoadRegion, onSelect }) => {
  return (
    <McSelect
      name="loadRegionSelect"
      label="Load Region"
      value={selectedLoadRegion}
      optionselected={(event) => onSelect(event.detail.value)}>
      {getLoadRegionOptions(filterLoadRegions)}
    </McSelect>
  );
};

export default LoadRegionSelect;

const allLoadRegions = allEnvLoadRegions[process.env.REACT_APP_ENV];
// const loadRegionOptions = allLoadRegions.flatMap(({ region, regionName, zoneListName, zoneList }) =>
//   zoneList.map((zone, index) => ({
//     id: `${zoneListName[index]}${regionName}`,
//     region,
//     zone
//   }))
// );

const getLoadRegionOptions = (filterLoadRegions) => {
  let options = [];
  allLoadRegions.forEach((region, index) => {
    options.push(<small key={region.region}>{region.region}</small>);
    let zoneAdded = false;
    region.zoneList.forEach((zone, zoneIndex) => {
      let id = `${region.zoneListName[zoneIndex]}${region.regionName}`;
      let filter = filterLoadRegions ? filterLoadRegions.some((region) => region === id) : true;
      if (filter) {
        zoneAdded = true;
        options.push(<McOption key={id} value={id}>{`${region.region} ${zone}`}</McOption>);
      }
    });
    if (!zoneAdded) {
      options.pop();
    }
  });
  return options;
};

// const getLoadRegionOptions = (loadRegions) => {
//   if (!loadRegions || loadRegions.length === 0) {
//     return loadRegionOptions;
//   }
//   let filteredLoadRegionOptions = loadRegionOptions.filter((option) =>
//     loadRegions.some((region) => region === option.id)
//   );
//   return filteredLoadRegionOptions;
// };
