import { McCCodePreview } from '@maersk-global/community-ui-react-wrapper';
import React, { useEffect, useState } from 'react';
import { ShowErrorNotification } from '../../common/notification';
import Loading from '../pages/Loading';
import TestIntegrationService from './TestIntegrationService';

const WorkflowPreview = ({ id, refreshPreview, setRefreshPreview }) => {
  const [workflowPreview, setWorkflowPreview] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    const getWorkflowPreview = async () => {
      try {
        setLoading(true);
        let response = await new TestIntegrationService().getWorkflowPreview(id);
        setWorkflowPreview(response.data);
      } catch (err) {
        console.error('Error while retrieving workflow preview: ', err);
        setError('Something unexpected happened. Please try again.');
      } finally {
        setLoading(false);
        setRefreshPreview(false);
      }
    };
    if (refreshPreview) {
      getWorkflowPreview();
    }
  }, [refreshPreview]);

  return (
    <div>
      {loading && <Loading />}
      {error && <ShowErrorNotification message={error} />}
      <McCCodePreview
        fit="small"
        variant="none"
        appearance="inverse"
        hidetoggle={true}
        code={[
          {
            language: 'yaml',
            template: workflowPreview
          }
        ]}>
        <span slot="heading">
          Copy and use this in your GitHub Actions workflow file. For details please refer{' '}
          <a
            href="https://ipace-docs.maersk-digital.net/perf-testing/cicd-integration.html"
            target="_blank"
            rel="noreferrer">
            help docs
          </a>{' '}
          .
        </span>
      </McCCodePreview>
    </div>
  );
};

export default WorkflowPreview;
