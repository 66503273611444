import { McButton, McModal } from '@maersk-global/mds-react-wrapper';

/**
 * This component renders a modal dialog that asks the user to confirm a deletion action.
 * It displays a custom confirmation message and provides two buttons: "Delete" and "Don't Delete".
 *
 * @param {string} [actionName='Delete'] The name of the action to confirm, defaults to 'Delete'.
 * @param {string} confirmationMessage The message to be displayed to the user for confirmation.
 * @param {Function} onAction The callback function to be called when the user clicks on either the "Delete" or "Don't Delete" button.
 *        This function receives two arguments: the click event and a string indicating the action ('ok' for delete, 'cancel' for don't delete).
 * @returns {JSX.Element} A modal dialog component for confirming deletion actions.
 */
const ConfirmDeletionModal = ({ actionName = 'Delete', confirmationMessage, onAction }) => {
  return (
    <McModal heading={`Confirm ${actionName}`} open={true} dimension="small">
      <p>{confirmationMessage}</p>
      <McButton
        focusstartanchor
        slot="primaryAction"
        appearance="error"
        click={(event) => {
          onAction(event, 'ok');
        }}>
        {actionName}
      </McButton>
      <McButton
        focusendanchor
        slot="secondaryAction"
        appearance="neutral"
        variant="outlined"
        dialogaction="cancel"
        click={(event) => {
          onAction(event, 'cancel');
        }}>
        {`Don't ${actionName}`}
      </McButton>
    </McModal>
  );
};

export default ConfirmDeletionModal;
