import { McButton, McHint, McIcon, McTab, McTabBar } from '@maersk-global/mds-react-wrapper';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ShowErrorNotification, ShowSuccessNotification } from '../../common/notification';
import Loading from '../pages/Loading';
import LoadRegion, { LoadRegionData } from '../testconfig/LoadRegion';
import TestConfig, { TestConfigData, TestConfigDataValidator } from '../testconfig/TestConfig';
import ConfirmDeletionModal from '../util/ConfirmDeletionModal';
import TestIntegrationInfo, { TestIntegrationInfoData } from './TestIntegrationInfo';
import TestIntegrationService from './TestIntegrationService';
import WorkflowPreview from './WorklfowPreview';

const IntegrationTest = () => {
  const integrationService = new TestIntegrationService();

  const navigate = useNavigate();
  const testExecutionData = useLocation().state?.testExecutionData;
  const showPreview = useLocation().state?.showPreview;
  let { id } = useParams();
  const isEdit = id ? true : false;

  const defaultApiStatus = {
    status: Status.Pending,
    message: ''
  };
  const [apiStatus, setApiStatus] = useState(defaultApiStatus);

  const defaultTestIntegrationData = {
    testIntegrationInfoData: TestIntegrationInfoData,
    testConfigData: TestConfigData,
    loadRegionData: LoadRegionData
  };
  const [testIntegrationData, setTestIntegrationData] = useState(defaultTestIntegrationData);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [refreshPreview, setRefreshPreview] = useState(true);

  const setTestIntegrationInfoData = (testIntegrationInfoData) => {
    setTestIntegrationData({
      ...testIntegrationData,
      testIntegrationInfoData: testIntegrationInfoData
    });
  };
  const setTestConfigData = (testConfigData) => {
    setTestIntegrationData({
      ...testIntegrationData,
      testConfigData: {
        ...testIntegrationData.testConfigData,
        optionsJson: testConfigData.optionsJson,
        varsJson: testConfigData.varsJson
      }
    });
  };
  const setLoadRegionData = (loadRegionData) => {
    setTestIntegrationData({ ...testIntegrationData, loadRegionData: loadRegionData });
  };
  useEffect(() => {
    if (isEdit) {
      // edit flow
      integrationService
        .getTestIntegrationById(id)
        .then((response) => {
          let responseData = response.data;
          let testIntegrationData =
            IntegrationTestUtil.getTestIntegrationDataFromResponse(responseData);
          setTestIntegrationData(testIntegrationData);

          if (showPreview) {
            setActiveTab(3);
          }

          setApiStatus({
            status: Status.Success,
            message: ''
          });
        })
        .catch((error) => {
          console.error('Error while retrieving integration test: ', error);
          setApiStatus({
            status: Status.Error,
            message: 'Something unexpected happened. Please try again by refreshing the page.'
          });
        });
    } else if (testExecutionData) {
      // create flow from existing test execution
      let testIntegrationData =
        IntegrationTestUtil.getTestIntegrationDataFromTestExecution(testExecutionData);
      setTestIntegrationData(testIntegrationData);

      setApiStatus({
        status: Status.Success,
        message: ''
      });
    } else {
      // create flow from scratch
      setApiStatus({
        status: Status.NotSupported,
        message: ''
      });
    }
  }, [id]);

  const handleCreateOrUpdate = () => {
    setApiStatus({ status: Status.InProgress, message: '' });
    let requestData = {};
    try {
      requestData = IntegrationTestUtil.validateAndGetRequestData(testIntegrationData);
    } catch (error) {
      setApiStatus({ status: Status.Error, message: error.message });
      return;
    }
    integrationService
      .createOrUpdateTestIntegration(requestData, isEdit)
      .then((response) => {
        const responseData = response.data;

        if (!isEdit) {
          setTimeout(() => {
            setApiStatus({ status: Status.Pending, message: '' });
            navigate(`/perf-testing/integrations/${responseData.id}`, {
              state: { showPreview: true }
            });
          }, 1000);
        } else {
          setTestIntegrationData(
            IntegrationTestUtil.getTestIntegrationDataFromResponse(responseData)
          );
          setRefreshPreview(true);
          setActiveTab(3);
        }

        let message = isEdit
          ? 'Test integration updated successfully.'
          : 'Test integration created successfully.';
        setApiStatus({ status: Status.Success, message: message });
      })
      .catch((error) => {
        console.error('Error while creating or updating integration test: ', error);
        let errorMessage = 'Something unexpected happened. Please try again.';
        if (error.response && error.response.status === 400) {
          errorMessage = `${error.response.data.message}. Please provide valid information.`;
        }
        setApiStatus({
          status: Status.Error,
          message: errorMessage
        });
      });
  };

  const handleDelete = (event, action) => {
    setConfirmDelete(false);
    if (action === 'cancel') {
      return;
    }
    setApiStatus({ status: Status.InProgress, message: '' });

    let id = testIntegrationData.testIntegrationInfoData.id;
    integrationService
      .deleteTestIntegration(id)
      .then((response) => {
        setApiStatus({
          status: Status.Success,
          message: `Test integration ${id} deleted successfully. Please delete the corresponding workflow from GitHub Actions.`
        });
        setTimeout(() => {
          navigate('/perf-testing/integrations');
        }, 2000);
      })
      .catch((error) => {
        console.error('Error while deleting integration test: ', error);
        setApiStatus({
          status: Status.Error,
          message: 'Something unexpected happened. Please try again.'
        });
      });
  };

  const getInitContent = () => {
    if (apiStatus.status === Status.Pending) {
      return <Loading />;
    } else if (apiStatus.status === Status.NotSupported) {
      return <CreateNewTestIntegrationHint />;
    }
  };

  return (
    <div style={{ width: '70%' }}>
      <h5 className="app__page-title">
        Performance Engineering &gt; CICD Integrations &gt;{' '}
        {isEdit ? 'Edit Integration' : 'New Integration'}
      </h5>
      {apiStatus.status === Status.Pending || apiStatus.status === Status.NotSupported ? (
        getInitContent()
      ) : (
        <>
          {apiStatus.status === Status.Success && apiStatus.message ? (
            <ShowSuccessNotification message={apiStatus.message} />
          ) : null}
          {apiStatus.status === Status.Error ? (
            <ShowErrorNotification message={apiStatus.message} />
          ) : null}
          {confirmDelete ? (
            <ConfirmDeletionModal
              confirmationMessage="Are you sure you want to delete this integration?"
              onAction={handleDelete}
            />
          ) : null}
          <McTabBar currentindex={activeTab} tabchange={(event) => setActiveTab(event.detail)}>
            <McTab slot="tab" label="Integration Info" />
            <div slot="panel">
              <TestIntegrationInfo
                testIntegrationInfoData={testIntegrationData.testIntegrationInfoData}
                setTestIntegrationInfoData={setTestIntegrationInfoData}
                isEdit={isEdit}
              />
            </div>
            <McTab slot="tab" label="Test Config" />
            <div slot="panel">
              <TestConfig
                testConfigData={testIntegrationData.testConfigData}
                setTestConfigData={setTestConfigData}
              />
            </div>
            <McTab slot="tab" label="Load Region" />
            <div slot="panel">
              <LoadRegion
                loadRegionData={testIntegrationData.loadRegionData}
                setLoadRegionData={setLoadRegionData}
              />
            </div>
            {isEdit ? (
              <>
                <McTab slot="tab" label="Workflow Preview" />
                <div slot="panel">
                  <WorkflowPreview
                    id={testIntegrationData.testIntegrationInfoData.id}
                    refreshPreview={refreshPreview}
                    setRefreshPreview={setRefreshPreview}
                  />
                </div>
              </>
            ) : (
              ''
            )}
          </McTabBar>
          <div className="app__page-footer">
            <McButton
              loading={apiStatus.status === Status.InProgress}
              click={(event) => handleCreateOrUpdate(event)}>
              {isEdit ? 'Update' : 'Create'}
            </McButton>
            {isEdit ? (
              <McButton
                appearance="error"
                loading={apiStatus.status === Status.InProgress}
                click={(event) => setConfirmDelete(true)}>
                Delete
              </McButton>
            ) : (
              ''
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default IntegrationTest;

export const CreateNewTestIntegrationHint = () => {
  return (
    <McHint>
      <McIcon icon="info-circle" />
      <span>
        To create new CICD integration please go to Test Executions &gt; View Test &gt; Integrate
        with GitHub Actions. Only completed test executions can be integrated.
      </span>
    </McHint>
  );
};

class IntegrationTestUtil {
  static getTestIntegrationDataFromTestExecution(testExecution) {
    return {
      testIntegrationInfoData: {
        integrationName: testExecution.testName,
        clientId: '',
        testTemplateId: testExecution.testTemplate.templateId,
        testTemplateName: testExecution.testTemplate.templateName,
        testType: testExecution.testType
      },
      testConfigData: {
        optionsJson: testExecution.optionsJson,
        varsJson: testExecution.varsJson
      },
      loadRegionData: testExecution.selLoadGeneratorList
    };
  }

  static validateAndGetRequestData(testIntegrationData) {
    let validationError = TestConfigDataValidator(testIntegrationData.testConfigData);
    if (validationError) {
      throw new Error(validationError);
    }
    if (!testIntegrationData.testIntegrationInfoData.clientId) {
      throw new Error('Client ID must not be blank. Please provide valid information.');
    }
    return {
      id: testIntegrationData.testIntegrationInfoData.id,
      integrationName: testIntegrationData.testIntegrationInfoData.integrationName,
      integrationClientProfile: {
        clientId: testIntegrationData.testIntegrationInfoData.clientId
      },
      testTemplateId: testIntegrationData.testIntegrationInfoData.testTemplateId,
      integrationType: testIntegrationData.testIntegrationInfoData.integrationType,
      testType: testIntegrationData.testIntegrationInfoData.testType,
      optionsJson: testIntegrationData.testConfigData.optionsJson,
      varsJson: testIntegrationData.testConfigData.varsJson,
      selLoadGeneratorList: testIntegrationData.loadRegionData
    };
  }

  static getTestIntegrationDataFromResponse(responseData) {
    return {
      testIntegrationInfoData: {
        id: responseData.id,
        integrationName: responseData.integrationName,
        testTemplateId: responseData.testTemplateId,
        testTemplateName: responseData.testTemplateName,
        testType: responseData.testType,
        updatedBy: responseData.updatedBy,
        updatedDateTime: responseData.updatedDateTime,
        clientId: responseData.integrationClientProfile.clientId
      },
      testConfigData: {
        optionsJson: responseData.optionsJson,
        varsJson: responseData.varsJson
      },
      loadRegionData: responseData.selLoadGeneratorList
    };
  }
}

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error',
  NotSupported: 'NotSupported'
};
