import { McIcon } from '@maersk-global/mds-react-wrapper';
import cronstrue from 'cronstrue';
import React, { useEffect, useState } from 'react';
import { ShowErrorNotification } from '../../common/notification';
import Loading from '../pages/Loading';
import { formatDateTime } from '../Util';
import ComponentLink from '../util/ComponentLink';
import IcTable from '../util/IcTable';
import { CreateNewScheduleHint } from './ScheduleTest';
import ScheduleTestService from './ScheduleTestService';

const ListScheduleTests = () => {
  const scheduleTestService = new ScheduleTestService();

  const defaultScheduleListData = {
    data: [],
    totalCount: -1,
    totalPages: 0,
    currentPage: 0,
    pageSize: 50
  };
  const [scheduleListData, setScheduleListData] = useState(defaultScheduleListData);

  const defaultApiStatus = {
    status: Status.Pending,
    message: ''
  };
  const [apiStatus, setApiStatus] = useState(defaultApiStatus);

  const getScheduledTests = () => {
    setApiStatus({ status: Status.InProgress, message: '' });

    scheduleTestService
      .getScheduledTests(scheduleListData.currentPage, scheduleListData.pageSize)
      .then((response) => {
        let responseData = response.data;
        setScheduleListData({
          ...scheduleListData,
          data: responseData.data,
          totalCount: responseData.totalCount,
          totalPages: responseData.totalPages
        });
        setApiStatus({ status: Status.Success, message: '' });
      })
      .catch((error) => {
        console.error('Error while retrieving scheduled tests: ', error);
        setApiStatus({
          status: Status.Error,
          message: 'Something unexpected happened. Please try again by refreshing the page.'
        });
      });
  };

  useEffect(() => {
    getScheduledTests();
  }, [scheduleListData.currentPage]);

  return (
    <div style={{ width: 'fit-content' }}>
      <h5 className="app__page-title">Performance Engineering &gt; Test Schedules </h5>
      {apiStatus.status === Status.InProgress ? <Loading /> : ''}
      {apiStatus.status === Status.Error ? (
        <ShowErrorNotification message={apiStatus.message} />
      ) : null}
      <div style={{ marginBottom: '16px' }}>
        <CreateNewScheduleHint />
      </div>
      <IcTable
        name="Schedule"
        data={scheduleListData}
        columns={columns}
        datakey="id"
        footer
        disableplaceholderfooters
        onPageChange={(nextPage) => {
          setScheduleListData({ ...scheduleListData, currentPage: nextPage });
        }}>
        {scheduleListData.data.map((row) => (
          <React.Fragment key={row.id}>
            <div slot={`${row.id}_id`}>
              <ComponentLink route={`/perf-testing/schedules/${row.id}`}>{row.id}</ComponentLink>
            </div>
            <div slot={`${row.id}_cronExpression`}>
              {cronstrue.toString(row.cronExpression, { use24HourTimeFormat: true })}
            </div>
            <div slot={`${row.id}_active`}>
              <McIcon
                icon={row.active ? 'check-circle' : 'times-circle'}
                color={row.active ? 'green' : 'red'}></McIcon>
            </div>
            <div slot={`${row.id}_testTemplateId`}>
              <ComponentLink route={`/perf-testing/templates/${row.testTemplateId}`}>
                {row.testTemplateId}
              </ComponentLink>
            </div>
            <div slot={`${row.id}_updatedDateTime`}>{formatDateTime(row.updatedDateTime)}</div>
          </React.Fragment>
        ))}
      </IcTable>
    </div>
  );
};

export default ListScheduleTests;

const columns = [
  {
    id: 'id',
    label: 'Schedule ID',
    sortDisabled: true
  },
  {
    id: 'scheduleName',
    label: 'Schedule Name',
    sortDisabled: true
  },
  {
    id: 'cronExpression',
    label: 'Schedule Interval',
    sortDisabled: true
  },
  {
    id: 'active',
    label: 'Active',
    sortDisabled: true
  },
  {
    id: 'testTemplateId',
    label: 'Test Template ID',
    sortDisabled: true
  },
  {
    id: 'updatedDateTime',
    label: 'Updated Date Time',
    sortDisabled: true
  }
];

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error'
};
