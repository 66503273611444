import axios from 'axios';
import { getCoreServiceUrl } from '../components/Util';

function handleError(error) {
  console.log('Error = ' + error);
  return error;
}

class InsightsService {
  url = `${getCoreServiceUrl()}/core-srv`;

  getTestResults(data) {
    let url = `${this.url}/test-insight`;

    return axios
      .post(url, data, {
        validateStatus: function (status) {
          return status === 200;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }
  getOnboardedApps(data) {
    let url = `${this.url}/app-onboarding-insight`;

    return axios
      .post(url, data, {
        validateStatus: function (status) {
          return status === 200;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }
}

export default InsightsService;
