import { McList, McListItem, McMenu, McButton, McTooltip } from '@maersk-global/mds-react-wrapper';
import * as React from 'react';
import { McIcon } from '@maersk-global/mds-react-wrapper/mc-icon';
import { useNavigate } from 'react-router-dom';
import Maersk_Logo from '../src/Assets/Maersk_logo.svg';
import './Header.css';
import UserInfo from './hooks/UserAppAuthorizationProvider';

const HeaderNew = () => {
  const navigate = useNavigate();

  const { userProfile, onboardingConfig } = UserInfo();

  const handleOnLogo = (e) => {
    e.preventDefault();
    navigate('/');
  };

  function stringAvatar(name) {
    return name !== undefined ? name.split(' ')[0][0] + name.split(' ')[1][0] : '';
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        height: '65px',
        backgroundColor: '#E9F6FC',
        justifyContent: 'space-between'
      }}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center'
        }}>
        <img
          style={{ width: '150px', cursor: 'pointer' }}
          alt="iPACE - Insights for Performance and Chaos Engineering"
          src={Maersk_Logo}
          onClick={handleOnLogo}
        />
        <span
          style={{ fontSize: '18px', lineHeight: '28px', fontWeight: '700', fontStyle: 'normal' }}>
          iPACE
        </span>
      </div>
      <div
        style={{
          display: 'flex'
        }}>
        <McTooltip>
          <McButton
            slot="trigger"
            variant="plain"
            target="_blank"
            href={process.env.REACT_APP_TEAMS_URL}>
            <McIcon icon="comments" size="24" />
          </McButton>
          <span>Support</span>
        </McTooltip>
        <McTooltip>
          <McButton
            slot="trigger"
            variant="plain"
            target="_blank"
            href={process.env.REACT_APP_HELP_DOC_URL}>
            <McIcon icon="question-circle" size="24" />
          </McButton>
          <span>Help Documentation</span>
        </McTooltip>
        {onboardingConfig ? (
          <>
            <McTooltip>
              <span slot="trigger">
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      textAlign: 'right',
                      marginRight: '8px'
                    }}>
                    <div
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '16px',
                        lineHeight: '24px',
                        height: '50%',
                        width: '120px'
                      }}>
                      {onboardingConfig.applicationName}
                    </div>
                    <div
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        fontSize: '12px',
                        lineHeight: '16px',
                        height: '50%',
                        width: '120px'
                      }}>
                      {onboardingConfig.platformName}
                    </div>
                  </div>
                  <McMenu position="bottom-center" style={{ marginRight: '24px' }}>
                    <span slot="trigger">
                      <mc-avatar
                        style={{ cursor: 'pointer' }}
                        slot="trigger"
                        initials={stringAvatar(userProfile.userName)}
                        hiddentooltip></mc-avatar>
                    </span>
                    <McList>
                      <McListItem
                        label="My Applications"
                        onClick={() => navigate('/applications')}></McListItem>
                    </McList>
                  </McMenu>
                </div>
              </span>
            </McTooltip>
          </>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};
export default HeaderNew;
