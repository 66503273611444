import axios from 'axios';
import { getCoreServiceUrl } from '../Util';

class ScheduleTestService {
  url = `${getCoreServiceUrl()}/core-srv`;

  createOrUpdateScheduledTest(data, isUpdate) {
    if (isUpdate) {
      return this.updateScheduledTest(data);
    } else {
      return this.createScheduledTest(data);
    }
  }

  createScheduledTest(data) {
    let url = `${this.url}/schedule/new`;

    return axios
      .post(url, data, {
        validateStatus: function (status) {
          return status === 201;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }

  getScheduledTests(currentPage, pageSize) {
    let url = `${this.url}/schedule/list?pageNum=${currentPage}&pageSize=${pageSize}`;
    return axios
      .get(url, {
        validateStatus: function (status) {
          return status === 200;
        },
        headers: {
          Accept: 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }

  getScheduledTestById(id) {
    let url = `${this.url}/schedule/${id}`;

    return axios
      .get(url, {
        validateStatus: function (status) {
          return status === 200;
        },
        headers: {
          Accept: 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }

  updateScheduledTest(data) {
    let url = `${this.url}/schedule/${data.id}`;

    return axios
      .put(url, data, {
        validateStatus: function (status) {
          return status === 200;
        },
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }

  deleteScheduledTest(id) {
    let url = `${this.url}/schedule/${id}`;

    return axios
      .delete(url, {
        validateStatus: function (status) {
          return status === 204;
        },
        headers: {
          Accept: 'application/json'
        }
      })
      .then((response) => {
        return response;
      });
  }
}

export default ScheduleTestService;
