import { McInput, McOption, McSelect } from '@maersk-global/mds-react-wrapper';
import React, { useEffect, useState } from 'react';
import configData from '../../config/config.json';
import Loading from '../pages/Loading';
import { formatDateTime } from '../Util';
import TestIntegrationService from './TestIntegrationService';

const TestIntegrationInfo = ({ testIntegrationInfoData, setTestIntegrationInfoData, isEdit }) => {
  const integrationService = new TestIntegrationService();

  const defaultApiStatus = {
    status: Status.Pending,
    message: ''
  };
  const [apiStatus, setApiStatus] = useState(defaultApiStatus);

  const defaultIntegrationData = {
    isNewClient: 'true',
    newClient: '',
    existingClients: [],
    selectedClient: ''
  };
  const [integrationInfoData, setIntegrationInfoData] = useState(defaultIntegrationData);

  const setNewClient = (newClient) => {
    setIntegrationInfoData({ ...integrationInfoData, newClient: newClient });
    setTestIntegrationInfoData({ ...testIntegrationInfoData, clientId: newClient });
  };

  const setSelectedClient = (selectedClient) => {
    setIntegrationInfoData({ ...integrationInfoData, selectedClient: selectedClient });
    setTestIntegrationInfoData({ ...testIntegrationInfoData, clientId: selectedClient });
  };

  useEffect(() => {
    integrationService
      .getIntegrationClients()
      .then((response) => {
        console.log('Integration clients: ', response.data);

        let existingClients = response.data;
        if (isEdit) {
          let isNewClient = 'false';
          let selectedClient = testIntegrationInfoData.clientId;
          setIntegrationInfoData({
            ...defaultIntegrationData,
            isNewClient,
            existingClients,
            selectedClient
          });
        } else {
          let isNewClient = response.data.length > 0 ? 'false' : 'true';
          if (isNewClient === 'false') {
            let selectedClient = existingClients[0].clientId;
            setTestIntegrationInfoData({
              ...testIntegrationInfoData,
              clientId: selectedClient
            });
            setIntegrationInfoData({
              ...defaultIntegrationData,
              isNewClient,
              existingClients,
              selectedClient
            });
          }
        }

        setApiStatus({
          status: Status.Success,
          message: ''
        });
      })
      .catch((error) => {
        console.error('Error while retrieving integration clients: ', error);
        setApiStatus({
          status: Status.Error,
          message: 'Something unexpected happened. Please try again by refreshing the page.'
        });
      });
  }, []);

  return (
    <div className="app__page-form">
      {apiStatus.status === Status.Pending ? <Loading /> : ''}
      {isEdit ? (
        <McInput
          name="TestIntegrationId"
          label="Integration ID"
          disabled={true}
          value={testIntegrationInfoData.id}
        />
      ) : (
        ''
      )}
      <McInput
        name="IntegrationName"
        label="Integration Name"
        value={testIntegrationInfoData.integrationName}
        input={(event) =>
          setTestIntegrationInfoData({
            ...testIntegrationInfoData,
            integrationName: event.target.value
          })
        }
      />
      <div>
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div style={{ flex: 0.2, marginRight: '8px' }}>
            <McSelect
              name="isNewClient"
              label="Client ID"
              value={integrationInfoData.isNewClient}
              optionselected={(event) => {
                let isNewClient = event.detail.value;
                if (isNewClient === 'true') {
                  setNewClient(integrationInfoData.newClient);
                } else {
                  setSelectedClient(integrationInfoData.selectedClient);
                }
                setIntegrationInfoData({ ...integrationInfoData, isNewClient: isNewClient });
              }}>
              <McOption value="false">Existing</McOption>
              <McOption value="true">New</McOption>
            </McSelect>
          </div>
          <div style={{ flex: 0.8 }}>
            {integrationInfoData.isNewClient === 'true' ? (
              <McInput
                name="newClientId"
                placeholder="Client ID of service principal to authenticate during CICD workflow run"
                hiddenlabel
                value={integrationInfoData.newClient}
                input={(event) => {
                  setNewClient(event.target.value);
                }}
              />
            ) : (
              <McSelect
                name="existingClients"
                hiddenlabel
                value={integrationInfoData.selectedClient}
                optionselected={(event) => {
                  setSelectedClient(event.detail.value);
                }}>
                {integrationInfoData.existingClients.map((client, index) => (
                  <McOption key={index} value={client.clientId}>
                    {client.clientId}
                  </McOption>
                ))}
              </McSelect>
            )}
          </div>
        </div>
      </div>

      <McInput
        name="templateName"
        label="Test Template Name"
        disabled={true}
        value={testIntegrationInfoData.testTemplateName}
      />
      <McSelect
        name="testType"
        label="Test Type"
        value={testIntegrationInfoData.testType}
        optionselected={(event) => {
          setTestIntegrationInfoData({ ...testIntegrationInfoData, testType: event.detail.value });
        }}>
        {configData.testType.map((testType, index) => (
          <McOption key={index} value={testType.toUpperCase()}>
            {testType}
          </McOption>
        ))}
      </McSelect>
      {isEdit ? (
        <>
          <McInput
            name="updatedBy"
            label="Updated By"
            value={testIntegrationInfoData.updatedBy}
            disabled={true}
          />
          <McInput
            name="updatedDateTime"
            label="Updated Date Time"
            value={formatDateTime(testIntegrationInfoData.updatedDateTime)}
            disabled={true}
          />
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default TestIntegrationInfo;

export const TestIntegrationInfoData = {
  id: '',
  integrationName: '',
  clientId: '',
  testTemplateId: '',
  testTemplateName: '',
  testType: 'LOAD TEST',
  updatedBy: '',
  updatedDateTime: ''
};

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error',
  NotSupported: 'NotSupported'
};
