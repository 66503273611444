import { McTable } from '@maersk-global/mds-react-wrapper/components-core/mc-table';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { ShowErrorNotification, ShowSuccessNotification } from '../common/notification.jsx';
import Loading from '../components/pages/Loading.jsx';
import { McDateRange } from '@maersk-global/mds-react-wrapper/components-core/mc-date-range';
import { McButton } from '@maersk-global/mds-react-wrapper';
import InsightsService from './InsightsService.js';

const OnboardedApplications = () => {
  const getPortfolios = require('../components/portfolioUtils.jsx');
  const { portfolioMap: portfolios } = getPortfolios();

  const getStartOfWeek = () => {
    let date = new Date();
    let dayOfWeek = date.getDay();
    // Monday to Sunday week
    let difference = dayOfWeek === 0 ? 6 : dayOfWeek - 1;
    date.setDate(date.getDate() - difference);
    return date.toISOString().slice(0, 10);
  };

  const getToday = () => {
    let date = new Date();
    return date.toISOString().slice(0, 10);
  };
  const defaultInsightsData = {
    from: getStartOfWeek(),
    to: getToday(),
    fromToChanged: true,
    defaultData: [],
    data: [],
    status: Status.InProgress,
    error: ''
  };

  const [insightsData, setInsightsData] = useState(defaultInsightsData);
  const setDateFilter = (event) => {
    if (
      insightsData.from !== event.target.value.from ||
      insightsData.to !== event.target.value.to
    ) {
      setInsightsData((prevState) => ({
        ...prevState,
        from: event.target.value.from,
        to: event.target.value.to,
        fromToChanged: true
      }));
      console.log('insightsData :', insightsData);
    }
  };

  const isDateFilterDisabled = () => {
    return (
      insightsData.status === Status.InProgress ||
      !(insightsData.fromToChanged && insightsData.from && insightsData.to)
    );
  };

  let insightsService = new InsightsService();

  const defaultOnboardedAppsData = {
    applications: [],
    status: Status.InProgress,
    success: '',
    error: ''
  };
  const [onboardedAppsData, setOnboardedAppsData] = useState(defaultOnboardedAppsData);
  const getApplicationsOnboarded = () => {
    setInsightsData((prevState) => ({
      ...prevState,
      status: Status.InProgress
    }));
    let data = {
      startTime: insightsData.from,
      endTime: insightsData.to,
      granularity: 'ALL',
      responseFields: ['macId', 'createDateTime'],
      groupBy: ['portfolioShortName', 'platformName', 'applicationName'],
      sort: [
        {
          type: 'portfolioShortName',
          order: 'ASC'
        },
        {
          type: 'platformName',
          order: 'ASC'
        },
        {
          type: 'applicationName',
          order: 'ASC'
        }
      ]
    };

    insightsService
      .getOnboardedApps(data)
      .then((response) => {
        console.log('response :', response.data);
        let formattedData = response.data.map((item) => {
          const date = moment(item.createDateTime[0]);
          return {
            ...item,
            portfolioName: portfolios[item.portfolioShortName].name,
            createDateTime: date.format('D-MMM-YYYY h:mm:ss a')
          };
        });
        setOnboardedAppsData({
          ...onboardedAppsData,
          applications: formattedData
        });
        setInsightsData((prevState) => ({
          ...prevState,
          status: Status.Success,
          fromToChanged: false
        }));
      })
      .catch((error) => {
        setOnboardedAppsData({
          ...onboardedAppsData,
          status: Status.Error,
          error:
            'Error while retrieving onboarded applications . Please refresh the page and try again.'
        });
        console.error('Error while retrieving onboarded applications: ', error);
      });
  };

  useEffect(() => {
    getApplicationsOnboarded();
  }, []);
  const dateFilterHandler = (event) => {
    getApplicationsOnboarded();
  };

  const columns = [
    {
      id: 'macId',
      label: 'MAC ID',
      sortDisabled: true
    },
    {
      id: 'portfolioName',
      label: 'Portfolio',
      sortDisabled: true
    },
    {
      id: 'platformName',
      label: 'Platform',
      sortDisabled: true
    },
    {
      id: 'applicationName',
      label: 'Application',
      sortDisabled: true
    },
    {
      id: 'createDateTime',
      label: 'Onboarded On',
      sortDisabled: true
    }
  ];

  return (
    <>
      {onboardedAppsData.applications.status === Status.InProgress ? <Loading /> : null}

      {onboardedAppsData.applications.status === Status.Success &&
      onboardedAppsData.applications.success ? (
        <ShowSuccessNotification message={onboardedAppsData.success} />
      ) : null}

      {onboardedAppsData.applications.status === Status.Error ? (
        <ShowErrorNotification message={onboardedAppsData.error} />
      ) : null}

      <div style={{ width: 'fit-content' }}>
        <h5 className="app__page-title">Insights &gt; Applications</h5>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-end',
            marginBottom: '16px'
          }}>
          <McDateRange
            name="dateFilter"
            format="YYYY-MM-DD"
            placeholder="YYYY-MM-DD"
            style={{ marginRight: '12px' }}
            input={(event) => setDateFilter(event)}
            value={{ from: insightsData.from, to: insightsData.to }}
          />
          <McButton disabled={isDateFilterDisabled()} click={dateFilterHandler}>
            Apply
          </McButton>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ marginTop: '16px' }}>
            {onboardedAppsData.applications.length > 0 ? (
              <McTable
                data={onboardedAppsData.applications}
                columns={columns}
                datakey="macId"
                footer>
                <div slot="macId_footer">Total:</div>
                <div slot="applicationName_footer">{onboardedAppsData.applications.length}</div>
              </McTable>
            ) : (
              <p>No applications have been onboarded during this timeline.</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardedApplications;

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error'
};
