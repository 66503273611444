import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { RumInit } from '@maersk-global/telemetry-web-sdk';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App.js';
import { msalConfig } from './auth/authProviderMsalReact';
import RequestInterceptor from './components/auth/RequestInterceptor';
import './index.css';

// MSAL initialization
const msalInstance = new PublicClientApplication(msalConfig);

// RUM initialization
export const faro =
  process.env.REACT_APP_RUM_NAME === 'iPACE-Local' || process.env.REACT_APP_RUM_NAME === 'iPACE-Dev'
    ? null
    : RumInit({
        app: {
          name: process.env.REACT_APP_RUM_NAME,
          version: 'iPACE-V2'
        },
        apiKey: process.env.REACT_APP_RUM_KEY,
        instrumentations: {
          console: false, // we can enable this when logging is thoughtful
          xhr: false,
          fetch: false
        }
      });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <MsalProvider instance={msalInstance}>
      <RequestInterceptor>
        <App />
      </RequestInterceptor>
    </MsalProvider>
  </BrowserRouter>
);
