import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McInput } from '@maersk-global/mds-react-wrapper/components-core/mc-input';
import { McNotification } from '@maersk-global/mds-react-wrapper/components-core/mc-notification';
import { McToast } from '@maersk-global/mds-react-wrapper/components-core/mc-toast';
import React, { useState } from 'react';
import { getCoreServiceUrl } from '../components/Util.js';
import HashicorpVaultService from '../service/HashicorpVaultService';
import '../stylesheets/Vault.css';
import './mdsVault.css';

const HashicorpVault = () => {
  const [vaultExist, setVaultExist] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [alertFit, setAlertFit] = useState('medium');

  let hashicorpVaultService = new HashicorpVaultService();
  const handleSubmit = (event) => {
    event.preventDefault();
    const fields = [
      { name: 'roleId', message: 'Role ID is required' },
      { name: 'secretId', message: 'Secret ID is required' },
      { name: 'kvName', message: 'KV Name is required' }
    ];

    let errorMessages = [];

    for (let field of fields) {
      if (userForm[field.name] === '') {
        errorMessages.push(field.message);
      }
    }
    if (errorMessages.length > 0) {
      setAlertType('error');
      setAlertMessage(errorMessages.join(', '));
      setShowAlert(true);
    }
    if (errorMessages.length === 0) {
      postVaultDeatils();
    }
  };

  React.useEffect(() => {
    getVaultDetails();
  }, []);
  function postVaultDeatils() {
    let data = {
      roleId: userForm.roleId ? userForm.roleId : null,
      secretId: userForm.secretId ? userForm.secretId : null,
      kvName: userForm.kvName
    };
    hashicorpVaultService
      .postHashicorpVault(getCoreServiceUrl() + '/core-srv/secrets/byov/hashicorp', data)
      .then((response) => {
        if (response.status == 201) {
          setShowAlert(true);
          setAlertType('success');
          setAlertMessage('Successfully configured HashiCorp Vault.');
          getVaultDetails();
        } else {
          throw new Error('Unexpectecd error', { response: response });
        }
      })
      .catch((error) => {
        setAlertType('error');
        if (error.response && error.response.data) {
          let msg = error.response.data;
          setAlertMessage(msg);
          setAlertFit('large');
        } else {
          setAlertMessage('Something unexpected happened. Please try again.');
        }
        setShowAlert(true);
      });
  }
  function getVaultDetails() {
    hashicorpVaultService
      .getHashicorpVault(getCoreServiceUrl() + '/core-srv/secrets/byov/hashicorp')
      .then((response) => {
        if (response.status == 200) {
          setVaultExist(true);
          setUserForm({
            ...userForm,
            roleId: response.data.roleId,
            secretId: response.data.secretId,
            kvName: response.data.kvName
          });
        } else {
          throw new Error('Unexpected error', { response: response });
        }
      })
      .catch((error) => {
        if (error.response && error.response.status == 404) {
          return;
        }

        console.error(error);
        setAlertType('error');
        setAlertMessage('Something unexpected happened. Please try again.');
        setShowAlert(true);
      });
  }

  const [userForm, setUserForm] = React.useState({
    roleId: '',
    secretId: '',
    kvName: ''
  });
  function errorDialogOnClose() {
    setShowAlert(false);
  }
  function showAlertDialog() {
    let icon;

    if (alertType === 'info') {
      icon = 'info-circle';
    } else if (alertType === 'error') {
      icon = 'exclamation-octagon';
    } else if (alertType === 'success') {
      icon = 'check-circle';
    }

    return (
      <McToast
        open
        position="top-center"
        appearance={alertType}
        fit={alertFit}
        width={alertFit === 'large' ? '150%' : 'auto'}
        close={errorDialogOnClose}
        duration="5000">
        <McNotification
          position="top-center"
          fit={alertFit}
          width={alertFit === 'large' ? '150%' : 'auto'}
          icon={icon}>
          {alertMessage}
        </McNotification>
      </McToast>
    );
  }
  let alertDia = showAlertDialog();

  return (
    <>
      <div>
        {showAlert ? alertDia : <div></div>}
        <h5 className="app__page-title">Configuration &gt; HashiCorp Vault</h5>
        <form id="info" onSubmit={(e) => handleSubmit(e)}>
          <div id="Personal">
            <div className="field">
              <div className="app__page-input">
                <McInput
                  name="roleId"
                  label="Role ID"
                  placeholder={vaultExist ? '**********' : 'Enter Role ID'}
                  type="password"
                  value={userForm.roleId ? userForm.roleId : ''}
                  required={true}
                  input={(event) => {
                    if (event) {
                      const value = event.target.value;
                      setUserForm({ ...userForm, roleId: value });
                    }
                  }}></McInput>
              </div>
              <div className="app__page-input">
                <McInput
                  name="secretId"
                  label="Secret ID"
                  placeholder={vaultExist ? '**********' : 'Enter Secret ID'}
                  type="password"
                  required={true}
                  value={userForm.secretId ? userForm.secretId : ''}
                  input={(event) => {
                    if (event) {
                      const value = event.target.value;
                      setUserForm({ ...userForm, secretId: value });
                    }
                  }}></McInput>
              </div>
              <div className="app__page-input">
                <McInput
                  name="kvName"
                  label="KV Name"
                  placeholder="Enter KV Name"
                  hint="Generally of format product_name-kv"
                  value={userForm.kvName ? userForm.kvName : ''}
                  required={true}
                  input={(event) => {
                    if (event) {
                      const value = event.target.value;
                      setUserForm({ ...userForm, kvName: value });
                    }
                  }}></McInput>
              </div>
              <McButton type="submit" variant="filled" className="app__page-footer">
                {vaultExist ? 'Update' : 'Save'}
              </McButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default HashicorpVault;
