import { McButton } from '@maersk-global/mds-react-wrapper';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShowErrorNotification } from '../common/notification.jsx';
import { formatDateTime, getCoreServiceUrl } from '../components/Util.js';
import Loading from '../components/pages/Loading.jsx';
import IcTable from '../components/util/IcTable.jsx';
import ChaosTestExecService from '../service/ChaosTestExecService';
import '../stylesheets/ListChaos.css';

export default function ListChaosTestExcnts() {
  const navigate = useNavigate();

  let chaosTestExecService = new ChaosTestExecService();

  const defaultChaosExecutionListData = {
    data: [],
    totalCount: -1,
    totalPages: 0,
    currentPage: 0,
    pageSize: 50
  };
  const [ChaosExecutionListData, setChaosExecutionListData] = useState(
    defaultChaosExecutionListData
  );

  const defaultApiStatus = {
    status: Status.Pending,
    message: ''
  };
  const [apiStatus, setApiStatus] = useState(defaultApiStatus);
  const navigateToChaosExecuteTest = (e, chaosTestId, status) => {
    e.preventDefault();

    if (status === 'DRAFT') {
      navigate(`/chaos-testing/executions/${chaosTestId}`, {
        state: {
          chaosTestId: chaosTestId,
          activeTablVal: 0
        }
      });
    } else if (status !== 'DRAFT') {
      navigate(`/chaos-testing/executions/${chaosTestId}`, {
        state: {
          chaosTestId: chaosTestId,
          activeTablVal: 0
        }
      });
    }
  };
  const navigateToCreateChaosTest = () => {
    navigate('/chaos-testing/executions/new');
  };

  React.useEffect(() => {
    setApiStatus({ status: Status.InProgress, message: '' });

    chaosTestExecService
      .getAllChaosTestTemplate(
        getCoreServiceUrl() + `/core-srv/chaos-test-exec/list`,
        ChaosExecutionListData.currentPage,
        ChaosExecutionListData.pageSize
      )
      .then((response) => {
        const responseData = response.data;
        setChaosExecutionListData({
          ...ChaosExecutionListData,
          data: responseData.data,
          totalCount: responseData.totalCount,
          totalPages: responseData.totalPages
        });
        setApiStatus({ status: Status.Success, message: '' });
      })
      .catch((error) => {
        console.error('Error while retrieving Chaos Execution list: ', error);
        setApiStatus({
          status: Status.Error,
          message: 'Something unexpected happened. Please try again by refreshing the page.'
        });
      });
  }, [ChaosExecutionListData.currentPage]);

  return (
    <div style={{ width: 'fit-content' }}>
      <h5 className="app__page-title">Chaos Engineering &gt; Test Executions</h5>
      {apiStatus.status === Status.InProgress ? <Loading /> : ''}
      {apiStatus.status === Status.Error ? (
        <ShowErrorNotification message={apiStatus.message} />
      ) : null}
      <div style={{ marginBottom: '16px' }}>
        <McButton variant="outlined" onClick={navigateToCreateChaosTest}>
          New Chaos Test
        </McButton>
      </div>
      <IcTable
        name="Chaos Execution"
        data={ChaosExecutionListData}
        columns={columns}
        datakey="testId"
        footer
        disableplaceholderfooters
        onPageChange={(nextPage) => {
          setChaosExecutionListData({ ...ChaosExecutionListData, currentPage: nextPage });
        }}>
        {ChaosExecutionListData.data?.map((row) => (
          <React.Fragment key={row.testId}>
            <div slot={`${row.testId}_chaosTestId`}>{row.testId}</div>
            <div slot={`${row.testId}_createDateTime`}>{formatDateTime(row.createDateTime)}</div>
            <div slot={`${row.testId}_action`}>
              <McButton
                variant="outlined"
                onClick={(e) => navigateToChaosExecuteTest(e, row.testId, row.status)}>
                {row.status === 'DRAFT' ? 'Execute Test' : 'View Test'}
              </McButton>
            </div>
          </React.Fragment>
        ))}
      </IcTable>
    </div>
  );
}
const columns = [
  {
    id: 'chaosTestId',
    sortDisabled: true,
    label: 'Test ID'
  },
  {
    id: 'testName',
    sortDisabled: true,
    label: 'Test Name'
  },
  {
    id: 'createdBy',
    sortDisabled: true,
    label: 'Created By'
  },
  {
    id: 'status',
    sortDisabled: true,
    label: 'Status'
  },
  {
    id: 'createDateTime',
    sortDisabled: true,
    label: 'Created  Date Time'
  },
  {
    id: 'action',
    sortDisabled: true,
    label: 'Action'
  }
];
const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error'
};
