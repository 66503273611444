// portfolioUtils.js
const config = require('../config/config.json');

const getPortfolios = () => {
  const portfolioList = config.platformNames;
  // create a portfolio map for quick lookup
  const portfolioMap = {};
  // add portfolio short name to portfolio mapping
  portfolioList.reduce((map, portfolio) => {
    map[portfolio.value] = portfolio;
    return map;
  }, portfolioMap);
  return { portfolioList, portfolioMap };
};

module.exports = getPortfolios;
