import React from 'react';
import ApplicationOnboarding from '../../Onboarding/ApplicationOnboarding.jsx';
import UserOnboarding from '../../Onboarding/UserOnboarding.jsx';
import { ShowErrorNotification } from '../../common/notification.jsx';
import UserInfo from '../../hooks/UserAppAuthorizationProvider.jsx';

const UserAppAuthorizedTemplate = ({ children }) => {
  const { userProfile, onUserProfileChange, onOnboardingConfigChange, authError } = UserInfo();

  const onApplicationOnboard = (userProfile, onboardingConfig) => {
    onUserProfileChange(userProfile);
    onOnboardingConfigChange(onboardingConfig);
  };

  if (authError) {
    // error in user and app authorization
    return <ShowErrorNotification message={authError.message} />;
  } else if (!userProfile) {
    // user not found, onboard user
    return <UserOnboarding onUserOnboard={onUserProfileChange} />;
  } else if (!userProfile.onboardingConfigIds) {
    // user found but application not found, onboard application
    return <ApplicationOnboarding onApplicationOnboard={onApplicationOnboard} />;
  }

  // user and application found, continue with the application
  return <>{children}</>;
};

export default UserAppAuthorizedTemplate;
