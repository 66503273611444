import React, { useEffect } from 'react';

import { McTab } from '@maersk-global/mds-react-wrapper/components-core/McTab';
import { McTabBar } from '@maersk-global/mds-react-wrapper/components-core/McTabBar';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McInput } from '@maersk-global/mds-react-wrapper/components-core/mc-input';
import { McNotification } from '@maersk-global/mds-react-wrapper/components-core/mc-notification';
import { McToast } from '@maersk-global/mds-react-wrapper/components-core/mc-toast';
import { getCoreServiceUrl } from '../components/Util.js';
import ConfirmDeletionModal from '../components/util/ConfirmDeletionModal.jsx';
import SecretService from '../service/SecretService.js';

export default function Secrets() {
  const [activeTab, setActiveTab] = React.useState(0);

  const [showAlert, setShowAlert] = React.useState(false);
  const [alertType, setAlertType] = React.useState('');
  const [alertMessage, setAlertMessage] = React.useState('');
  const [confirmDelete, setConfirmDelete] = React.useState({ show: false, env: '', name: '' });

  const environments = ['Development', 'Pre-Prod', 'Prod'];

  const emptySecretData = {
    devSecrets: [{ name: '', value: '', isNew: true }],
    preProdSecrets: [{ name: '', value: '', isNew: true }],
    prodSecrets: [{ name: '', value: '', isNew: true }]
  };
  const [secretData, setSecretData] = React.useState(emptySecretData);

  const convertFromGetSecret = (responseSecretData) => {
    let newSecretData = { ...emptySecretData };

    for (let environment in responseSecretData) {
      newSecretData[environment] = [];

      for (let secretName in responseSecretData[environment]) {
        newSecretData[environment].push({
          name: secretName,
          value: ''
        });
      }
    }
    return newSecretData;
  };

  const convertForSaveSecret = (formSecretData) => {
    let newSecretData = {};

    for (let environment in formSecretData) {
      newSecretData[environment] = {};
      for (let secret of formSecretData[environment]) {
        if (secret.name && secret.value) {
          newSecretData[environment][secret.name] = secret.value;
        }
      }
    }

    return newSecretData;
  };

  const saveSecret = () => {
    let convertedSecretData = convertForSaveSecret(secretData);

    if (
      Object.keys(convertedSecretData.devSecrets).length == 0 &&
      Object.keys(convertedSecretData.preProdSecrets).length == 0 &&
      Object.keys(convertedSecretData.prodSecrets).length == 0
    ) {
      // nothing to save
      return;
    }

    let secretService = new SecretService();
    secretService
      .postSecret(getCoreServiceUrl() + '/core-srv/secrets', convertedSecretData)
      .then((response) => {
        if (response.status === 201) {
          setShowAlert(true);
          setAlertType('success');
          setAlertMessage('Successfully saved all Secrets.');
          getSecret();
        } else {
          throw new Error({ response: response });
        }
      })
      .catch((error) => {
        console.error(error);
        setAlertType('error');
        if (error.response && error.response.data && error.response.data.message) {
          setAlertMessage(error.response.data.message);
        } else {
          setAlertMessage('Something unexpected happened. Please try again.');
        }
        setShowAlert(true);
      });
  };

  const handleDelete = (event, action) => {
    if (action === 'cancel') {
      setConfirmDelete({ show: false, env: '', name: '' });
      return;
    }
    setConfirmDelete({ ...confirmDelete, show: false });

    let mappedEnv = '';
    if (confirmDelete.env === 'Development') {
      mappedEnv = 'DEV';
    } else if (confirmDelete.env === 'Pre-Prod') {
      mappedEnv = 'PREPROD';
    } else {
      mappedEnv = confirmDelete.env;
    }
    let secretService = new SecretService();
    secretService
      .deleteSecret(
        `${getCoreServiceUrl()}/core-srv/secrets/${mappedEnv}?name=${confirmDelete.name}`
      )
      .then((response) => {
        if (response.status === 200) {
          setShowAlert(true);
          setAlertType('success');
          setAlertMessage(`Successfully deleted "${confirmDelete.name}" secret.`);

          setConfirmDelete({ show: false, env: '', name: '' });
          getSecret();
        } else {
          throw new Error({ response: response });
        }
      })
      .catch((error) => {
        console.error(error);
        setAlertType('error');
        if (error.response && error.response.data && error.response.data.message) {
          setAlertMessage(error.response.data.message);
        } else {
          setAlertMessage('Something unexpected happened. Please try again.');
        }
        setShowAlert(true);
        setConfirmDelete({ show: false, env: '', name: '' });
      });
  };

  const getSecret = () => {
    let secretService = new SecretService();
    secretService
      .getSecret(getCoreServiceUrl() + '/core-srv/secrets')
      .then((response) => {
        if (response.status === 200) {
          let convertedSecretData = convertFromGetSecret(response.data);
          setSecretData(convertedSecretData);
        } else {
          throw new Error({ response: response });
        }
      })
      .catch((error) => {
        console.error(error);
        setAlertType('error');
        if (error.response && error.response.data && error.response.data.message) {
          setAlertMessage(error.response.data.message);
        } else {
          setAlertMessage('Something unexpected happened. Please try again.');
        }
        setShowAlert(true);
      });
  };

  const addNewSecret = () => {
    let newSecret = { name: '', value: '', isNew: true };
    let newSecretData = { ...secretData };

    let environmentSecrets = getEnvironmentSecrets(activeTab, newSecretData);
    environmentSecrets.push(newSecret);

    setSecretData(newSecretData);
  };

  const updateSecret = (event, index, updateKey) => {
    console.info(activeTab);

    let newSecretData = { ...secretData };

    let environmentSecrets = getEnvironmentSecrets(activeTab, newSecretData);
    environmentSecrets[index][updateKey] = event.target.value;

    setSecretData(newSecretData);
  };

  const getEnvironmentSecrets = (tabIndex, secretDataForAllEnvs) => {
    let environment = environments[tabIndex];
    if (environment === 'Development') {
      return secretDataForAllEnvs.devSecrets;
    } else if (environment === 'Pre-Prod') {
      return secretDataForAllEnvs.preProdSecrets;
    } else if (environment === 'Prod') {
      return secretDataForAllEnvs.prodSecrets;
    }
  };

  useEffect(() => {
    getSecret();
  }, []);

  function errorDialogOnClose() {
    setShowAlert(false);
  }

  function showAlertDialog() {
    let icon;

    if (alertType === 'info') {
      icon = 'info-circle';
    } else if (alertType === 'error') {
      icon = 'exclamation-octagon';
    } else if (alertType === 'success') {
      icon = 'check-circle';
    }
    return (
      <McToast
        open
        position="top-center"
        appearance={alertType}
        close={errorDialogOnClose}
        duration="5000">
        <McNotification icon={icon} body={alertMessage}></McNotification>
      </McToast>
    );
  }
  let alertDia = showAlertDialog();

  return (
    <div style={{ width: '70%' }}>
      {showAlert ? alertDia : <div></div>}
      {confirmDelete.show ? (
        <ConfirmDeletionModal
          confirmationMessage={`Are you sure you want to delete "${confirmDelete.name}" secret?`}
          onAction={handleDelete}
        />
      ) : null}
      <h5 className="app__page-title">Configuration &gt; Secrets</h5>
      <McTabBar tabchange={(e) => setActiveTab(e.detail)}>
        {environments.map((environment, tabindex) => (
          <React.Fragment key={tabindex}>
            <McTab slot="tab" label={environment} />
            <div slot="panel">
              <form>
                <McButton
                  label="Add Secret"
                  variant="outlined"
                  style={{ marginBottom: '16px' }}
                  click={addNewSecret}></McButton>
                {getEnvironmentSecrets(tabindex, secretData).map((secret, index) => (
                  <div
                    key={index}
                    style={{ display: 'flex', alignItems: 'flex-end' }}
                    className="app__page-input">
                    <McInput
                      style={{ flex: 0.4, marginRight: '16px' }}
                      name={`${environment}-secret-name-${index}`}
                      label="Secret Name"
                      placeholder={secret.isNew ? 'Enter secret name' : ''}
                      value={secret.name}
                      required
                      trailingicon={secret.isNew ? '' : 'lock'}
                      disabled={!secret.isNew}
                      input={(event) => updateSecret(event, index, 'name')}></McInput>
                    <McInput
                      style={{ flex: 0.6, marginRight: '16px' }}
                      name={`${environment}-secret-value-${index}`}
                      label="Secret Value"
                      placeholder={secret.isNew ? 'Enter secret value' : '**********'}
                      value={secret.value}
                      type="password"
                      required
                      trailingicon={secret.isNew ? '' : 'pencil'}
                      input={(event) => updateSecret(event, index, 'value')}></McInput>
                    <McButton
                      icon="trash"
                      variant="outlined"
                      appearance="error"
                      disabled={secret.isNew}
                      click={() => {
                        setConfirmDelete({
                          show: true,
                          env: environment,
                          name: secret.name
                        });
                      }}></McButton>
                  </div>
                ))}
              </form>
            </div>
          </React.Fragment>
        ))}
      </McTabBar>
      <McButton label="Save All" click={saveSecret} className="app__page-footer" />
    </div>
  );
}
