import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShowErrorNotification } from '../../common/notification';
import { formatDateTime } from '../Util';
import Loading from '../pages/Loading';
import ComponentLink from '../util/ComponentLink';
import IcTable from '../util/IcTable';
import { CreateNewTestIntegrationHint } from './IntegrationTest';
import TestIntegrationService from './TestIntegrationService';

const ListTestIntegrations = () => {
  const navigate = useNavigate();
  const testIntegrationService = new TestIntegrationService();

  const defaultTestIntegrationListData = {
    data: [],
    totalCount: -1,
    totalPages: 0,
    currentPage: 0,
    pageSize: 50
  };
  const [testIntegrationListData, setTestIntegrationListData] = useState(
    defaultTestIntegrationListData
  );

  const defaultApiStatus = {
    status: Status.Pending,
    message: ''
  };
  const [apiStatus, setApiStatus] = useState(defaultApiStatus);

  const getTestIntegrations = () => {
    setApiStatus({ status: Status.InProgress, message: '' });

    testIntegrationService
      .getTestIntegrations(testIntegrationListData.currentPage, testIntegrationListData.pageSize)
      .then((response) => {
        let responseData = response.data;
        setTestIntegrationListData({
          ...testIntegrationListData,
          data: responseData.data,
          totalCount: responseData.totalCount,
          totalPages: responseData.totalPages
        });
        setApiStatus({ status: Status.Success, message: '' });
      })
      .catch((error) => {
        console.error('Error while retrieving integration tests: ', error);
        setApiStatus({
          status: Status.Error,
          message: 'Something unexpected happened. Please try again by refreshing the page.'
        });
      });
  };

  useEffect(() => {
    getTestIntegrations();
  }, [testIntegrationListData.currentPage]);

  return (
    <div style={{ width: 'fit-content' }}>
      <h5 className="app__page-title">Performance Engineering &gt; CICD Integrations</h5>
      {apiStatus.status === Status.InProgress ? <Loading /> : ''}
      {apiStatus.status === Status.Error ? (
        <ShowErrorNotification message={apiStatus.message} />
      ) : null}
      <div style={{ marginBottom: '16px' }}>
        <CreateNewTestIntegrationHint />
      </div>
      <IcTable
        name="Integration"
        data={testIntegrationListData}
        columns={columns}
        datakey="id"
        footer
        disableplaceholderfooters
        onPageChange={(nextPage) => {
          setTestIntegrationListData({ ...testIntegrationListData, currentPage: nextPage });
        }}>
        {testIntegrationListData.data.map((row) => (
          <React.Fragment key={row.id}>
            <div slot={`${row.id}_id`}>
              <ComponentLink route={`/perf-testing/integrations/${row.id}`}>{row.id}</ComponentLink>
            </div>
            <div slot={`${row.id}_testTemplateId`}>
              <ComponentLink route={`/perf-testing/templates/${row.testTemplateId}`}>
                {row.testTemplateId}
              </ComponentLink>
            </div>
            <div slot={`${row.id}_updatedDateTime`}>{formatDateTime(row.updatedDateTime)}</div>
          </React.Fragment>
        ))}
      </IcTable>
    </div>
  );
};

export default ListTestIntegrations;

const columns = [
  {
    id: 'id',
    label: 'Integration ID',
    sortDisabled: true
  },
  {
    id: 'integrationName',
    label: 'Integration Name',
    sortDisabled: true
  },
  {
    id: 'testTemplateId',
    label: 'Test Template ID',
    sortDisabled: true
  },
  {
    id: 'updatedDateTime',
    label: 'Updated Date Time',
    sortDisabled: true
  }
];

const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error'
};
