import { React } from 'react';
import { McInput } from '@maersk-global/mds-react-wrapper/components-core/mc-input';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McTable } from '@maersk-global/mds-react-wrapper/components-core/mc-table';
import { McIcon } from '@maersk-global/mds-react-wrapper/components-core/mc-icon';
import { data, columns } from './DummyTableData';

const OnboardUser = () => {
  //   const [optionSelected, setOptionSelected] = useState('none');
  //   const [isARowSelected, setIsARowSelected] = useState(false);

  //   const rowSelectHandler = (evt) => {
  //     console.log('Row selected', evt);
  //     setIsARowSelected(true);
  //   };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '70%',
          marginLeft: '17%',
          marginTop: '5vh'
        }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>
            <McInput
              name="Users"
              label="Users"
              placeholder="user.one@maersk.com, user.two@maersk.com"
              hint="Type comma separated user email addresses"></McInput>
          </div>
          <div style={{ marginTop: '2vh', width: '100%' }}>
            <McInput
              name="ServicePrincipals"
              label="Service Principals"
              placeholder="service-principal-1, service-principal-2"
              hint="Type comma separated service principal names"></McInput>
          </div>

          <div>
            <McButton
              style={{ marginTop: '2vh' }}
              label="Verify All"
              trailingicon="magnifying-glass"
              //   click="yourClickHandler()"
            ></McButton>
          </div>

          <div style={{ marginTop: '8vh' }}>
            <McTable
              data={data}
              columns={columns}
              datakey="userID"
              //   sortchange={() => yourSortedHandler}
              //   rowclick={() => yourRowClickHandler}
            >
              {data &&
                data.map((row) => (
                  <div
                    style={{ display: 'flex', marginLeft: '8vh' }}
                    key={`${row.userID}_verificationStatus`}
                    slot={`${row.userID}_verificationStatus`}>
                    <McIcon
                      icon={row.verificationStatus ? 'check-circle' : 'times-circle'}
                      color={row.verificationStatus ? 'green' : 'red'}></McIcon>
                  </div>
                ))}
            </McTable>
          </div>
          <div
            style={{
              marginTop: '4vh'
            }}>
            <McButton
            // click="yourClickHandler()"
            >
              <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <span style={{ marginRight: '0.4vw' }}>Onboard All</span>
                <McIcon icon="check-circle" color="green"></McIcon>
              </div>
            </McButton>
          </div>
          <div style={{ marginTop: '2vh', display: 'flex' }}>
            <McIcon icon="info-circle"></McIcon>
            <span style={{ marginLeft: '1.2vh', marginTop: '-0.2%' }}>
              Onboard verified users to application-name
            </span>
          </div>

          {/* {console.log('isARowSelected', isARowSelected)} */}
        </div>
      </div>
    </>
  );
};

export default OnboardUser;
