import { McIcon } from '@maersk-global/mds-react-wrapper';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShowErrorNotification } from '../common/notification.jsx';
import Loading from '../components/pages/Loading.jsx';
import { formatDateTime, getCoreServiceUrl, getGitHubLink } from '../components/Util.js';
import ComponentLink from '../components/util/ComponentLink.jsx';
import IcTable from '../components/util/IcTable.jsx';
import TestTmplService from '../service/TestTmplService.js';
function ListTestTmpls() {
  const navigate = useNavigate();

  let testTmplService = new TestTmplService();

  const navigateToExecuteTest = (e, templateData) => {
    navigate('/perf-testing/executions/new', {
      state: {
        templateData: templateData
      }
    });
  };

  const handleAddTest = (e) => {
    e.preventDefault();
    navigate('/perf-testing/templates/new');
  };

  const defaultTemplateListData = {
    data: [],
    totalCount: -1,
    totalPages: 0,
    currentPage: 0,
    pageSize: 50
  };
  const [TemplateListData, setTemplateListData] = useState(defaultTemplateListData);

  const defaultApiStatus = {
    status: Status.Pending,
    message: ''
  };
  const [apiStatus, setApiStatus] = useState(defaultApiStatus);

  const getTemplateData = () => {
    setApiStatus({ status: Status.InProgress, message: '' });

    testTmplService
      .getAllTestTemplate(
        getCoreServiceUrl() + `/core-srv/test-tmpl/list`,
        TemplateListData.currentPage,
        TemplateListData.pageSize
      )
      .then((response) => {
        const responseData = response.data;
        setTemplateListData({
          ...TemplateListData,
          data: responseData.data,
          totalCount: responseData.totalCount,
          totalPages: responseData.totalPages
        });
        setApiStatus({ status: Status.Success, message: '' });
      })
      .catch((error) => {
        if (error instanceof Error) {
          console.error('Error while retrieving templates', error);
          setApiStatus({
            status: Status.Error,
            message: 'Something unexpected happened. Please try again by refreshing the page.'
          });
        }
      });
  };
  React.useEffect(() => {
    getTemplateData();
  }, [TemplateListData.currentPage]);

  return (
    <div style={{ width: 'fit-content' }}>
      <h5 className="app__page-title">Performance Engineering &gt; Test Templates</h5>
      {/* <LoadingMask isOpen={apiStatus.status === Status.InProgress} /> */}
      {apiStatus.status === Status.InProgress ? <Loading /> : ''}
      {apiStatus.status === Status.Error ? (
        <ShowErrorNotification message={apiStatus.message} />
      ) : null}
      <div style={{ marginBottom: '16px' }}>
        <McButton variant="outlined" onClick={handleAddTest}>
          New Test Template
        </McButton>
      </div>
      <IcTable
        name="Test Template"
        data={TemplateListData}
        columns={columns}
        datakey="templateId"
        footer
        disableplaceholderfooters
        onPageChange={(nextPage) => {
          setTemplateListData({ ...TemplateListData, currentPage: nextPage });
        }}>
        {TemplateListData.data?.map((row) => (
          <React.Fragment key={row.templateId}>
            <div slot={`${row.templateId}_templateId`}>
              <ComponentLink route={`/perf-testing/templates/${row.templateId}`}>
                {row.templateId}
              </ComponentLink>
            </div>
            <div slot={`${row.templateId}_testScript`}>
              <a href={getGitHubLink(row)} target="_blank" rel="noopener noreferrer">
                {row.testScript}
              </a>
            </div>
            <div slot={`${row.templateId}_status`}>
              <McIcon
                icon={row.status === 'ACTIVE' ? 'check-circle' : 'times-circle'}
                color={row.status === 'ACTIVE' ? 'green' : 'red'}></McIcon>
            </div>
            <div slot={`${row.templateId}_createDateTime`}>
              {formatDateTime(row.createDateTime)}
            </div>
            <div slot={`${row.templateId}_action`}>
              <McButton
                variant="outlined"
                disabled={row.status === 'ACTIVE' ? false : true}
                onClick={(e) => navigateToExecuteTest(e, row)}>
                Execute Test
              </McButton>
            </div>
          </React.Fragment>
        ))}
      </IcTable>
    </div>
  );
}
export default ListTestTmpls;
const columns = [
  {
    id: 'templateId',
    label: 'Template ID',
    sortDisabled: true
  },
  {
    id: 'templateName',
    label: 'Template Name',
    sortDisabled: true
  },
  {
    id: 'testScript',
    label: 'Test Script',
    sortDisabled: true
  },
  {
    id: 'status',
    label: 'Status',
    sortDisabled: true
  },
  {
    id: 'createDateTime',
    label: 'Created  Date Time',
    sortDisabled: true
  },
  {
    id: 'action',
    label: 'Action',
    sortDisabled: true
  }
];
const Status = {
  Pending: 'Pending',
  InProgress: 'InProgress',
  Success: 'Success',
  Error: 'Error'
};
