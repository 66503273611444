import axios from 'axios';
import { getCoreServiceUrl } from '../components/Util.js';

class TestInsightService {
  coreServiceUrl = `${getCoreServiceUrl()}/core-srv`;

  getTestComparisonLink(testIds) {
    let url = `${this.coreServiceUrl}/test-compare`;
    return axios
      .post(
        url,
        { testIds: testIds },
        {
          validateStatus: function (status) {
            return status === 200;
          }
        }
      )
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default TestInsightService;
